import _ from 'lodash';
import $ from 'jquery';
import classNames from 'classnames';

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { IOption } from 'types';

const useStyles = makeStyles({
  autocomplete: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    width: 70,
    textAlign: 'right',
    marginRight: 10,
  },
  input: {
    flex: '1 1',
    padding: '1px 0',

    '& .MuiInputBase-root': {
      borderRadius: 8,
      padding: 0,
      paddingLeft: 15,
    },
  },
});

interface Props {
  className?: string;
  label?: string;
  placeholder: string;
  hideArrow?: boolean;
  options: IOption[];
  inputValue: string;
  defaultInputValue: string;
  specialOptionTypes: string[];
  filterOptions: (_: IOption[]) => IOption[];
  onChange: (_: any) => void;
  onInputChange: (_: string) => void;
}

function HvAutoComplete(props: Props) {
  const styles = useStyles();

  const [text, setText] = useState('');

  const {
    className,
    label,
    placeholder,
    hideArrow,
    options,
    inputValue,
    defaultInputValue,
    specialOptionTypes,
    filterOptions,
    onChange,
    onInputChange,
  } = props;

  const clearInputValue = () => {
    setText('');
  };

  const isSpecialOption = (_type: string = '') => {
    return specialOptionTypes.includes(_type);
  };

  const onTextFieldChange = (e: any) => {
    if (!e) return;

    const { dataset, value, innerText } = e.target;
    if (isSpecialOption(dataset?.type)) {
      return;
    }

    const _text = value || innerText;

    setText(_text);
    onInputChange(_text);
  };

  const onFilterOptions = (options: IOption[]): IOption[] => {
    if (filterOptions) {
      return filterOptions(options);
    }

    if (text) {
      return options.filter((o: IOption) => {
        return o.label.toLowerCase().includes(text.toLowerCase());
      });
    }
    return options;
  };

  const onOptionSelected = (_: any, o: IOption) => {
    if (!o) return;
    onChange(o);
  };

  useEffect(() => {
    $('.MuiAutocomplete-clearIndicator').eq(0).on('click', clearInputValue);
    return () => {
      $('.MuiAutocomplete-clearIndicator').eq(0).off('click', clearInputValue);
    };
  }, []);

  useEffect(() => {
    if (text !== inputValue) {
      setText(inputValue);
    }
  }, [inputValue]);

  return (
    <Box className={classNames(className, styles.autocomplete)}>
      {label ? <Typography className={styles.label}>{label}</Typography> : null}
      <Autocomplete
        className={classNames(className, styles.input)}
        options={options}
        inputValue={text}
        popupIcon={hideArrow ? null : <ArrowDropDownIcon />}
        filterOptions={onFilterOptions}
        onInputChange={onTextFieldChange}
        onChange={onOptionSelected}
        getOptionLabel={(option: any) => {
          return option?.label || '';
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              placeholder={placeholder}
              defaultValue={defaultInputValue}
              variant='outlined'
            />
          );
        }}
        renderOption={(option: IOption) => {
          return <span data-type={option.type}>{option.label || ''}</span>;
        }}
      />
    </Box>
  );
}

HvAutoComplete.defaultProps = {
  className: null,
  label: '',
  placeholder: '',
  hideArrow: false,
  options: [],
  inputValue: '',
  defaultInputValue: '',
  specialOptionTypes: [],
  filterOptions: null,
  onChange: _.noop,
  onInputChange: _.noop,
};

HvAutoComplete.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  hideArrow: PropTypes.bool,
  options: PropTypes.array,
  inputValue: PropTypes.string,
  defaultInputValue: PropTypes.string,
  specialOptionTypes: PropTypes.arrayOf(PropTypes.string),
  filterOptions: PropTypes.func,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
};

export default HvAutoComplete;
