import axios from 'axios';
import { getAPIURL } from 'config/constants';

export async function getOrderInfoFromId(order_id: number) {
  try {
    const response = await axios.get(
      `${getAPIURL()}/getFloorplanInfoFromOrderId?esoft_order_id=${order_id}`,
    );
    if (response.status !== 200) return null;
    return response.data;
  } catch (error) {
    console.log('[getOrderInfoFromId] error: ', error);
  }
}
