import classNames from 'classnames';

import React from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { COLOR } from 'config/constants';

const useStyles = makeStyles({
  thanksContent: {
    flex: '1 1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: COLOR.BLACK,
    justifyContent: 'center',
    marginTop: 30,
  },
  icon: {
    color: COLOR.GREEN,
    fontSize: 60,
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginTop: 10,
  },
  subtitle: {
    fontSize: 15,
    marginTop: 10,
  },
});

function ThankYou() {
  const intl = useIntl();
  const styles = useStyles();

  const fmtThankYou = intl.formatMessage({ id: 'feedback.thankYou' });
  const fmtThanksForFeedback = intl.formatMessage({ id: 'feedback.thanksForFeedback' });

  return (
    <Box className={styles.thanksContent}>
      <CheckCircleOutlineIcon className={styles.icon} />
      <Typography className={styles.title}>{fmtThankYou}</Typography>
      <Typography className={styles.subtitle}>{fmtThanksForFeedback}</Typography>
    </Box>
  );
}

export default ThankYou;
