import coastal from './coastal.jpg';
import farmhouse from './farmhouse.jpg';
import industrial from './industrial.jpg';
import luxury from './luxury.jpg';
import midcentury from './midcentury.jpg';
import modern from './modern.jpg';
import scandinavian from './scandinavian.jpg';
import standard from './standard.jpg';

export default {
  coastal,
  farmhouse,
  industrial,
  luxury,
  midcentury,
  modern,
  scandinavian,
  standard,
};
