import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { User } from 'models';
import {
  fetchBrokersAsync,
  fetchPostProdTechsAsync,
  fetchPhotographersAsync,
  fetchFreelancerWithShootingsAsync,
} from 'state/actions';
import { LOADING, IDLES } from 'state/constants';

export const useAllBrokers = (user: User, token: string) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(LOADING.NONE);

  const { brokers } = useSelector((state: any) => {
    return state.users;
  });

  const loadBrokers = async () => {
    const isIdle = IDLES.includes(loading);
    if (token && user?.isAdmin && !brokers.length && isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchBrokersAsync(user, token));
      setLoading(LOADING.FINISHED);
    }
  };

  useEffect(() => {
    loadBrokers();
  }, [user, token, brokers]);

  return { loading, brokers };
};

export const usePostProdTechs = (user?: User, token?: string) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(LOADING.NONE);

  const { techns } = useSelector((state: any) => {
    return state.users;
  });

  const loadTechns = async () => {
    const isIdle = IDLES.includes(loading);
    const hasPermission =
      User.POSTPROD_ROLES.includes(user?.role) || User.ADMIN_ROLES.includes(user?.role);
    if (token && hasPermission && !techns.length && isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchPostProdTechsAsync(token));
      setLoading(LOADING.FINISHED);
    }
  };

  const postProdTechs = techns.filter((techn: any) => {
    return User.POSTPROD_TEAM_IDS.includes(techn.id);
  });
  useEffect(() => {
    loadTechns();
  }, [user, token, techns]);

  return { loading, techns, postProdTechs };
};

export const usePhotographers = (token?: string) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(LOADING.NONE);

  const { photographers } = useSelector((state: any) => {
    return state.users;
  });

  const loadPhotographers = async () => {
    const isIdle = IDLES.includes(loading);
    if (token && !photographers.length && isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchPhotographersAsync(token));
      setLoading(LOADING.FINISHED);
    }
  };

  useEffect(() => {
    loadPhotographers();
  }, [token, photographers]);

  return { loading, photographers };
};

export const useFreelancerPhotographers = (token: string, startDate: string, endDate: string) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(LOADING.NONE);

  const { photographers, generateBillStatus } = useSelector((state: any) => {
    return state.users;
  });

  const loadPhotographers = async () => {
    const isIdle = IDLES.includes(loading);
    if (token && !photographers.length && isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchFreelancerWithShootingsAsync(token, startDate, endDate));
      setLoading(LOADING.FINISHED);
    }
  };

  useEffect(() => {
    loadPhotographers();
  }, [token, photographers]);

  return { loading, photographers, generateBillStatus };
};
