import classNames from 'classnames';

import React from 'react';
import PropTypes from 'prop-types';
import { COLOR } from 'config/constants';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  stepperDots: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 30,
  },
  dot: {
    width: 16,
    height: 16,
    borderRadius: 8,
    margin: '0 6px',
    backgroundColor: COLOR.GREY,
  },
  selectedDot: {
    width: 18,
    height: 18,
    borderRadius: 9,
    margin: '0 6px',
    backgroundColor: COLOR.LIGHT_BLUE,
  },
});

interface Props {
  className: string;
  activeDot: number;
  numberOfDots: number;
}

function HvStepperDots(props: Props) {
  const styles = useStyles();

  const { className, activeDot, numberOfDots } = props;
  const dots = [];
  for (let i = 0; i < numberOfDots; i++) {
    dots.push({ index: i, active: activeDot === i });
  }

  return (
    <div className={classNames(className, styles.stepperDots)}>
      {dots.map((dot) => {
        return (
          <div
            key={dot.index}
            className={activeDot === dot.index ? styles.selectedDot : styles.dot}
          />
        );
      })}
    </div>
  );
}

HvStepperDots.defaultProps = {
  className: null,
  activeDot: 0,
  numberOfDots: 1,
};

HvStepperDots.propTypes = {
  className: PropTypes.string,
  activeDot: PropTypes.number,
  numberOfDots: PropTypes.number,
};

export default HvStepperDots;
