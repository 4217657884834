import _ from 'lodash';

import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { Profile } from 'models';
import { getProfile, getBanners, getFranchise, saveProfile } from 'apis';
import { ISelectorItem } from 'types';

const initialState = {
  profile: new Profile(),
  banners: [],
  franchise: [],
  savedProfileImageStatus: '',
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    initialize: (state) => {
      state.profile = new Profile();
      state.banners = [];
      state.franchise = [];
      state.savedProfileImageStatus = '';
    },
    addProfile: (state, action) => {
      state.profile = action.payload;
    },
    addBanners: (state, action) => {
      state.banners = action.payload;
    },
    addFranchise: (state, action) => {
      state.franchise = action.payload;
    },
    updateCentrisDataStatus: (state, action) => {
      state.profile.centris_data_status = Profile.CENTRIS_DATA_STATUS.UNOPENED.VALUE;
    },
    setSavedProfileImageStatus: (state, action) => {
      state.savedProfileImageStatus = action.payload;
    },
  },
});

// Actions
export const { initialize, addProfile, addBanners, addFranchise, setSavedProfileImageStatus } =
  profileSlice.actions;

// Thunks
export const fetchProfileAsync = (token: string, email: string) => {
  return async (dispatch: Dispatch) => {
    const profile = await getProfile(token, email);
    dispatch(addProfile(profile));
  };
};

export const fetchBannersAsync = (token: string) => {
  return async (dispatch: Dispatch) => {
    const banners = await getBanners(token);
    if (Array.isArray(banners)) {
      const _banners: ISelectorItem[] = banners.map((item, index) => {
        return {
          value: index,
          label: item.name,
          picture: item.picture_url,
        };
      });
      dispatch(addBanners(_banners));
    }
  };
};

export const fetchFranchiseAsync = (token: string, bannerId: number) => {
  return async (dispatch: Dispatch) => {
    let franchise = await getFranchise(token, bannerId);
    if (!franchise) {
      franchise = [];
    }
    const _franchise: ISelectorItem[] = franchise.map((item, index) => {
      return {
        value: index,
        label: item.name,
        picture: item.picture_url,
        bannerId: item.banner_id,
      };
    });
    dispatch(addFranchise(_franchise));
  };
};

export const updateProfileAsync = (token: string, formdata: FormData) => {
  return async (dispatch: Dispatch) => {
    const res = await saveProfile(token, formdata);
    if (res === 200) {
      dispatch(setSavedProfileImageStatus(200));
    } else {
      dispatch(setSavedProfileImageStatus(500));
    }
    dispatch(setSavedProfileImageStatus(''));
  };
};

export default profileSlice.reducer;
