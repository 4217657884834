import _ from 'lodash';
import classNames from 'classnames';

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import UploadingFile from './UploadingFile';

const useStyles = makeStyles({
  uploadingFiles: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    marginTop: 20,

    '& .UploadingFile': {
      // marginLeft: 20,

      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  closeButton: {
    position: 'absolute',
    minWidth: 'auto',
    top: -30,
    right: 0,
    padding: 0,
    backgroundColor: 'transparent',
  },
});

interface Props {
  className: string;
  files: FileList;
  progresses: object;
  errors: object;
  onClose: () => void;
}

function UploadingFiles(props: Props) {
  const styles = useStyles();

  const { className, files, progresses, errors, onClose } = props;

  if (!Array.from(files).length) return null;

  return (
    <Box className={classNames(className, styles.uploadingFiles)}>
      <Button className={styles.closeButton} onClick={onClose}>
        <CloseIcon />
      </Button>

      {Array.from(files).map((file: File) => {
        const error = errors[file.name] ? errors[file.name] : '';
        const progress = progresses[file.name] ? progresses[file.name].percent : 0;
        return <UploadingFile key={file.name} file={file} progress={progress} error={error} />;
      })}
    </Box>
  );
}

UploadingFiles.defaultProps = {
  className: null,
  progresses: {},
  errors: {},
  onClose: _.noop,
};

UploadingFiles.propTypes = {
  className: PropTypes.string,
  files: PropTypes.object.isRequired,
  progresses: PropTypes.object,
  errors: PropTypes.object,
  onClose: PropTypes.func,
};

export default UploadingFiles;
