class Inject {
  id: number;

  shooting_id: number;

  file_count: object;

  constructor(data: object = {}) {
    Object.assign(this, data);
  }
}

export default Inject;
