import axios from 'axios';

import { Feedback } from '../models';
import { getAPIURL } from '../config/constants';

export const fetchFeedbacks = async (token: string) => {
  try {
    const config = { headers: { token } };
    const res = await axios.get(`${getAPIURL()}/getFeedbacks`, config);
    return res.data;
  } catch (err) {
    console.log('[Feedbacks] error: ', err);
  }
  return {};
};
