class Depot {
  id: number;

  lat: number;

  lng: number;

  civic_number: string;

  street: string;

  city: string;

  postal_code: string;

  province: string;

  country: string;

  constructor(data: object = {}) {
    Object.assign(this, data);
  }
}

export default Depot;
