import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { LanguageContext } from 'contexts';
import { ENG, ENGLISH, FR, FRENCH, COLOR } from 'config/constants';

interface Props {
  className?: string;
}

const useStyles = makeStyles({
  language: {
    color: COLOR.WHITE,
    padding: '0 8px',
  },
  hideContent: {
    display: 'none',
  },
});

function LanguageMobileMenu(props: Props) {
  const { className } = props;
  const styles = useStyles();
  const { language, updateLanguage } = useContext(LanguageContext);
  const onLanguageChange = (lang: string) => {
    updateLanguage(lang);
  };

  const isEnglish = language === ENG;

  return (
    <Box className={className || styles.language}>
      <Box
        className={isEnglish ? styles.hideContent : null}
        onClick={() => {
          return onLanguageChange(ENG);
        }}
      >
        <Typography className={styles.language}>{ENGLISH}</Typography>
      </Box>
      <Box
        className={!isEnglish ? styles.hideContent : null}
        onClick={() => {
          return onLanguageChange(FR);
        }}
      >
        <Typography className={styles.language}>{FRENCH}</Typography>
      </Box>
    </Box>
  );
}

export default LanguageMobileMenu;
