import _ from 'lodash';
import classNames from 'classnames';

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, InputBase, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { HvButton } from 'components';

const useStyles = makeStyles({
  searchInput: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '2px 4px',
  },
  input: {
    flex: 1,
    margin: 'auto 10px',
  },
  iconButton: {
    padding: 5,
  },
  button: {
    height: '32px',
    fontSize: '13px',
  },
});

interface Props {
  className: string;
  value: string;
  placeholder: string;
  onChange: any;
  onSearch: any;
  buttonText: string;
  showClearButton?: boolean;
  onClearSearch?: any;
}

function HvSearchInput(props: Props) {
  const styles = useStyles();
  const [text, setText] = useState('');

  const {
    className,
    placeholder,
    buttonText,
    value,
    onChange,
    onSearch,
    showClearButton,
    onClearSearch,
  } = props;

  useEffect(() => {
    setText(value);
  }, [value]);

  const onInputChange = (e: any) => {
    const newText = e.target.value;

    setText(newText);
    onChange(newText);
  };

  const onButtonClick = () => {
    onSearch(text);
  };

  const onKeyUp = (e: any) => {
    if (e.key === 'Enter') {
      onSearch(text);
    }
  };

  return (
    <Paper className={classNames(className, styles.searchInput)}>
      <IconButton className={styles.iconButton}>
        <SearchIcon />
      </IconButton>

      <InputBase
        className={styles.input}
        value={text}
        onChange={onInputChange}
        onKeyUp={onKeyUp}
        placeholder={placeholder}
      />
      {showClearButton && (
        <IconButton className={styles.iconButton}>
          <ClearRoundedIcon color='error' onClick={onClearSearch} />
        </IconButton>
      )}
      <HvButton className={styles.button} onClick={onButtonClick}>
        {buttonText}
      </HvButton>
    </Paper>
  );
}

HvSearchInput.defaultProps = {
  className: null,
  value: '',
  placeholder: 'Search',
  onChange: _.noop,
  onSearch: _.noop,
};

HvSearchInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
};

export default HvSearchInput;
