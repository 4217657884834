import axios from 'axios';
import { BROKER_PUBLIC_DOMAIN_SUFFIX, getAPIURL, LEAD_TYPES } from 'config/constants';
import { BrokerFavoriteListing, Broker, Listing } from 'models';
import { isValidUrl } from 'utils';

export async function getPageInfosFromDomain(
  token?: string,
  domain_name?: string,
  language?: string,
) {
  try {
    const config = { headers: { token } };
    let query = '';
    if (domain_name) {
      query += `?domain_name=${domain_name}`;
    }
    if (domain_name && language) {
      query += `&language=${language}`;
    }
    if (!domain_name && language) {
      query += `?language=${language}`;
    }
    const res = await axios.get(`${getAPIURL()}/getPageInfosFromDomain${query}`, config);
    let broker = {};
    const {
      no_domain_found,
      centris_listings,
      centris_listings_from_db,
      all_templates,
      google_reviews,
    } = res.data;
    let broker_favorite_listings = [];
    let broker_portal_infos = {};

    broker = new Broker(res.data.broker);

    broker_favorite_listings = res.data.favorite_listings.map((favorite_listing: any) => {
      return new BrokerFavoriteListing(favorite_listing);
    });
    broker_favorite_listings.sort((a: BrokerFavoriteListing, b: BrokerFavoriteListing) => {
      return a.order_index - b.order_index;
    });
    broker_portal_infos = res.data.broker_portal_infos;

    return {
      broker,
      broker_favorite_listings,
      broker_portal_infos,
      all_templates,
      no_domain_found,
      centris_listings,
      centris_listings_from_db,
      google_reviews,
    };
  } catch (err) {
    console.log('[getPageInfosFromDomain] error: ', err);
  }
  return null;
}

export const addFavoriteListings = async (
  token: string,
  listings: Array<Listing>,
  broker_id: string,
) => {
  try {
    const config = { headers: { token } };
    const params = { listings, broker_id };
    const res = await axios.post(`${getAPIURL()}/addFavoriteListing`, params, config);
    return res;
  } catch (error) {
    console.log('[addFavoriteListings] error: ', error);
  }
  return null;
};

export const fetchBrokerFavoriteListings = async (token: string, broker_id: string) => {
  try {
    const config = { headers: { token } };
    const res = await axios.get(
      `${getAPIURL()}/getBrokerFavoriteListings?broker_id=${broker_id}`,
      config,
    );
    const broker_favorite_listings = res.data.favorite_listings.map((favorite_listing: any) => {
      return new BrokerFavoriteListing(favorite_listing);
    });
    broker_favorite_listings.sort((a: BrokerFavoriteListing, b: BrokerFavoriteListing) => {
      return a.order_index - b.order_index;
    });
    return {
      broker_favorite_listings,
    };
  } catch (error) {
    console.log('[getBrokerFavoriteListings] error: ', error);
  }
  return null;
};

export const removeFavoriteListing = async (
  token: string,
  listing_id: string,
  broker_id: string,
) => {
  try {
    const config = { headers: { token } };
    const params = { listing_id, broker_id };
    const res = await axios.post(`${getAPIURL()}/removeListingFromFavorites`, params, config);
    return res;
  } catch (error) {
    console.log('[removeListingFromFavorites] error: ', error);
  }
  return null;
};

export const updateBrokerPublicPageInfos = async (
  token: string,
  is_domain_required: boolean,
  domain_name: string,
  custom_domain_name: string,
  bio_fr: string,
  bio_en: string,
  display_name: string,
  email: string,
  phone_number: number,
  pixelID: number,
  instagram: string,
  facebook: string,
  twitter: string,
  linkedin: string,
  youtube: string,
  tiktok: string,
  website: string,
  address: string,
  slogan_fr: string,
  slogan_en: string,
  template_id: number,
  template_style: any,
  banner_image: string,
) => {
  try {
    const config = { headers: { token } };
    const broker_domain =
      domain_name && !domain_name.includes(BROKER_PUBLIC_DOMAIN_SUFFIX)
        ? domain_name + BROKER_PUBLIC_DOMAIN_SUFFIX
        : domain_name;
    const params = {
      is_domain_required,
      domain_name: broker_domain,
      custom_domain_name,
      bio_fr,
      bio_en,
      display_name,
      email,
      phone_number,
      pixelID,
      instagram,
      facebook,
      twitter,
      linkedin,
      youtube,
      tiktok,
      website,
      address,
      slogan_fr,
      slogan_en,
      template_id,
      template_style,
      banner_image,
    };
    const res = await axios.post(`${getAPIURL()}/updateBrokerPublicPageInfos`, params, config);
    return res;
  } catch (error) {
    console.log('[updateBrokerPublicPageInfos] error: ', error);
    return error?.response?.data;
  }
};

export const verifyDomain = async (token: string, domain_name: string, type: string) => {
  try {
    const config = { headers: { token } };
    let broker_domain = domain_name;
    if (type === 'domain_name') {
      broker_domain = domain_name + BROKER_PUBLIC_DOMAIN_SUFFIX;
    }
    if (type === 'custom_domain_name') {
      const isValidURL = isValidUrl(domain_name);
      if (isValidURL) {
        broker_domain = new URL(domain_name).hostname;
        if (broker_domain.startsWith('www.')) {
          broker_domain = broker_domain.substring(4);
        }
      } else {
        broker_domain = domain_name;
      }
    }
    const res = await axios.get(
      `${getAPIURL()}/validateDomainAvailability?domain_name=${broker_domain}&type=${type}`,
      config,
    );
    const result = { type, status: res.data.status };
    return result;
  } catch (error) {
    console.log('[validateDomainAvailability] error: ', error);
  }
  return { type, status: 'error' };
};

export const updateBrokerPortalBanner = async (token: string, formdata: FormData) => {
  try {
    const config = { headers: { token } };
    const res = await axios.post(`${getAPIURL()}/updatePortalImage`, formdata, config);
    return res.data.status;
  } catch (err) {
    console.log('[updateBrokerPortalBanner] error: ', err);
  }
  return null;
};

export const updateBrokerPortalBannerProfile = async (token: string, formdata: FormData) => {
  try {
    const config = { headers: { token } };
    const res = await axios.post(`${getAPIURL()}/updatePortalImage`, formdata, config);
    return res.data.status;
  } catch (err) {
    console.log('[updateBrokerPortalBannerProfile] error: ', err);
  }
  return null;
};

export const updateBrokerPortalFavicon = async (token: string, formdata: FormData) => {
  try {
    const config = { headers: { token } };
    const res = await axios.post(`${getAPIURL()}/updatePortalImage`, formdata, config);
    return res.data.status;
  } catch (err) {
    console.log('[updateBrokerPortalFavicon] error: ', err);
  }
  return null;
};

export const updateBrokerPortalLogo = async (token: string, formdata: FormData) => {
  try {
    const config = { headers: { token } };
    const res = await axios.post(`${getAPIURL()}/updatePortalImage`, formdata, config);
    return res.data.status;
  } catch (err) {
    console.log('[updateBrokerPortalLogo] error: ', err);
  }
  return null;
};
export const updateBrokerBackgroundImage = async (token: string, formdata: FormData) => {
  try {
    const config = { headers: { token } };
    const res = await axios.post(`${getAPIURL()}/updatePortalImage`, formdata, config);
    return res.data.status;
  } catch (err) {
    console.log('[updateBrokerBackgroundImage] error: ', err);
  }
  return null;
};
export const updateListingOrderIndex = async (token: string, reordered_listings) => {
  try {
    const config = { headers: { token } };
    const params = { favorite_listings: reordered_listings };
    const res = await axios.post(`${getAPIURL()}/updateListingOrderIndex`, params, config);
    return res.data.status;
  } catch (err) {
    console.log('[updateListingOrderIndex] error: ', err);
  }
  return null;
};

export const fetchDemoListings = async (token: string) => {
  try {
    const config = { headers: { token } };
    const res = await axios.get(`${getAPIURL()}/getDemoListings`, config);
    const demo_listings = res.data.demo_listings.map((demo_listing: any) => {
      return new BrokerFavoriteListing(demo_listing);
    });
    return { demo_listings };
  } catch (err) {
    console.log('[fetchDemoListings] error: ', err);
  }
  return null;
};

export const fetchBrokerPortalListings = async (token: string): Promise<any> => {
  try {
    const res = await axios.get(`${getAPIURL()}/getBrokerPortalListings?`, {
      headers: { token },
    });
    const listings = res.data.listings.map((obj: object) => {
      return new BrokerFavoriteListing(obj);
    });
    return { listings };
  } catch (err) {
    console.log('[fetchBrokerPortalListings] error: ', err);
  }
  return {};
};

export const fetchServiceOfferDemoPictures = async () => {
  try {
    const res = await axios.get(`${getAPIURL()}/serviceOfferDemoPictures`);
    return res.data;
  } catch (err) {
    console.log('[fetchServiceOfferDemoPictures] error: ', err);
  }
  return {};
};
export const submitLeadRequest = async (formData: any) => {
  try {
    const { firstname, lastname, email, phone, message, selectedServices, destinate_user_id } =
      formData;
    const params = {
      firstname,
      lastname,
      email,
      phone_number: phone,
      type: LEAD_TYPES.public_portal,
      data: JSON.stringify({ serviceOffers: selectedServices, message }),
      destinate_user_id: destinate_user_id || null,
    };
    const res = await axios.post(`${getAPIURL()}/createLead`, params);
    return res;
  } catch (err) {
    console.log('[submitLeadRequest] error: ', err);
  }
  return null;
};

export const savePortalSectionData = async (
  token: string,
  broker_portal_info_id: number,
  section_id: number,
  sectionData: any,
  broker_id: number,
) => {
  try {
    const config = { headers: { token } };
    const params = { broker_portal_info_id, section_id, sectionData, broker_id };
    const res = await axios.post(`${getAPIURL()}/savePortalSectionData`, params, config);
    return res;
  } catch (err) {
    console.log('[savePortalSectionData] error: ', err);
  }
  return null;
};

export const updateBrokerPortalSectionsOrder = async (token: string, sections: any) => {
  try {
    const config = { headers: { token } };
    const params = { sections };
    const res = await axios.post(`${getAPIURL()}/updateBrokerPortalSectionsOrder`, params, config);
    return res;
  } catch (err) {
    console.log('[updateBrokerPortalSectionsOrder] error: ', err);
  }
  return null;
};

export const updateBrokerPortalSectionMedia = async (token: string, formdata: FormData) => {
  try {
    const config = { headers: { token } };
    const res = await axios.post(`${getAPIURL()}/updatePortalSectionMedia`, formdata, config);
    return res.data;
  } catch (err) {
    console.log('[updatePortalSectionMedia] error: ', err);
  }
  return null;
};

export const savePortalClientPageInfo = async (
  token: string,
  broker_portal_info_id: number,
  page_info: any,
) => {
  try {
    const config = { headers: { token } };
    const params = { page_info, broker_portal_info_id };
    const res = await axios.post(`${getAPIURL()}/savePortalClientPageInfo`, params, config);
    return res;
  } catch (err) {
    console.log('[savePortalClientPageInfo] error: ', err);
  }
  return null;
};

export const updateBrokerPortalClientPageMedia = async (token: string, formdata: FormData) => {
  try {
    const config = { headers: { token } };
    const res = await axios.post(`${getAPIURL()}/updatePortalClientPageMedia`, formdata, config);
    return res.data;
  } catch (err) {
    console.log('[updatePortalClientPageMedia] error: ', err);
  }
  return null;
};

export const fetchClientPageInfo = async (link: string) => {
  try {
    const res = await axios.get(`${getAPIURL()}/fetchClientPageInfo?link=${link}`);
    return res.data;
  } catch (error) {
    console.log('[fetchClientPageInfo] error: ', error);
  }
  return null;
};
