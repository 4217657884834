import _ from 'lodash';

import { ENG } from 'config/constants';
import { Marker } from 'models';

export const OUTSIDE_ROOMS = ['Backyard', 'Pool', 'Facade', 'Piscine', 'Cour arrière'];

export const FLOORS = [
  { key: 0, fr: '1er niveau/RDC', en: '1st level / Ground floor' },
  { key: 1, fr: '2e niveau', en: '2nd level' },
  { key: 2, fr: '3e niveau', en: '3nd level' },
  { key: 3, fr: '4e niveau', en: '4nd level' },
  { key: 4, fr: '5e niveau', en: '5nd level' },
  { key: -1, fr: 'Sous-sol 1', en: 'Basement 1' },
  { key: 1000, fr: 'Autre', en: 'Autre' },
];

export const FLOORING = [
  { key: 'Ardoise', fr: 'Ardoise', en: 'Slate' },
  { key: 'Béton', fr: 'Béton', en: 'Concrete' },
  { key: 'Bois', fr: 'Bois', en: 'Wood' },
  { key: 'Céramique', fr: 'Céramique', en: 'Ceramic' },
  { key: 'Granite', fr: 'Granite', en: 'Granite' },
  {
    key: 'Linoléum/Vinyle/Couvre-Planchers Souple',
    fr: 'Linoléum/Vinyle/Couvre-Planchers Souple',
    en: 'Linoleum/Vinyl/Flexible Floor Coverings',
  },
  { key: 'Marbre', fr: 'Marbre', en: 'Marble' },
  { key: 'Parqueterie', fr: 'Parqueterie', en: 'Parqueterie' },
  { key: 'Plancher flottant', fr: 'Plancher flottant', en: 'Floating floor' },
  { key: "Bois d'ingénierie", fr: "Bois d'ingénierie", en: 'Engineered Wood' },
  { key: 'Contreplaqué', fr: 'Contreplaqué', en: 'Plywood' },
  { key: 'Tapis', fr: 'Tapis', en: 'Carpet' },
  { key: 'Tuile', fr: 'Tuile', en: 'Tile' },
  { key: 'Époxy', fr: 'Epoxy', en: 'Epoxy' },
  { key: 'Autre', fr: 'Autre', en: 'Other' },
  { key: 'Aucun', fr: 'Aucun', en: 'None' },
];

export const ROOM_NAMES = [
  { key: 'Facade', fr: 'Façade', en: 'Facade' },
  { key: "Vue d'ensemble", fr: "Vue d'ensemble", en: 'Overall view' },
  { key: 'Piscine', fr: 'Piscine', en: 'Pool' },
  { key: 'Patio', fr: 'Patio', en: 'Patio' },
  { key: 'Veranda', fr: 'Véranda', en: 'Veranda' },
  { key: 'Terrasse', fr: 'Terrasse', en: 'Terrace' },
  { key: 'Balcon', fr: 'Balcon', en: 'Balcony' },
  { key: 'Cour arrière', fr: 'Cour arrière', en: 'Back yard' },
  { key: 'Cour avant', fr: 'Cour avant', en: 'Front yard' },
  { key: 'Parc', fr: 'Parc', en: 'Park' },
  { key: 'Spa', fr: 'Spa', en: 'Spa' },
  { key: "Hall d'entrée", fr: "Hall d'entrée", en: 'Hallway' },
  { key: 'Stationnement', fr: 'Stationnement', en: 'Parking' },
  { key: 'Quai de chargement', fr: 'Quai de chargement', en: 'Loading dock' },
  { key: 'Entrée', fr: 'Entrée', en: 'Entrance' },
  { key: 'Salon', fr: 'Salon', en: 'Living room' },
  { key: 'Salle à manger', fr: 'Salle à manger', en: 'Dining room' },
  { key: 'Cuisine', fr: 'Cuisine', en: 'Kitchen' },
  { key: 'Chambre des maîtres', fr: 'Chambre principale', en: 'Master bedroom' },
  { key: 'Chambre', fr: 'Chambre', en: 'Bedroom' },
  { key: 'Chambre double', fr: 'Chambre double', en: 'Double bedroom' },
  { key: 'Salle familiale', fr: 'Salle familiale', en: 'Family room' },
  { key: 'Salle de bain', fr: 'Salle de bains', en: 'Bathroom' },
  { key: 'Salle de bain attenante', fr: 'Salle de bains attenante', en: 'Adjoining bathroom' },
  { key: "Salle d'eau", fr: "Salle d'eau", en: 'Washroom' },
  { key: 'Salle de lavage', fr: 'Salle de lavage', en: 'Laundry room' },
  { key: 'Bureau', fr: 'Bureau', en: 'Office' },
  { key: 'Gym', fr: 'Gym', en: 'Gym' },
  { key: 'Piscine intérieure', fr: 'Piscine intérieure', en: 'Indoor pool' },
  { key: 'Salle de jeux', fr: 'Salle de jeux', en: 'Play room' },
  { key: 'Cinéma maison', fr: 'Cinéma maison', en: 'Home theater' },
  { key: 'Garage', fr: 'Garage', en: 'Garage' },
  { key: 'Solarium', fr: 'Solarium', en: 'Solarium' },
  { key: 'Couloir', fr: 'Couloir', en: 'Corridor' },
  { key: 'Sauna', fr: 'Sauna', en: 'Sauna' },
  { key: 'Salle électrique', fr: 'Salle électrique', en: 'Electric room' },
  { key: 'Rangement', fr: 'Rangement', en: 'Storage' },
  { key: 'Boudoir', fr: 'Boudoir', en: 'Boudoir' },
  { key: 'Garde-Robe', fr: 'Garde-Robe', en: 'Wardrobe' },
  { key: 'Walk In', fr: 'Walk In', en: 'Walk In' },
  { key: 'Coin-repas', fr: 'Coin-repas', en: 'Dining area' },
  { key: 'Réception', fr: 'Réception', en: 'Reception' },
  { key: 'Salle de réception', fr: 'Salle de réception', en: 'Reception hall' },
  { key: 'Salle de formation', fr: 'Salle de formation', en: 'Training room' },
  { key: 'Aire de jeux', fr: 'Aire de jeux', en: 'Playground' },
  { key: 'Chambre froide', fr: 'Chambre froide', en: 'Cold room' },
  { key: 'Comptoir', fr: 'Comptoir', en: 'Counter' },
  { key: 'Station de gaz', fr: 'Station de gaz', en: 'Gas station' },
  { key: 'Surface de vente', fr: 'Surface de vente', en: 'Sales area' },
  { key: 'Entrepôt', fr: 'Entrepôt', en: 'Warehouse' },
  { key: 'Cave à vin', fr: 'Cave à vin', en: 'Wine cellar' },
  { key: 'Atelier', fr: 'Atelier', en: 'Workshop' },
  { key: 'Espace commun', fr: 'Espace commun', en: 'Common area' },
  { key: 'Sous-Sol semi fini', fr: 'Sous-sol semi fini', en: 'Semi finished basement' },
  { key: 'Coin repas', fr: 'Coin repas', en: 'Dining area' },
  { key: 'Atelier', fr: 'Atelier', en: 'Workshop' },
  { key: 'Bar', fr: 'Bar', en: 'Bar' },
  { key: 'Bibliotheque', fr: 'Bibliothèque', en: 'Library' },
  { key: 'Face arriere', fr: 'Face arrière', en: 'Back facade' },
  { key: 'Mezzanine', fr: 'Mezzanine', en: 'Mezzanine' },
  { key: 'Salle de bains attenante', fr: 'Salle de bains attenante', en: 'Ensuite bathroom' },
  { key: "Vue d'ensemble", fr: "Vue d'ensemble", en: 'Overall view' },
  { key: 'Remise', fr: 'Remise', en: 'Shed' },
  { key: 'Jardin', fr: 'Jardin', en: 'Garden' },
  { key: "Bord de l'eau", fr: "Bord de l'eau", en: 'Waterfront' },
  { key: 'Salle communautaire', fr: 'Salle communautaire', en: 'Common room' },
];

class Room {
  id: number;

  roomname: string;

  height: number;

  height_inches: number;

  width: number;

  width_inches: number;

  irregular: boolean;

  floor: number;

  floor_type: string;

  metadata: object;

  marker: Marker;

  constructor(data: object = {}) {
    Object.assign(this, data);
    if (this.marker) {
      this.marker = new Marker(this.marker);
    }
  }

  get isInsideRoom(): boolean {
    return !OUTSIDE_ROOMS.includes(this.roomname);
  }

  get dimensions(): string {
    let dimention = `${this.width}'${this.width_inches}" X `;
    dimention += `${this.height}'${this.height_inches}"`;
    dimention += this.irregular ? ' irr.' : '';

    return dimention;
  }

  getFlooring(language: string): string {
    const key = language === ENG ? 'en' : 'fr';
    const ftInfo = _.find(FLOORING, (m: any) => {
      return m.key === this.floor_type;
    });
    return ftInfo ? ftInfo[key] : '-';
  }

  getRoomName(language: string): string {
    const key = language === ENG ? 'en' : 'fr';
    const rnInfo = _.find(ROOM_NAMES, (m: any) => {
      return m.key === this.roomname;
    });
    return rnInfo ? rnInfo[key] : 'Uknown';
  }

  hasDefaultMeasures(): boolean {
    if (
      this.width === 10 &&
      this.height === 10 &&
      this.width_inches === 0 &&
      this.height_inches === 0
    ) {
      return true;
    }
    return false;
  }

  getFloor(language: string): string {
    const key = language === ENG ? 'en' : 'fr';
    const floor = [-1, 0, 1, 2, 3, 4].includes(this.floor) ? this.floor : 1000;
    const rnInfo = _.find(FLOORS, (m: any) => {
      return m.key === floor;
    });
    return rnInfo ? rnInfo[key] : '-';
  }
}

export default Room;
