import _ from 'lodash';
import $ from 'jquery';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box } from '@material-ui/core';
import Logo from './Logo';
import { LanguageMenu, ProfileMenu } from './menus';

import 'styles/animations.scss';

const useStyles = makeStyles({
  header: {
    position: 'fixed',
    top: 40,
    width: '100%',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    animation: 'slide-down .4s ease-in',
    zIndex: 1000,

    '&.sticky': {
      top: 0,
      animation: 'slide-up .4s ease-out',
    },
  },
  container: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#FFF',
    borderRadius: 4,
    padding: '10px 20px',
    boxSizing: 'border-box',
    boxShadow: '5px 25px 50px rgba(34, 38, 77, 0.25)',
  },
  leftBox: {
    display: 'flex',
    alignItems: 'center',

    '& a': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  centerBox: {
    flex: '1 1',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px',

    '& a': {
      display: 'flex',
      alignItems: 'center',
      minWidth: '64px',
      color: 'rgba(0, 0, 0, 0.87)',
      boxSizing: 'border-box',
      borderRadius: '4px',
      transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      padding: '6px 16px',
      textDecoration: 'none',
      textTransform: 'capitalize',

      '&:hover, &:focus': {
        background: 'rgba(0, 0, 0, 0.04)',
      },
    },

    '& .HvMenu': {
      '& .Button': {
        height: '100%',
        fontSize: 13,
        fontWeight: 400,
        lineHeight: 'inherit',

        '&.open::after': {
          content: '""',
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: -9,
          height: 3,
          background: '#00BDF2',
        },
      },

      '& .Popover': {
        transform: 'none !important',
        top: 'calc(100% - 1px) !important',
        width: '100%',
        boxSizing: 'border-box',
        boxShadow: 'none',
        borderTop: '1px solid #CDD1D6',

        '& .MuiPaper-root': {
          borderRadius: '0 0 4px 4px',
          transition: 'none !important',
          animation: 'show-down .3s ease-in',
        },

        '& ul': {
          maxWidth: '500px',
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          margin: '0 auto',
          boxSizing: 'border-box',
          padding: '20px 15px',

          '& a': {
            width: '50%',
          },
        },
      },
    },
  },
  rightBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});

interface Props {
  left: any;
  center: any;
  rightBefore: any;
  rightAfter: any;
  showLanguageMenu?: boolean;
  public_usage?: boolean;
}

function BaseHeader({
  left,
  center,
  rightBefore,
  rightAfter,
  showLanguageMenu,
  public_usage,
}: Props) {
  const styles = useStyles();

  const onScrollPage = _.debounce(() => {
    const $header = $('header');
    if (!$header.length) return;

    if ($header.offset().top > 40) {
      $header.addClass('sticky');
    } else {
      $header.removeClass('sticky');
    }
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', onScrollPage);
    return () => {
      window.removeEventListener('scroll', onScrollPage);
    };
  }, []);

  return (
    <header className={styles.header}>
      <Container>
        <Box className={styles.container}>
          <Box className={styles.leftBox}>
            <Logo />
            {left}
          </Box>

          {center ? <Box className={styles.centerBox}>{center}</Box> : null}

          <Box className={styles.rightBox}>
            {rightBefore}
            {showLanguageMenu && <LanguageMenu />}
            {!public_usage && <ProfileMenu />}
            {rightAfter}
          </Box>
        </Box>
      </Container>
    </header>
  );
}

BaseHeader.defaultProps = {
  left: null,
  center: null,
  rightBefore: null,
  rightAfter: null,
};

BaseHeader.propTypes = {
  left: PropTypes.any,
  center: PropTypes.any,
  rightBefore: PropTypes.any,
  rightAfter: PropTypes.any,
};

export default BaseHeader;
