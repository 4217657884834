import classNames from 'classnames';

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles({
  pleaseWait: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, .3)',
    zIndex: 9000,

    '&.transparent': {
      backgroundColor: 'transparent',
    },
  },
});

interface Props {
  className: string;
  size: number;
  color?: string;
}

function HvPleaseWait(props: Props) {
  const styles = useStyles();

  const { className, size, color } = props;

  return (
    <div className={classNames(className, styles.pleaseWait)}>
      <CircularProgress
        size={size}
        disableShrink
        color='secondary'
        style={{ color: color || 'none' }}
        thickness={2}
      />
    </div>
  );
}

HvPleaseWait.defaultProps = {
  className: null,
  size: 60,
};

HvPleaseWait.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

export default HvPleaseWait;
