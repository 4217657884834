import moment from 'moment';
import { COLOR, STATE } from 'config/constants';
import { Shooting, Picture } from 'models';
import _ from 'lodash';

export function currentDateTime() {
  return moment().format('MMMM-DD-YYYY-h-mm');
}

export function initTimeForDate(_date: Date): Date {
  const date = moment(_date).hour(0).minute(0).second(0);
  return date.toDate();
}

export function fetchOldestServiceOfferDate(shooting: Shooting, pictures: Picture[]) {
  const dates = [];
  const { service_offers } = shooting;
  let filteredServiceOffers = [];
  if (!service_offers || service_offers.length === 0) {
    dates.push(moment(shooting.scheduleddate));
  } else {
    filteredServiceOffers = service_offers.filter((serviceOffer: any) => {
      return (
        [STATE.NOT_STARTED.KEY, STATE.ORDERED.KEY, STATE.ONGOING.KEY, STATE.COMPLETED.KEY].includes(
          serviceOffer.state,
        ) && serviceOffer.created_at
      );
    });
  }
  const tickets = _.flatten(
    pictures.map((picture) => {
      return picture.tickets;
    }),
  );
  const filteredTickets = tickets.filter((ticket) => {
    return [STATE.NOT_STARTED.KEY, STATE.ONGOING.KEY].includes(ticket.state);
  });
  const allDates = [...filteredServiceOffers, ...filteredTickets];
  for (let i = 0; i < allDates.length; i += 1) {
    const service: any = allDates[i];
    if (service.created_at) {
      dates.push(moment(service.created_at));
    }
  }
  if (dates.length === 0) {
    return null;
  }
  return moment.min(dates).toString();
}

export const getPriorityColor = (date: string) => {
  const days = moment().diff(moment(date), 'days');
  if (days >= 4) {
    return COLOR.RED;
  }
  if (days < 4 && days >= 2) {
    return COLOR.ORANGE;
  }
  if (days === 1) {
    return COLOR.YELLOW;
  }
  return COLOR.DARK_BLUE;
};
