import _ from 'lodash';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProfileAsync, fetchBannersAsync } from 'state/actions';
import { LOADING, IDLES } from 'state/constants';
import { ISelectorItem } from 'types';
import { useIntl } from 'react-intl';

export const useProfile = (token: string, email: string) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(LOADING.NONE);

  const { profile, savedProfileImageStatus } = useSelector((state: any) => {
    return state.profile;
  });

  const loadProfile = async () => {
    const isIdle = IDLES.includes(loading);
    if (token && isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchProfileAsync(token, email));
      setLoading(LOADING.FINISHED);
    }
  };

  useEffect(() => {
    loadProfile();
  }, [token, email]);

  return { loading, profile, savedProfileImageStatus };
};

export const useBanners = (token: string) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(LOADING.NONE);

  const { banners } = useSelector((state: any) => {
    return state.profile;
  });

  const loadBanners = async () => {
    const isIdle = IDLES.includes(loading);
    if (token && isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchBannersAsync(token));
      setLoading(LOADING.FINISHED);
    }
  };

  useEffect(() => {
    loadBanners();
  }, [token]);

  return { loading, banners };
};

export const useTitleItems = () => {
  const intl = useIntl();
  const titleItems: ISelectorItem[] = [
    {
      value: 0,
      label: intl.formatMessage({ id: 'profile.title.residentialREBroker' }),
    },
    {
      value: 1,
      label: intl.formatMessage({ id: 'profile.title.commercialREBroker' }),
    },
    {
      value: 2,
      label: intl.formatMessage({ id: 'profile.title.residentialAndCommercialREBroker' }),
    },
    {
      value: 3,
      label: intl.formatMessage({ id: 'profile.title.certifiedResidentialREBroker' }),
    },
    {
      value: 4,
      label: intl.formatMessage({ id: 'profile.title.certifiedCommercialREBroker' }),
    },
    {
      value: 5,
      label: intl.formatMessage({
        id: 'profile.title.certifiedResidentialAndCommercialREBroker',
      }),
    },
    {
      value: 6,
      label: intl.formatMessage({ id: 'profile.title.certifiedResidentialREBrokerAEO' }),
    },
    {
      value: 7,
      label: intl.formatMessage({ id: 'profile.title.certifiedCommercialREBrokerAEO' }),
    },
    {
      value: 8,
      label: intl.formatMessage({
        id: 'profile.title.certifiedResidentialAndCommercialREBrokerAEO',
      }),
    },
    {
      value: 9,
      label: intl.formatMessage({ id: 'profile.title.other' }),
    },
  ];
  return titleItems;
};
