import { useSelector, useDispatch } from 'react-redux';
import { fetchCategoriesAsync, fetchQuestionsAsync } from 'state/actions';
import { LOADING, IDLES } from 'state/constants';
import { useState, useEffect } from 'react';

export const useCategories = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(LOADING.NONE);

  const { faqCategories } = useSelector((state: any) => {
    return state.faq;
  });

  const loadCategories = async () => {
    const isIdle = IDLES.includes(loading);
    if (isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchCategoriesAsync());
      setLoading(LOADING.FINISHED);
    }
  };

  useEffect(() => {
    loadCategories();
  }, [faqCategories]);

  return { loadingCategories: loading, faqCategoriesData: faqCategories };
};

export const useQuestions = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(LOADING.NONE);

  const { faqQuestions } = useSelector((state: any) => {
    return state.faq;
  });

  const loadQuestions = async () => {
    const isIdle = IDLES.includes(loading);
    if (isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchQuestionsAsync());
      setLoading(LOADING.FINISHED);
    }
  };

  useEffect(() => {
    loadQuestions();
  }, [faqQuestions]);
  return { loadingQuestions: loading, faqQuestionsData: faqQuestions };
};
