import _, { times } from 'lodash';
import axios from 'axios';

import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Shooting, Listing, User } from 'models';
import {
  cancelShooting as cancelShootingApi,
  deleteShootingDraftApi,
  saveShootingDraft,
} from 'apis';
import {
  fetchAssignedShootingsAsync,
  fetchShootingQueueAsync,
  fetchShootingsAsync,
  fetchShootingAsync,
  fetchDistanceFeeAsync,
  removeShooting,
  hideModal,
  fetchShootingsFromTechAsync,
  fetchShootingsFromPostTechAsync,
  fetchUndeliveredShootingsAsync,
  fetchServiceOffersAsync,
  fetchAllServiceOffersAsync,
  fetchSearchResultAsync,
  fetchUserShootingsAsync,
  fetchAllServiceOfferItemsAsync,
  saveCustomServiceOfferAsync,
  fetchAllCustomServiceOffersAsync,
  deleteServiceOfferAsync,
  fetchDailyShootingActivityAsync,
  fetchAllTechLocksForTheDayAsync,
  updateTechStatusAsync,
  saveShootingParamsAsync,
  recalculateDistancesAsync,
  assignProdShootingAsync,
  changeShootingBillableAsync,
  reinitializeShootingAsync,
} from 'state/actions';
import { LOADING, IDLES } from 'state/constants';
import { useAuth } from 'hooks';
import { uploadFileWithProgress, downloadBlobFile } from 'utils';
import { SERVICE_OFFER_TYPE, getAPIURL, MODAL } from 'config/constants';

let cancelToken = null;

export const useShootings = (token?: string) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(LOADING.NONE);

  const { data } = useSelector((state: any) => {
    return state.shootings;
  });

  const loadShootings = async () => {
    const isIdle = IDLES.includes(loading);
    if (token && !data.length && isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchShootingsAsync(token));
      setLoading(LOADING.FINISHED);
    }
  };

  useEffect(() => {
    loadShootings();
  }, [token, data]);

  return { loading, shootings: data };
};

export const useShooting = (token: string, id: number) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(LOADING.NONE);

  const shooting = useSelector((state: any) => {
    return _.find(state.shootings.data, (m: Shooting) => {
      return m.id === Number(id);
    });
  });

  const loadShooting = async () => {
    const isIdle = IDLES.includes(loading);
    if (!shooting && token && id && isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchShootingAsync(token, id));
      setLoading(LOADING.FINISHED);
    }
  };

  useEffect(() => {
    loadShooting();
  }, [shooting]);

  return { loading, shooting };
};

export const useShootingQueue = (user?: User, token?: string) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(LOADING.NONE);

  const { data } = useSelector((state: any) => {
    return state.shootings;
  });

  const loadShootingQueue = async () => {
    const isIdle = IDLES.includes(loading);
    const hasPermission =
      user?.role === User.ROLES.TECH_POSTPROD_LEAD || user?.role === User.ROLES.ADMIN;
    if (token && hasPermission && !data.length && isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchShootingQueueAsync(token));
      setLoading(LOADING.FINISHED);
    }
  };

  useEffect(() => {
    loadShootingQueue();
  }, [user, token, data]);

  return { loading, shootings: data };
};

export const useAssignedShootings = (user?: User, token?: string) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(LOADING.NONE);

  const { data } = useSelector((state: any) => {
    return state.shootings;
  });

  const loadAssignedShootings = async () => {
    const isIdle = IDLES.includes(loading);
    const hasPermission = User.POSTPROD_TECH_ROLES.includes(user?.role);
    if (token && hasPermission && !data.length && isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchAssignedShootingsAsync(token));
      setLoading(LOADING.FINISHED);
    }
  };

  useEffect(() => {
    loadAssignedShootings();
  }, [user, token, data]);

  return { loading, shootings: data };
};

export const useCancelShooting = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    cancelled: false,
    cancelling: LOADING.NONE,
  });

  const cancelShooting = async (token: string, id: number, reason: string) => {
    const isIdle = IDLES.includes(state.cancelling);
    if (token && isIdle) {
      setState({ cancelled: false, cancelling: LOADING.STARTED });

      cancelShootingApi(token, id, reason).then((cancelled) => {
        if (cancelled) {
          dispatch(removeShooting(id));
          dispatch(hideModal(MODAL.DRAFT_SHOOTING_DELETE));
        }
        setState({ cancelled, cancelling: LOADING.FINISHED });
      });
    }
  };

  return { ...state, cancelShooting };
};

export const useDeleteShootingDraft = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    deleted: false,
    deleting: LOADING.NONE,
  });
  const deleteShootingDraft = async (token: string, id: number) => {
    const isIdle = IDLES.includes(state.deleting);
    if (token && isIdle) {
      setState({ deleted: false, deleting: LOADING.STARTED });

      deleteShootingDraftApi(token, id).then((deleted) => {
        if (deleted) {
          dispatch(removeShooting(id));
        }
        setState({ deleted, deleting: LOADING.FINISHED });
      });
    }
  };

  return { ...state, deleteShootingDraft };
};

export const useSaveShootingDraft = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    saved: false,
    saving: LOADING.NONE,
  });
  const saveDraft = async (token: string, shooting: Shooting, listing: Listing) => {
    const isIdle = IDLES.includes(state.saving);
    if (token && isIdle) {
      setState({ saved: false, saving: LOADING.STARTED });

      saveShootingDraft(token, shooting, listing).then((shooting) => {
        if (shooting) {
          dispatch(fetchShootingAsync(token, shooting.id));
        }
        setState({ saved: !!shooting, saving: LOADING.FINISHED });
      });
    }
  };

  return { ...state, saveDraft };
};
export const useUndeliveredShootings = (
  token: string,
  searchInput = '',
  page?: number,
  loadAll: boolean = false,
) => {
  const dispatch = useDispatch();

  const [isUndeliveredShootingsloading, setUndeliveredShootingsloading] = useState(LOADING.NONE);
  const [refresh, setRefresh] = useState(false);
  const { undeliveredShootings, pageTotalCount } = useSelector((state: any) => {
    return state.shootings;
  });
  const loadShootings = async () => {
    if (token) {
      setUndeliveredShootingsloading(LOADING.STARTED);
      await dispatch(fetchUndeliveredShootingsAsync(token, searchInput, page, loadAll));
      setUndeliveredShootingsloading(LOADING.FINISHED);
    }
  };
  useEffect(() => {
    loadShootings();
  }, [token, searchInput, page]);

  useEffect(() => {
    if (refresh) {
      loadShootings();
      setRefresh(false);
    }
  }, [refresh]);
  return {
    isUndeliveredShootingsloading,
    undeliveredShootings,
    pageTotalCount,
    refresh,
    setRefresh,
  };
};

export const useSearchResult = (
  token: string,
  searchInput = '',
  page?: number,
  loadAll: boolean = false,
) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(LOADING.NONE);

  const { searchResult, pageTotalCountForSearch } = useSelector((state: any) => {
    return state.shootings;
  });

  const loadShootings = async () => {
    if (token) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchSearchResultAsync(token, searchInput, page, loadAll));
      setLoading(LOADING.FINISHED);
    }
  };

  useEffect(() => {
    loadShootings();
  }, [token, searchInput, page, pageTotalCountForSearch]);

  return { loading, searchResult, pageTotalCountForSearch };
};

export const useFileUpload = (shootingId: number, listingId: number, fileInputRef: any) => {
  const dispatch = useDispatch();

  const { token } = useAuth();
  const [files, setFiles] = useState<FileList>({} as FileList);
  const [progresses, setProgresses] = useState<object>({});
  const [errors, setErrors] = useState<object>({});
  const [isUploading, setIsUploading] = useState<boolean>(false);

  function onUploadFiles() {
    fileInputRef.current.click();
  }

  function isInProgress(name: string) {
    return progresses[name] ? progresses[name].percent : 0;
  }

  function updateProgress(fileName: string, percentage: number) {
    setProgresses((prevProgresses) => {
      return { ...prevProgresses, [fileName]: { done: percentage === 100, percent: percentage } };
    });
  }

  function uploadFile(file: File) {
    const fileName = file.name;
    const fileType = file.type;

    const params = [];
    params.push(`listing_id=${listingId}`);
    params.push(`shooting_id=${shootingId}`);
    params.push(`filename=${fileName}`);
    params.push(`filetype=${fileType}`);

    const config = { headers: { token } };

    return axios
      .get(`${getAPIURL()}/getHDRSProcUploadURL?${params.join('&')}`, config)
      .then((result) => {
        return uploadFileWithProgress(file, result.data.url, updateProgress);
      })
      .then(() => {
        return axios.post(
          `${getAPIURL()}/setHDRSProcUploaded`,
          { shooting_id: shootingId },
          config,
        );
      })
      .catch(() => {
        setErrors({ ...errors, [fileName]: 'Failed to upload' });
      });
  }

  const startUploading = () => {
    setIsUploading(true);

    const allPromises = [];
    Array.from(files).forEach((file: File) => {
      if (!isInProgress(file.name)) {
        allPromises.push(uploadFile(file));
      }
    });

    Promise.allSettled(allPromises)
      .then(() => {
        setIsUploading(false);
        dispatch(fetchShootingAsync(token, shootingId));
      })
      .catch(() => {
        setIsUploading(false);
      });
  };

  useEffect(() => {
    if (!_.isEmpty(files)) {
      startUploading();
    }
  }, [files]);

  return { isUploading, files, progresses, errors, setFiles, onUploadFiles };
};

export const useDownloadHDRSProc = (shootingId: number, listingId: number) => {
  const { token } = useAuth();

  function onDownloadHDRSProc() {
    if (!shootingId || !listingId) return;

    const config = { headers: { token } };

    const params = [];
    params.push(`listing_id=${listingId}`);
    params.push(`shooting_id=${shootingId}`);

    axios
      .get(`${getAPIURL()}/getHDRSProcDownloadLink?${params.join('&')}`, config)
      .then((res: any) => {
        const urls = res.data.url;
        urls.forEach((url: string) => {
          window.open(url, '_blank');
        });
      });
  }

  return { onDownloadHDRSProc };
};

export const useDownloadHDRS = (shootingId: number, listingId: number) => {
  const { token } = useAuth();
  const [progress, setProgress] = useState('');

  function onDownloadHDRS() {
    if (!shootingId || !listingId) return;

    const params = [];
    params.push(`listing_id=${listingId}`);
    params.push(`shooting_id=${shootingId}`);

    const headers = { token };
    const fileName = `${listingId}.zip`;
    const url = `${getAPIURL()}/downloadHDRS?${params.join('&')}`;

    downloadBlobFile(url, fileName, headers, setProgress);
  }

  return { progress, onDownloadHDRS };
};

export const useDistanceFee = (id: number) => {
  const dispatch = useDispatch();

  const { token } = useAuth();
  const { shooting } = useShooting(token, id);
  const [loading, setLoading] = useState(LOADING.NONE);

  const getDistanceFee = async () => {
    const isIdle = IDLES.includes(loading);
    const { id, listing, additional_km } = shooting;
    if (additional_km === undefined && isIdle) {
      setLoading(LOADING.STARTED);
      const { broker, lat, lng } = listing || {};
      if (broker && lat && lng) {
        await dispatch(fetchDistanceFeeAsync(token, id, broker.id, lat, lng));
      }
      setLoading(LOADING.FINISHED);
    }
  };

  useEffect(() => {
    if (shooting) {
      getDistanceFee();
    }
  }, [shooting]);
  return { loading, distanceFee: shooting?.distanceFee };
};

export const useCanadaPlaces = (token: string) => {
  const [canadaPlaces, setCanadaPlaces] = useState([]);
  const [loadingPlaces, setPlacesLoading] = useState(LOADING.NONE);
  const [loadingPlaceDetails, setPlaceDetailsLoading] = useState(LOADING.NONE);

  const getCanadaPlaces = (searchText: string) => {
    if (cancelToken) {
      cancelToken.cancel('Search was cancelled by the user.');
      cancelToken = null;
    }

    if (!searchText) return;

    setPlacesLoading(LOADING.STARTED);

    cancelToken = axios.CancelToken.source();

    axios
      .get(`${getAPIURL()}/addressSuggestions?address=${searchText}`, {
        headers: { token },
        cancelToken: cancelToken.token,
      })
      .then((res) => {
        const { addresses } = res.data;
        const _places =
          addresses?.map((m: any) => {
            return {
              id: m.Id,
              description: `${m.Text}, ${m.Description}`,
            };
          }) || [];

        setCanadaPlaces(_places);
        setPlacesLoading(LOADING.FINISHED);
      })
      .catch((thrown) => {
        if (axios.isCancel(thrown)) {
          console.log('[getCanadaPlaces] canceled: ', thrown.message);
        } else {
          console.log('Oop! Something went wrong.');
        }
        setPlacesLoading(LOADING.FINISHED);
      });
  };

  const getCanadaPlaceDetails = (addressId: string, callback: Function) => {
    setPlaceDetailsLoading(LOADING.STARTED);

    axios
      .get(`${getAPIURL()}/addressDetails?address_id=${addressId}`, {
        headers: { token },
      })
      .then((res) => {
        callback(res.data.address);
        setPlaceDetailsLoading(LOADING.FINISHED);
      })
      .catch(() => {
        console.log('Oop! Something went wrong.');
        setPlaceDetailsLoading(LOADING.FINISHED);
      });
  };

  return {
    canadaPlacesLoading: loadingPlaces,
    setCanadaPlacesLoading: setPlacesLoading,
    canadaPlaces,
    getCanadaPlaces,
    canadaPlaceDetailsLoading: loadingPlaceDetails,
    setCanadaPlaceDetailsLoading: setPlaceDetailsLoading,
    getCanadaPlaceDetails,
  };
};

export const useShootingsFromTech = (
  user_id: number,
  startDate: string,
  endDate: string,
  assigned: any,
  token: string,
) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(LOADING.NONE);

  const { ShootingsFromTech } = useSelector((state: any) => {
    return state.shootings;
  });

  const loadShootings = async () => {
    const isIdle = IDLES.includes(loading);
    if (token && isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchShootingsFromTechAsync(user_id, startDate, endDate, assigned, token));
      setLoading(LOADING.FINISHED);
    }
  };

  useEffect(() => {
    loadShootings();
  }, [token, ShootingsFromTech, user_id, startDate, endDate]);

  return { loading, shootings: ShootingsFromTech.length };
};

export const useShootingsFromPostTech = (
  tech_id: number,
  start_date: string,
  end_date: string,
  token: string,
) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(LOADING.NONE);

  const { ShootingsFromPostTech } = useSelector((state: any) => {
    return state.shootings;
  });

  const loadShootings = async () => {
    const isIdle = IDLES.includes(loading);
    if (token && isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchShootingsFromPostTechAsync(tech_id, start_date, end_date, token));
      setLoading(LOADING.FINISHED);
    }
  };

  useEffect(() => {
    loadShootings();
  }, [token, ShootingsFromPostTech, tech_id, start_date, end_date]);

  return { loading, shootings: ShootingsFromPostTech.length };
};

export const useServiceOffers = (token: string) => {
  const dispatch = useDispatch();

  const { serviceOffers } = useSelector((state: any) => {
    return state.shootings;
  });

  const loadServiceOffers = async () => {
    if (token) {
      await dispatch(fetchServiceOffersAsync(token));
    }
  };

  useEffect(() => {
    loadServiceOffers();
  }, [token]);

  return { serviceOffers };
};

export const useAllServiceOffers = (token: string) => {
  const dispatch = useDispatch();

  const { allServiceOffers } = useSelector((state: any) => {
    return state.shootings;
  });

  const loadAllServiceOffers = async () => {
    if (token) {
      await dispatch(fetchAllServiceOffersAsync(token));
    }
  };

  useEffect(() => {
    loadAllServiceOffers();
  }, [token]);

  return { allServiceOffers };
};

export const useUserShootings = (
  user_id: number,
  date_range: any,
  is_admin = false,
  token: string,
) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(LOADING.NONE);

  const { ShootingsFromTech } = useSelector((state: any) => {
    return state.shootings;
  });

  const loadShootings = async () => {
    const isIdle = IDLES.includes(loading);
    if (token && isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchUserShootingsAsync(user_id, date_range, is_admin, token));
      setLoading(LOADING.FINISHED);
    }
  };

  useEffect(() => {
    loadShootings();
  }, [token, ShootingsFromTech, user_id, date_range]);

  return { loading, shootings: ShootingsFromTech };
};

export const useShootingServiceOffers = (token: string, serviceOffer: any) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    saved: false,
    saving: LOADING.NONE,
  });
  const { serviceOfferItems, saveCustomServiceOfferStatus } = useSelector((state: any) => {
    return state.shootings;
  });
  const serviceOfferHDRItems = serviceOfferItems.filter((item: any) => {
    return item.icon_name === SERVICE_OFFER_TYPE.HDR;
  });
  const loadShootingPackages = async () => {
    if (token) {
      dispatch(fetchAllServiceOfferItemsAsync(token));
    }
  };

  const onSave = async (
    id: number,
    selectedSubscription: number,
    selectedServiceOfferHDRItem: number,
    selectedServiceOffers: any,
    price: number,
    freelancer_prod_commission: number,
    processing_time_in_minutes: number,
    zoho_item_id: string,
    display_name: string,
    display_name_fr: string,
    description: string,
    description_fr: string,
    selectedBrokers: number[],
    selectedPhotographers: number[],
    available: boolean,
    gf_clause: boolean,
    is_global: boolean,
    recommendation_max: number,
  ) => {
    const isIdle = IDLES.includes(state.saving);
    if (token && isIdle) {
      setState({ saved: false, saving: LOADING.STARTED });
      await dispatch(
        saveCustomServiceOfferAsync(
          token,
          id,
          selectedSubscription,
          selectedServiceOfferHDRItem,
          selectedServiceOffers,
          price,
          freelancer_prod_commission,
          processing_time_in_minutes,
          zoho_item_id,
          display_name,
          display_name_fr,
          description,
          description_fr,
          selectedBrokers,
          selectedPhotographers,
          available,
          gf_clause,
          is_global,
          recommendation_max,
        ),
      );
      setState({ saved: true, saving: LOADING.FINISHED });
    }
  };
  useEffect(() => {
    loadShootingPackages();
  }, [token, serviceOffer]);

  return {
    serviceOfferItems,
    serviceOfferHDRItems,
    ...state,
    onSave,
    saveCustomServiceOfferStatus,
  };
};

export const useCustomServiceOffers = (token: string) => {
  const dispatch = useDispatch();
  const { customServiceOffers } = useSelector((state: any) => {
    return state.shootings;
  });

  const loadCustomServiceOffers = async () => {
    if (token) {
      await dispatch(fetchAllCustomServiceOffersAsync(token));
    }
  };
  useEffect(() => {
    loadCustomServiceOffers();
  }, [token]);

  return { customServiceOffers, loadCustomServiceOffers };
};

export const useDeleteShootingServiceOffer = (token: string) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    deleted: false,
    deleting: LOADING.NONE,
  });
  const { deleteShootingOfferStatus } = useSelector((state: any) => {
    return state.shootings;
  });
  const deleteServiceOffer = async (id: number) => {
    if (token) {
      setState({ deleted: false, deleting: LOADING.STARTED });
      await dispatch(deleteServiceOfferAsync(token, id));
      setState({ deleted: true, deleting: LOADING.FINISHED });
    }
  };
  return { deleteServiceOffer, deleteShootingOfferStatus, ...state };
};

export const useDailyShootingActivity = (token: string, date: string) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(LOADING.NONE);
  const { dailyShootingActivity } = useSelector((state: any) => {
    return state.shootings;
  });
  const loadDailyShootingActivity = async () => {
    if (token) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchDailyShootingActivityAsync(token, date));
      setLoading(LOADING.FINISHED);
    }
  };
  useEffect(() => {
    loadDailyShootingActivity();
  }, [token, date]);
  return { loading, dailyShootingActivity, loadDailyShootingActivity };
};

export const useAllTechLocksForTheDay = (token: string, date: string) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(LOADING.NONE);
  const { allTechLocksForTheDay, updateTechLockStatus } = useSelector((state: any) => {
    return state.shootings;
  });
  const loadAllTechLocksForTheDay = async () => {
    if (token) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchAllTechLocksForTheDayAsync(token, date));
      setLoading(LOADING.FINISHED);
    }
  };
  const updateTechStatus = async (tech_id: number, is_locked: boolean) => {
    await dispatch(updateTechStatusAsync(token, tech_id, is_locked, date));
    loadAllTechLocksForTheDay();
  };
  return {
    loading,
    allTechLocksForTheDay,
    updateTechStatus,
    updateTechLockStatus,
    loadAllTechLocksForTheDay,
  };
};

export const useSaveShootingParams = (token: string) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    saved: false,
    saving: LOADING.NONE,
  });
  const { savedShootingParamsStatus } = useSelector((state: any) => {
    return state.shootings;
  });
  const onSaveShootingParams = async (
    shooting_id: number,
    scheduleddate: string,
    room_count: number,
    package_id?: number,
  ) => {
    if (token) {
      setState({ saved: false, saving: LOADING.STARTED });
      await dispatch(
        saveShootingParamsAsync(token, shooting_id, scheduleddate, room_count, package_id),
      );
      setState({ saved: true, saving: LOADING.FINISHED });
    }
  };
  return { ...state, onSaveShootingParams, savedShootingParamsStatus };
};

export const useRecalculateShootingDistances = (token: string) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    updated: false,
    updating: LOADING.NONE,
  });
  const { recalculateDistancesStatus } = useSelector((state: any) => {
    return state.shootings;
  });
  const recalculateDistances = async (date) => {
    if (token) {
      setState({ updated: false, updating: LOADING.STARTED });
      await dispatch(recalculateDistancesAsync(token, date));
      setState({ updated: true, updating: LOADING.FINISHED });
    }
  };
  return { ...state, recalculateDistances, recalculateDistancesStatus };
};

export const useAssignProdShooting = (token: string) => {
  const dispatch = useDispatch();
  const { assignProdShootingStatus } = useSelector((state: any) => {
    return state.shootings;
  });
  const [state, setState] = useState({
    updated: false,
    updating: LOADING.NONE,
  });
  const assignProdShooting = async (
    shooting_id: number,
    tech_id: number,
    force_assigned: boolean,
  ) => {
    if (token) {
      setState({ updated: false, updating: LOADING.STARTED });
      await dispatch(assignProdShootingAsync(token, shooting_id, tech_id, force_assigned));
      setState({ updated: true, updating: LOADING.FINISHED });
    }
  };
  return { ...state, assignProdShooting, assignProdShootingStatus };
};

export const useChangeShootingBillable = (token: string) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    updated: false,
    updating: LOADING.NONE,
  });
  const changeShootingBillable = async (shooting_id: number, billable: boolean) => {
    if (token) {
      setState({ updated: false, updating: LOADING.STARTED });
      await dispatch(changeShootingBillableAsync(token, shooting_id, billable));
      setState({ updated: true, updating: LOADING.FINISHED });
    }
  };
  return { ...state, changeShootingBillable };
};

export const useReinitializeShootingFlags = (token: string) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    updated: false,
    updating: LOADING.NONE,
  });
  const { reinitializeShootingStatus } = useSelector((state: any) => {
    return state.shootings;
  });
  const reinitializeShootingFlags = async (shooting_id: number) => {
    if (token) {
      setState({ updated: false, updating: LOADING.STARTED });
      await dispatch(reinitializeShootingAsync(token, shooting_id));
      setState({ updated: true, updating: LOADING.FINISHED });
    }
  };
  return { ...state, reinitializeShootingFlags, reinitializeShootingStatus };
};
