import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { LOADING, IDLES } from 'state/constants';

export const useAiServices = () => {
  const {
    selectedPicture,
    listing_pictures,
    orderParams,
    canProceed,
    noMoreSteps,
    listing_id,
    result,
    currentStep,
    hideButtons,
    selectedService,
    is_AI_promotion_applied,
    nextAiStagingOrderIsFree,
    showDisclaimer,
    creatingOrder,
    selectedRoomType,
    selectedRoomStyle,
  } = useSelector((state: any) => {
    return state.aiServices;
  });

  return {
    selectedPicture,
    listing_pictures,
    orderParams,
    canProceed,
    noMoreSteps,
    listing_id,
    result,
    currentStep,
    hideButtons,
    selectedService,
    is_AI_promotion_applied,
    nextAiStagingOrderIsFree,
    showDisclaimer,
    creatingOrder,
    selectedRoomType,
    selectedRoomStyle,
  };
};
