import { ContrastRatioChecker } from 'contrast-ratio-checker';

export const getColorContrastRatio = (hexcolor: string, baseColor: string) => {
  const crc = new ContrastRatioChecker();
  if (hexcolor) {
    const ratio = crc.getContrastRatioByHex(hexcolor, baseColor);
    return ratio;
  }
  return 1;
};
