import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { fetchFaqCategories, fetchFaqQuestions } from 'apis';

const initialState = {
  faqQuestions: [],
  faqCategories: [],
};

export const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    initialize: (state) => {
      state.faqQuestions = [];
      state.faqCategories = [];
    },
    setFaqCategories: (state, action) => {
      state.faqCategories = action.payload;
    },
    setFaqQuestions: (state, action) => {
      state.faqQuestions = action.payload;
    },
  },
});

// Actions
export const { initialize, setFaqCategories, setFaqQuestions } = faqSlice.actions;

export const fetchQuestionsAsync = () => {
  return async (dispatch: Dispatch) => {
    const { questions } = await fetchFaqQuestions();
    if (questions) {
      dispatch(setFaqQuestions(questions));
    }
  };
};

export const fetchCategoriesAsync = () => {
  return async (dispatch: Dispatch) => {
    const { categories } = await fetchFaqCategories();
    if (categories) {
      dispatch(setFaqCategories(categories));
    }
  };
};

export default faqSlice.reducer;
