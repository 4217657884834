import _ from 'lodash';

import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { User } from 'models';
import {
  fetchBrokers,
  fetchPostProdTechs,
  fetchPhotographers,
  fetchFreelancerWithShootings,
  generateBillForFreelancer,
} from 'apis';

const initialState = {
  brokers: [],
  techns: [],
  photographers: [],
  generateBillStatus: null,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    initialize: (state) => {
      state.brokers = [];
      state.techns = [];
      state.photographers = [];
      state.generateBillStatus = null;
    },
    setBrokers: (state, action) => {
      state.brokers = action.payload;
    },
    addPostProdTechs: (state, action) => {
      state.techns.unshift(..._.compact(action.payload));
      state.techns = _.uniqBy(state.techns, (m: User) => {
        return m.id;
      });
    },
    setPhotographers: (state, action) => {
      state.photographers = action.payload;
    },
    setBillCreationStatus: (state, action) => {
      state.generateBillStatus = action.payload;
    },
  },
});

// Actions
export const { initialize, setBrokers, addPostProdTechs, setPhotographers, setBillCreationStatus } =
  usersSlice.actions;

// Thunk
export const fetchBrokersAsync = (user: User, token: string) => {
  return async (dispatch: Dispatch) => {
    const brokers = await fetchBrokers(user, token);
    if (brokers?.length) {
      dispatch(setBrokers(brokers));
    }
  };
};

export const fetchPostProdTechsAsync = (token: string) => {
  return async (dispatch: Dispatch) => {
    const prodTechs: User[] = await fetchPostProdTechs(token);
    if (prodTechs.length) {
      dispatch(addPostProdTechs(prodTechs));
    }
  };
};

export const fetchPhotographersAsync = (token: string) => {
  return async (dispatch: Dispatch) => {
    const photographers: User[] = await fetchPhotographers(token);
    if (photographers.length) {
      dispatch(setPhotographers(photographers));
    }
  };
};

export const fetchFreelancerWithShootingsAsync = (
  token: string,
  startDate: string,
  endDate: string,
) => {
  return async (dispatch: Dispatch) => {
    const photographers: User[] = await fetchFreelancerWithShootings(token, startDate, endDate);
    if (photographers.length) {
      dispatch(setPhotographers(photographers));
    }
  };
};

export const generateBillForFreelancerAsync = (
  token: string,
  photographers: User[],
  dateRange: any,
) => {
  return async (dispatch: Dispatch) => {
    const response = await generateBillForFreelancer(token, photographers, dateRange);
    if (response) {
      dispatch(setBillCreationStatus(response.status));
    } else {
      dispatch(setBillCreationStatus(500));
    }
  };
};

export default usersSlice.reducer;
