import _ from 'lodash';

import React from 'react';
import { IListingContextProps } from 'types';
import { Picture } from 'models';

const ListingContext = React.createContext({
  listing: null,

  saving: false,
  photoIndex: null,
  public_usage: false,

  openImageCarousel: _.noop,
  setPhotoIndex: _.noop,

  onSaveToDropbox: _.noop,
  onDownloadAll: _.noop,
  onDownloadPictures: _.noop,
  onPostPhotoComment: _.noop,
  onDeletePhotoComment: _.noop,
  onPostGeneralComment: _.noop,
  onDownloadVideo: _.noop,

  virtualServiceOrderItems: [],
  onDownloadDetailedFloorplan: _.noop,
  onDownloadSelectedPictures: _.noop,
  checkIfLogedIn: _.noop,
  onClickShare: _.noop,
} as IListingContextProps);

export default ListingContext;
