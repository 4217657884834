import _ from 'lodash';
import moment from 'moment';
import classNames from 'classnames';

import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Box, Button, IconButton, Typography } from '@material-ui/core';
import NavigateBeforeOutlinedIcon from '@material-ui/icons/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import { initTimeForDate } from 'utils';
import { DATEFORMAT_MY, COLOR } from 'config/constants';

const useStyles = makeStyles({
  calendar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: '15px 10px',
  },
  month: {
    color: '#171F24',
    padding: '10px',
  },
  daysContainer: {
    maxWidth: '100%',
    display: 'flex',
    margin: '0 auto',
  },
  navButton: {
    borderRadius: 0,

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  days: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: '0 10px',
  },
  day: {
    width: '60px',
    minWidth: 'auto',
    color: '#171F24',
    textAlign: 'center',
    backgroundColor: '#fff',
    borderRadius: 8,
    boxSizing: 'border-box',
    boxShadow: '0px 2px 6px rgba(34, 38, 77, 0.08), 0px 0px 1px rgba(34, 38, 77, 0.4)',
    padding: '15px 0',
    margin: '0 5px',

    '@media (max-width: 767px)': {
      width: 40,
    },

    '&.selected': {
      color: '#fff',
      backgroundColor: COLOR.LIGHT_BLUE,
    },

    '& .MuiButton-label': {
      width: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  dayLabel: {
    fontSize: 12,

    '@media (max-width: 767px)': {
      fontSize: 10,
    },
  },
  dayNumber: {
    fontSize: 22,
    fontWeight: 500,

    '@media (max-width: 767px)': {
      fontSize: 18,
    },
  },
});

interface Props {
  className: string;
  selectedDate: Date;
  disabledDates?: Array<string>;
  onChange: (_: Date) => void;
  availabilities?: Array<number>;
  isServicable?: boolean;
  direct_drive?: boolean;
  checkAvailability?: (_: Date) => boolean;
}

function HvHorizontalCalendar({
  className,
  selectedDate,
  disabledDates,
  onChange,
  availabilities,
  isServicable,
  direct_drive,
  checkAvailability,
}: Props) {
  const styles = useStyles();

  const thisRef = useRef(null);
  const today = initTimeForDate(new Date());

  const [fromDate, setFromDate] = useState(selectedDate || today);
  const [displayCount, setDisplayCount] = useState(0);
  const [activeDate, setActiveDate] = useState(today);

  const updateDisplayCount = () => {
    if (!thisRef?.current) return;

    const _dayWidth = window.innerWidth >= 768 ? 70 : 50;
    const _daysWidth = thisRef.current.offsetWidth - 120;
    setDisplayCount(_.floor(_daysWidth / _dayWidth));
  };

  useEffect(() => {
    if (selectedDate !== activeDate) {
      setActiveDate(selectedDate);
    }
  }, [selectedDate]);

  useEffect(() => {
    updateDisplayCount();

    window.addEventListener('resize', updateDisplayCount);
    return () => {
      window.removeEventListener('resize', updateDisplayCount);
    };
  }, []);

  const onDateChange = (date: Date) => {
    setActiveDate(date);
    onChange(date);
  };

  const onPreviousDates = () => {
    const mDate = moment(fromDate).add(-displayCount, 'days');
    if (mDate.isBefore(today, 'day')) {
      setFromDate(today);
    } else {
      setFromDate(mDate.toDate());
    }
  };

  const onNextDates = () => {
    const mDate = moment(fromDate).add(displayCount, 'days');
    setFromDate(mDate.toDate());
  };

  const displayDates = useMemo(() => {
    const dates = [];

    let mDate = moment(fromDate).hour(0).minute(0).second(0);
    for (let i = 0; i < displayCount; i++) {
      dates.push(mDate.toDate());
      mDate = mDate.add(1, 'day');
    }

    return dates;
  }, [displayCount, fromDate]);

  return (
    <Paper ref={thisRef} elevation={0} className={classNames(className, styles.calendar)}>
      <Box className={styles.month}>
        <Typography>{moment(fromDate).format(DATEFORMAT_MY)}</Typography>
      </Box>
      <Box className={styles.daysContainer}>
        <IconButton className={styles.navButton} disableRipple onClick={onPreviousDates}>
          <NavigateBeforeOutlinedIcon />
        </IconButton>
        <Box className={styles.days}>
          {displayDates.map((m: Date, index: number) => {
            const is_available = checkAvailability(m);
            const isSelected = moment(m).isSame(activeDate, 'day') && is_available;
            const isDisabled =
              disabledDates.includes(moment(m).format('YYYYMMDD')) || !is_available;
            return (
              <Button
                key={m.toString()}
                disabled={isDisabled}
                className={classNames(styles.day, { selected: isSelected })}
                onClick={() => {
                  onDateChange(m);
                }}
              >
                <Typography className={styles.dayLabel}>{moment(m).format('ddd')}</Typography>
                <Typography className={styles.dayNumber}>{moment(m).format('D')}</Typography>
              </Button>
            );
          })}
        </Box>
        <IconButton className={styles.navButton} disableRipple onClick={onNextDates}>
          <NavigateNextOutlinedIcon />
        </IconButton>
      </Box>
    </Paper>
  );
}

HvHorizontalCalendar.defaultProps = {
  className: null,
  selectedDate: new Date(),
  disabledDates: [],
  onChange: _.noop,
};

HvHorizontalCalendar.propTypes = {
  className: PropTypes.string,
  selectedDate: PropTypes.object,
  disabledDates: PropTypes.array,
  onChange: PropTypes.func,
};

export default HvHorizontalCalendar;
