class FaqQuestion {
  id: number;

  question_fr: String;

  question_en: String;

  answer_fr: String;

  answer_en: String;

  category_id: number;

  priority: number;

  constructor(data: object = {}) {
    Object.assign(this, data);
  }

  get faqId() {
    return this.id;
  }

  getQuestion(language: String): String {
    if (language === 'fr') {
      return `${this.question_fr}`;
    }
    return `${this.question_en}`;
  }

  getAnswer(language: string): string {
    if (language === 'fr') {
      return `${this.answer_fr}`;
    }
    return `${this.answer_en}`;
  }

  get faqCategory_id() {
    return this.category_id;
  }

  get faqPriority() {
    return this.priority;
  }
}

export default FaqQuestion;
