import React, { useState, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IMenuItem, IMenu } from 'types';

const useStyles = makeStyles({
  accordion: {
    color: 'white',
    border: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  summary: {
    minHeight: 'auto !important',

    '& .Mui-expanded': {
      margin: '12px 0',
    },
  },
  subTitle: {},
  subMenu: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 20,
  },
  menuItem: {
    color: 'white',
    fontSize: 16,
    lineHeight: '35px',
    textDecoration: 'none',
  },
});

interface Props {
  className: string;
  menuItems: IMenu[];
}

function ComplexMobileMenu(props: Props) {
  const { className, menuItems } = props;

  const styles = useStyles();
  const [expanded, setExpanded] = useState<string | boolean>(false);

  const handleChange = (panel: string) => {
    return (_: ChangeEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  };

  return (
    <Box className={className}>
      {menuItems.map((submenu: IMenu, index: number) => {
        const panelId = `panel${index}`;
        return (
          <Accordion
            key={panelId}
            className={styles.accordion}
            expanded={expanded === panelId}
            onChange={handleChange(panelId)}
            square
          >
            <AccordionSummary
              className={styles.summary}
              expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
            >
              <Typography className={styles.subTitle}>{submenu.text}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className={styles.subMenu}>
                {submenu.links.map((item: IMenuItem) => {
                  return (
                    <Link key={item.link} to={item.link} className={styles.menuItem}>
                      {item.text}
                    </Link>
                  );
                })}
              </Box>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}

ComplexMobileMenu.defaultProps = {
  className: null,
};

ComplexMobileMenu.propTypes = {
  className: PropTypes.string,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          link: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
};

export default ComplexMobileMenu;
