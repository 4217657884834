import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { getAllJobs } from 'apis';

const initialState = {
  jobs: [],
  problematicFiles: [],
};

export const bullJobsSlices = createSlice({
  name: 'bullJobs',
  initialState,
  reducers: {
    initialize: (state) => {
      state.jobs = [];
    },
    setJobs: (state, action) => {
      state.jobs = action.payload;
    },
    setProblematicFiles: (state, action) => {
      state.problematicFiles = action.payload;
    },
  },
});

// Actions
export const { initialize, setJobs, setProblematicFiles } = bullJobsSlices.actions;

export const fetchAlljobsAsync = (token: string) => {
  return async (dispatch: Dispatch) => {
    const { allJobs, problematicFiles } = await getAllJobs(token);
    if (allJobs) {
      if (allJobs.length === 0) {
        dispatch(setJobs([]));
        dispatch(setProblematicFiles([]));
      }
      dispatch(setJobs(allJobs));
      dispatch(setProblematicFiles(problematicFiles));
    }
  };
};

export default bullJobsSlices.reducer;
