export const PORTFOLIO_IMAGES = [
  'https://cdn.hauslifenetwork.com/portfolio/pictures/6207_6595_32_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/6245_6639_61_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/6457_6890_0_web-Edit.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/6457_6890_27_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/6503_6944_35_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/6505_6946_18.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/6505_6946_3-Edit.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/6522_6963_18.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/6522_6963_3.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/6524_6965_10_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/6524_6965_39_web-Edit.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/6526_6967_22_web-Edit.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/6565_7013_44_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/6565_7013_53_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/6578_7026_10_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/6610_7066_42_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/6652_7109_23_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/6695_7155_4_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/6711_7172_6-Edit.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/6731_7231_32_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/6731_7231_49_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/6759_7230_19.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/6840_7322_1_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/DJI_0392.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/DJI_0454.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/DJI_0465.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/DJI_0500.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5779_6076_19-Edit.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5779_6076_60.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5918_6228_0.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5942_6264_17.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5955_6286_17.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5955_6286_43.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5955_6286_51.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5972_6307_9_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5990_6326_52.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/6061_6430_19_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/6207_6595_0_web-Edit.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5307_6079_26.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5354_5549_27.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5354_5549_31.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5428_5630_10.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5428_5630_11.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5485_5694_2_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5485_5694_30_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5531_5758_27.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5556_5887_65.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5598_5852_11.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5598_5852_3.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5598_5852_31.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5620_5872_8_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5624_5876_26.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5634_5891_16_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5635_5892_34.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5235_5405_100.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5235_5405_123-Edit.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5235_5405_2.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5237_5408_25.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5248_5419_11.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5248_5455_14.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5248_5455_8.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5257_5433_43-Edit.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5261_5438_19.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5279_5458_10_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5288_5467_129_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5289_5468_0.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5289_5468_49.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5296_5475_45.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5306_5488_75.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5307_6044_6.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/4925_5033_71_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/4937_5049_11_web-Edit.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/4937_5049_14_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/4945_5059_16_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/4945_5059_61_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5035_5164_17.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5053_5185_14.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5086_5228_35.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5114_5263_50_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5114_5263_54_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5121_5275_15.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5121_5275_65.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5147_5301_3_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5147_5301_53_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5211_5377_12_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5211_5377_22_web-Edit.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/5217_5384_4_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/1160-rue-des-pinsons-boucherville-86.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/4388_4385_12_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/4390_4953_29_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/4390_4964_4_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/4618_4663_5_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/4713_4783_46_web-Edit.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/4893_4996_17_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/4893_4996_55_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/4893_4996_66_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/4899_5004_16_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/4899_5004_39_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/4900_5005_3_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/4912_5018_121_web.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/1160-rue-des-pinsons-boucherville-101.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/1160-rue-des-pinsons-boucherville-106.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/1160-rue-des-pinsons-boucherville-109.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/pictures/1160-rue-des-pinsons-boucherville-51.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/drone/1160-rue-des-pinsons-boucherville-17.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/drone/1160-rue-des-pinsons-boucherville-38.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/drone/1160-rue-des-pinsons-boucherville-8.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/drone/706-rue-de-la-futaie-boucherville-115.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/drone/706-rue-de-la-futaie-boucherville-116.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/drone/706-rue-de-la-futaie-boucherville-117.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/drone/706-rue-de-la-futaie-boucherville-118.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/drone/706-rue-de-la-futaie-boucherville-119.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/drone/706-rue-de-la-futaie-boucherville-126.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/drone/730-chemin-de-gasp-bromont-19.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/drone/730-chemin-de-gasp-bromont-27.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/drone/730-chemin-de-gasp-bromont-4.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/drone/8-rue-de-turin-candiac-100.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/drone/8-rue-de-turin-candiac-104.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/drone/8-rue-de-turin-candiac-92.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/drone/8-rue-de-turin-candiac-94.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/drone/8-rue-de-turin-candiac-95.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/blue_hour/1160-rue-des-pinsons-boucherville-114.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/blue_hour/1160-rue-des-pinsons-boucherville-121.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/blue_hour/1160-rue-des-pinsons-boucherville-124.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/blue_hour/1160-rue-des-pinsons-boucherville-128.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/blue_hour/706-rue-de-la-futaie-boucherville-111.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/blue_hour/706-rue-de-la-futaie-boucherville-112.jpg',
  'https://cdn.hauslifenetwork.com/portfolio/blue_hour/706-rue-de-la-futaie-boucherville-134.jpg',
  'https://cdn.hauslifenetwork.com/7210/2D/7210_7770_56_web.jpg?mars-19-2021-4-07',
  'https://cdn.hauslifenetwork.com/7210/2D/7210_7770_58_web.jpg?mars-19-2021-4-07',
  'https://cdn.hauslifenetwork.com/6908/2D/6908_7395_34_web.jpg?mars-19-2021-4-10',
];
