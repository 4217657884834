import classNames from 'classnames';

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Popper, Grow, ClickAwayListener, Paper, MenuList } from '@material-ui/core';

const useStyles = makeStyles({
  popover: {
    boxShadow:
      '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
  },
});

interface Props {
  className: string;
  buttonContent: any;
  children: any;
}

function HvMenu(props: Props) {
  const { className, buttonContent, children } = props;

  const styles = useStyles();
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(false);

  const onToggleMenu = () => {
    setOpen((prevOpen) => {
      return !prevOpen;
    });
  };

  const onHandleClose = (e: any) => {
    const element = anchorRef ? anchorRef.current : null;
    if (element && element.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  const onHandleListKeyDown = (e: any) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      setOpen(false);
    }
  };

  return (
    <Box className='HvMenu' onClick={onHandleClose}>
      <Button
        className={classNames('Button', className, { open })}
        ref={anchorRef}
        onClick={onToggleMenu}
      >
        {buttonContent}
      </Button>

      <Popper
        className={classNames('Popover', styles.popover)}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => {
          return (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center right' : 'center right',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={onHandleClose}>
                  <MenuList onKeyDown={onHandleListKeyDown}>{children}</MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          );
        }}
      </Popper>
    </Box>
  );
}

HvMenu.defaultProps = {
  className: null,
  buttonContent: null,
  children: null,
};

HvMenu.propTypes = {
  className: PropTypes.string,
  buttonContent: PropTypes.any,
  children: PropTypes.any,
};

export default HvMenu;
