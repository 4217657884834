import React, { useEffect, useState } from 'react';
import { useAlert } from 'hooks';
import { useLocation, useHistory } from 'react-router-dom';
import { Box, Typography, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { images } from 'assets/images';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { HvButton } from 'components';
import { saveFeedback } from 'apis';
import { COLOR, ALERT } from 'config/constants';

const useStyles = makeStyles({
  root: {
    flexDirection: 'column',
    backgroundImage: `url(${images.maskGroup1})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left bottom',
    backgroundSize: '50% auto',
    padding: '50px 0 50px 0',
    color: '#FFF',
  },
  dataContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: 800,
    borderRadius: 8,
    padding: 20,
    backgroundColor: COLOR.WHITE,
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  subtitle: {
    textAlign: 'center',
    fontSize: 20,
    color: COLOR.BLACK,
    marginTop: 50,
    marginBottom: 10,
    '@media (max-width: 670px)': {
      fontSize: 16,
    },
  },
  options: {
    marginBottom: 20,
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  option: {
    borderRadius: 8,
    width: 200,
    border: 'solid 1px #e8e8e8',
    backgroundColor: 'white',
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 10,
    '@media (max-width: 670px)': {
      maxWidth: 80,
    },
  },
  selectedOption: {
    borderRadius: 8,
    width: 200,
    border: 'solid 1 grey',
    backgroundColor: COLOR.LIGHT_GREY,
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 10,
    '@media (max-width: 670px)': {
      maxWidth: 80,
    },
  },
  input: {
    backgroundColor: 'white',
    bordercolor: 'white',
    marginBottom: 10,
    borderRadius: 8,
    marginTop: 10,
  },
  satisfaction: {
    fontSize: 15,
    '@media (max-width: 670px)': {
      fontSize: 13,
    },
  },
  submit: {
    marginTop: 20,
    '@media (max-width: 670px)': {
      width: '100%',
    },
    width: '20%',
    padding: 5,
    alignSelf: 'center',
  },
});

interface Props {
  className: string;
}

function FeedbackContent({ className }: Props) {
  const styles = useStyles();
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();
  const { displayAlert } = useAlert();
  const defaultSatisfaction = new URLSearchParams(location.search).get('satisfaction');
  const token = new URLSearchParams(location.search).get('feedbackToken');

  const [feedback, setFeedback] = useState('');
  const [satisfaction, setSatisfaction] = useState(Number(defaultSatisfaction));

  const submitFeedback = async () => {
    const res = await saveFeedback(feedback, Number(satisfaction), token);

    if (res && res.status === 200) {
      history.push('/thanks');
    } else {
      displayAlert(ALERT.ERROR, intl.formatMessage({ id: 'feedback.failed' }));
    }
  };
  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };
  const onChooseSatisfaction = (value) => {
    setSatisfaction(value);
  };
  return (
    <Box>
      <Typography className={styles.subtitle}>
        {intl.formatMessage({ id: 'feedback.subtitle' })}
      </Typography>
      <Box className={styles.options}>
        <Box
          className={satisfaction === 1 ? styles.selectedOption : styles.option}
          onClick={() => {
            return onChooseSatisfaction(1);
          }}
        >
          <img src={images.happy} style={{ height: 50, width: 50 }} alt='satisfied' />
          <Typography className={styles.satisfaction}>
            {intl.formatMessage({ id: 'feedback.good' })}
          </Typography>
        </Box>
        <Box
          className={satisfaction === 2 ? styles.selectedOption : styles.option}
          onClick={() => {
            return onChooseSatisfaction(2);
          }}
        >
          <img src={images.neutral} style={{ height: 50, width: 50 }} alt='neutral' />
          <Typography className={styles.satisfaction}>
            {intl.formatMessage({ id: 'feedback.neutral' })}
          </Typography>
        </Box>
        <Box
          className={satisfaction === 3 ? styles.selectedOption : styles.option}
          onClick={() => {
            return onChooseSatisfaction(3);
          }}
        >
          <img src={images.sad} style={{ height: 50, width: 50 }} alt='not satisfied' />
          <Typography className={styles.satisfaction}>
            {intl.formatMessage({ id: 'feedback.bad' })}
          </Typography>
        </Box>
      </Box>
      <TextField
        variant='outlined'
        className={styles.input}
        multiline
        onChange={handleFeedbackChange}
        rows={10}
        fullWidth
        value={feedback}
        label={intl.formatMessage({ id: 'feedback.pleaseLeaveDetailedFeedback' })}
      />
      <Box className={styles.row}>
        <HvButton
          className={styles.submit}
          onClick={() => {
            return submitFeedback();
          }}
        >
          <Typography>{intl.formatMessage({ id: 'feedback.submit' })}</Typography>
        </HvButton>
      </Box>
    </Box>
  );
}

FeedbackContent.defaultProps = {
  className: null,
};

FeedbackContent.propTypes = {
  className: PropTypes.string,
};

export default FeedbackContent;
