import classNames from 'classnames';

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  linkButton: {
    color: '#fffb',
    lineHeight: '24px',
    textDecoration: 'none',
    padding: '7px 12px',
    margin: 'auto 0',

    '&:hover': {
      textDecoration: 'none',
    },
  },
});

interface Props {
  className?: string;
  children: any;
  to: string;
  target?: string;
}

function HvLinkButton(props: Props) {
  const styles = useStyles();

  const { className, children, to, target } = props;

  return (
    <Link
      href={to}
      target={target || '_self'}
      className={classNames('HvLinkButton', className, styles.linkButton)}
    >
      {children}
    </Link>
  );
}

HvLinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

export default HvLinkButton;
