import classNames from 'classnames';
import $ from 'jquery';
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import LanguageMobileMenu from './LanguageMobileMenu';

interface IMenuItem {
  link: string;
  text: string;
}

interface Props {
  className: string;
  menuItems: IMenuItem[];
  showLanguageMenu?: boolean;
  isMobile?: boolean;
}

function SimpleMenu({ isMobile, className, menuItems, showLanguageMenu }: Props) {
  const onSelectSection = (e: any, section: string) => {
    e.preventDefault();

    const element = $(`.section.${section}`)[0];
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  const handleClickItem = (e: any, item: IMenuItem) => {
    const section = item.link.split('#')[1];
    if (section) {
      return onSelectSection(e, section);
    }
    return null;
  };
  return (
    <Box className={classNames('SimpleMenu', className)}>
      {menuItems.map((item: IMenuItem) => {
        return (
          <Link
            key={item.link}
            to={item.link}
            onClick={(e) => {
              return handleClickItem(e, item);
            }}
            style={isMobile ? { marginTop: 20 } : null}
          >
            <Typography>{item.text}</Typography>
          </Link>
        );
      })}
      {showLanguageMenu ? <LanguageMobileMenu /> : null}
    </Box>
  );
}

SimpleMenu.defaultProps = {
  className: null,
};

SimpleMenu.propTypes = {
  className: PropTypes.string,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default SimpleMenu;
