import axios from 'axios';
import { getAPIURL } from 'config/constants';

export async function getAllJobs(token: string) {
  try {
    const config = { headers: { token } };
    const response = await axios.get(`${getAPIURL()}/fetchAllJobsForFrontend`, config);
    const { jobs, problematicFiles } = response.data;
    return { allJobs: jobs, problematicFiles };
  } catch (error) {
    console.log('[getAllJobs error:]', error);
  }
}

export async function removeFailedJob(job_id: number, queue_name: string, token: string) {
  try {
    const config = { headers: { token } };
    const params = { job_id, queue_name };
    const response = await axios.post(`${getAPIURL()}/removeFailedJob`, params, config);
    return response;
  } catch (error) {
    console.log('[removeFailedJob error:]', error);
  }
}

export async function retryFailedJob(job_id: number, queue_name: string, token: string) {
  try {
    const config = { headers: { token } };
    const params = { job_id, queue_name };
    const response = await axios.post(`${getAPIURL()}/retryFailedJob`, params, config);
    return response;
  } catch (error) {
    console.log('[retryFailedJob error:]', error);
  }
}

export async function removeAllFailedJobs(token: string) {
  try {
    const config = { headers: { token } };
    const response = await axios.post(`${getAPIURL()}/removeAllFailedJobs`, {}, config);
    return response;
  } catch (error) {
    console.log('[removeFailedJob error:]', error);
  }
}

export async function retryAllFailedJobs(token: string) {
  try {
    const config = { headers: { token } };
    const response = await axios.post(`${getAPIURL()}/retryAllFailedJobs`, {}, config);
    return response;
  } catch (error) {
    console.log('[removeFailedJob error:]', error);
  }
}

export async function updateJobPriority(job_id: number, queue_name: string, token: string) {
  try {
    const config = { headers: { token } };
    const params = { job_id, queue_name };
    const response = await axios.post(`${getAPIURL()}/updateJobPriority`, params, config);
    return response;
  } catch (error) {
    console.log('[removeFailedJob error:]', error);
  }
}

export async function deleteProblematicFiles(filename: string, token: string) {
  try {
    const config = { headers: { token } };
    const params = { filename };
    const response = await axios.post(
      `${getAPIURL()}/deleteProblematicInjectorFile`,
      params,
      config,
    );
    return response;
  } catch (error) {
    console.log('[deleteProblematicInjectorFile error:]', error);
  }
}
