class DetailedFloorplan {
  id: number;

  url: string;

  shooting_service_offer_id: number;

  language: string;

  media_type: string;

  constructor(data: object = {}) {
    Object.assign(this, data);
  }
}

export default DetailedFloorplan;
