import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';
import { HvMenu } from 'components';
import { LanguageContext } from 'contexts';
import { ENG, ENGLISH, FR, FRENCH } from 'config/constants';

interface Props {
  className?: string;
}

const useStyles = makeStyles({
  language: {
    minWidth: 'auto',
    textTransform: 'none',
    padding: '8px 16px',
  },
  menuItem: {
    fontSize: '14px',
  },
});

function LanguageMenu(props: Props) {
  const { className } = props;
  const styles = useStyles();
  const { language, updateLanguage } = useContext(LanguageContext);
  const onLanguageChange = (lang: string) => {
    updateLanguage(lang);
  };

  const isEnglish = language === ENG;

  return (
    <HvMenu className={className || styles.language} buttonContent={isEnglish ? ENGLISH : FRENCH}>
      <MenuItem
        className={styles.menuItem}
        selected={isEnglish}
        onClick={() => {
          return onLanguageChange(ENG);
        }}
      >
        {ENGLISH}
      </MenuItem>
      <MenuItem
        className={styles.menuItem}
        selected={!isEnglish}
        onClick={() => {
          return onLanguageChange(FR);
        }}
      >
        {FRENCH}
      </MenuItem>
    </HvMenu>
  );
}

export default LanguageMenu;
