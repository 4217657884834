import React from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, Grid, Link, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faLinkedinIn,
  faInstagram,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import RemoveIcon from '@material-ui/icons/Remove';
import { HvLinkButton } from 'components';
import {
  BASE_URL,
  CONTACT_EMAIL_ADDRESS,
  CONTACT_PHONE_NUMBER,
  CONTACT_SOCIAL_LINKS,
} from 'config/constants';
import { images } from 'assets/images';

const useStyles = makeStyles({
  footer: {
    paddingTop: '50px',
    background: '#081027',
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 12px',
    marginBottom: '30px',

    '@media (max-width: 767px)': {
      flexDirection: 'column',
    },

    '& .HvLinkButton': {
      fontSize: '15px',
    },
  },
  topLinks: {
    '@media (max-width: 767px)': {
      marginTop: '10px',
    },
  },
  section: {
    padding: '50px 0',
    borderTop: '1px solid rgba(255,255,255,.2)',
    borderBottom: '1px solid rgba(255,255,255,.2)',

    '@media (max-width: 599px)': {
      padding: '40px 0',
    },

    '&:first-child': {
      // borderRight: '1px solid rgba(255,255,255,.2)',

      '@media (max-width: 599px)': {
        textAlign: 'center',
        borderRight: 0,
      },
    },

    '&:last-child': {
      '@media (min-width: 600px)': {
        paddingLeft: 50,
      },
    },
  },
  subTitle: {
    color: 'white',
    fontSize: '17px',
    padding: '0 12px',
    marginBottom: 15,
  },
  gridTile: {
    height: 'auto !important',
    fontSize: '11px',
    lineHeight: '24px',
    padding: '0 12px',

    '& a': {
      color: 'rgba(255,255,255,.7)',
      fontSize: '14px',
    },
  },
  description: {
    color: '#CCC',
    fontSize: '14px',
    padding: '0 12px',
    marginBottom: 15,
  },
  emailInput: {
    background: '#232837',
    margin: '0 12px',

    '& input': {
      color: '#B4B7BE',
      fontSize: '14px',
    },

    '& button': {
      borderRadius: '0 5px 5px 0',
    },
  },
  contact: {
    color: '#FFF',
    fontSize: '17px',
    padding: '42px 12px 65px',

    '@media (max-width: 1279px)': {
      padding: '56px 12px',
    },

    '@media (max-width: 991px)': {
      fontSize: '14px',
      padding: '33px 12px',
    },

    '@media (max-width: 599px)': {
      fontSize: '17px',
      borderBottom: '1px solid rgba(255, 255, 255, .2)',
    },

    '& a': {
      color: '#FFF',
      lineHeight: '20px',
    },
  },
  contactBox: {
    '@media (max-width: 599px)': {
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',

      '& *': {
        marginBottom: 10,
      },
    },
  },
  socialBox: {
    justifyContent: 'flex-end',

    '@media (max-width: 599px)': {
      justifyContent: 'center',
    },

    '& .MuiLink-root': {
      fontSize: '24px',
      color: 'rgba(255,255,255,.6)',
      marginLeft: '20px',

      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  minus: {
    color: '#AAA',
    padding: '0 5px',

    '@media (max-width: 599px)': {
      display: 'none',
    },
  },
  infos: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#FFF8',
    fontSize: '12px',
    padding: '25px 12px',

    '@media (max-width: 991px)': {
      fontSize: '10px',
    },

    '@media (max-width: 599px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      fontSize: '12px',
    },

    '& a': {
      color: '#FFF8',
      marginRight: '20px',

      '@media (max-width: 599px)': {
        lineHeight: '20px',
      },
    },
  },
  infoLeft: {
    display: 'flex',

    '@media (max-width: 599px)': {
      flexDirection: 'column',
    },
  },
  infoRight: {
    display: 'flex',
    alignItems: 'center',

    '@media (min-width: 600px)': {
      justifyContent: 'flex-end',
    },

    '& img': {
      marginLeft: 10,
    },
  },
});

function Footer() {
  const intl = useIntl();
  const styles = useStyles();
  // const [email, setEmail] = useState('');

  // const onEmailChange = useCallback((e: any) => {
  //   setEmail(e.target.value);
  // }, []);

  const topLinks = [
    { title: intl.formatMessage({ id: 'about' }), link: 'about' },
    { title: intl.formatMessage({ id: 'howItWorks' }), link: 'how-it-works' },
    { title: intl.formatMessage({ id: 'services' }), link: 'services' },
    { title: intl.formatMessage({ id: 'contact' }), link: 'contact' },
  ];

  const servicesLinks = [
    {
      title: intl.formatMessage({ id: 'footer.services.link1' }),
      link: 'service/hdr-photography',
    },
    {
      title: intl.formatMessage({ id: 'footer.services.link2' }),
      link: 'service/real-estate-video',
    },
    {
      title: intl.formatMessage({ id: 'footer.services.link3' }),
      link: 'service/real-estate-drone-video-and-photography',
    },
    {
      title: intl.formatMessage({ id: 'footer.services.link4' }),
      link: 'service/virtual-real-estate-tour',
    },
  ];

  const companyRules = [
    { title: intl.formatMessage({ id: 'footer.rules.copyRight' }), link: null },
    {
      title: intl.formatMessage({ id: 'footer.rules.legalNotice' }),
      link: 'https://hausvalet.ca/legal-notice/',
    },
    {
      title: intl.formatMessage({ id: 'footer.rules.termsOfUse' }),
      link: ' https://hausvalet.ca/terms-of-use/',
    },
    {
      title: intl.formatMessage({ id: 'footer.rules.privacyPolicy' }),
      link: 'https://hausvalet.ca/privacy-policy/',
    },
  ];

  const fmtServices = intl.formatMessage({ id: 'services' });
  // const fmtSubscribeTitle = intl.formatMessage({ id: 'footer.subscribe.title' });
  // const fmtSubscribeDescription = intl.formatMessage({ id: 'footer.subscribe.description' });

  // logo and top links
  const topLinkes = () => {
    return (
      <Box className={styles.top}>
        <Link href={BASE_URL} underline='none'>
          <img src={images.logoTitleWhite} alt='logo' />
        </Link>

        <Box className={styles.topLinks}>
          {topLinks.map((item, index) => {
            return (
              <HvLinkButton key={index} to={`${BASE_URL}/${item.link}`}>
                {item.title}
              </HvLinkButton>
            );
          })}
        </Box>
      </Box>
    );
  };

  // services and subscribe sections
  const servicesAndSubscribe = () => {
    return (
      <Grid container>
        <Grid item container className={styles.section} direction='column' xs={12} sm={12}>
          <Typography classes={{ root: styles.subTitle }}>{fmtServices}</Typography>

          <Grid container>
            {servicesLinks.map((item, index) => {
              return (
                <Grid key={index} item classes={{ root: styles.gridTile }} xs={12} sm={12} md={6}>
                  <Link href={`${BASE_URL}/${item.link}`} underline='none'>
                    {item.title}
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        {/* <Grid item container className={styles.section} direction='column' xs={12} sm={6}>
          <Typography classes={{ root: styles.subTitle }}>{fmtSubscribeTitle}</Typography>
          <Typography classes={{ root: styles.description }}>{fmtSubscribeDescription}</Typography>
          <HvEmailInput className={styles.emailInput} value={email} onChange={onEmailChange} />
        </Grid> */}
      </Grid>
    );
  };

  // contact section
  const contactSection = () => {
    return (
      <Grid container alignItems='center' className={styles.contact}>
        <Grid item container className={styles.contactBox} xs={12} sm={8}>
          <Link href={`mailto:${CONTACT_EMAIL_ADDRESS}`} underline='none'>
            {CONTACT_EMAIL_ADDRESS}
          </Link>
          <RemoveIcon className={styles.minus} />
          <Box>{CONTACT_PHONE_NUMBER}</Box>
        </Grid>

        <Grid item container className={styles.socialBox} xs={12} sm={4}>
          <Link href={CONTACT_SOCIAL_LINKS.FACEBOOK}>
            <FontAwesomeIcon icon={faFacebookF} />
          </Link>
          <Link href={CONTACT_SOCIAL_LINKS.YOUTUBE}>
            <FontAwesomeIcon icon={faYoutube} />
          </Link>
          <Link href={CONTACT_SOCIAL_LINKS.LINKEDIN}>
            <FontAwesomeIcon icon={faLinkedinIn} />
          </Link>
          <Link href={CONTACT_SOCIAL_LINKS.INSTAGRAM}>
            <FontAwesomeIcon icon={faInstagram} />
          </Link>
        </Grid>
      </Grid>
    );
  };

  // terms of use, privcacy policy, etc.
  const bottomSection = () => {
    return (
      <Box className={styles.infos}>
        <Box className={styles.infoLeft}>
          {companyRules.map((item, index) => {
            return (
              <Link key={index} href={item.link} underline='none'>
                {item.title}
              </Link>
            );
          })}
        </Box>
        {/* <Box className={styles.infoRight}>
          <span>Web agency</span>
          <img src={images.logoK} alt='logo' />
        </Box> */}
      </Box>
    );
  };

  return (
    <footer className={styles.footer}>
      <Container>
        <Grid container direction='column' wrap='nowrap'>
          {topLinkes()}
          {servicesAndSubscribe()}
          {contactSection()}
          {bottomSection()}
        </Grid>
      </Container>
    </footer>
  );
}

export default Footer;
