import _ from 'lodash';
import classNames from 'classnames';

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { images } from 'assets/images';
import { COLOR } from 'config/constants';

const useStyles = makeStyles({
  iconButton: {
    display: 'flex',
    alignItems: 'center',
    background: 'transparent',
    borderRadius: '8px',
    textTransform: 'none',
  },
  buttonIcon: {
    width: '20px',
    height: '20px',
  },
  buttonText: {
    fontSize: '12px',
    lineHeight: '14px',
    marginLeft: '5px',
    '@media (max-width: 767px)': {
      fontSize: '10px',
    },
  },
  animatedButton: {
    display: 'flex',
    alignItems: 'center',
    background: COLOR.LIGHT_BLUE,
    borderRadius: '8px',
    textTransform: 'none',
    animation: 'pulse-blue 2s infinite',
  },
  highlightedText: {
    position: 'absolute',
    top: -28,
    background: COLOR.GREEN,
    padding: '2px 5px',
    borderRadius: 4,
    fontSize: 12,
    animation: 'pulse-green 2s infinite',
    '@media (max-width: 767px)': {
      fontSize: 10,
      top: -15,
    },
  },
});

interface Props {
  id: string;
  className: string;
  icon: string;
  text: string;
  disabled?: boolean;
  show_overlay_text?: boolean;
  is_button_highlighted?: boolean;
}

function HvIconButton(props: Props) {
  const styles = useStyles();
  const intl = useIntl();

  const { className, icon, text, disabled, show_overlay_text, is_button_highlighted, ...rest } =
    props;

  const fmtClaimYourPromotion = intl.formatMessage({
    id: 'deliver.photos.virtualServiceModal.claimYourPromotion',
  });

  return (
    <Button
      disabled={disabled}
      variant='contained'
      className={classNames(
        className,
        is_button_highlighted ? styles.animatedButton : styles.iconButton,
      )}
      {...rest}
    >
      {show_overlay_text && (
        <Typography className={styles.highlightedText}>{fmtClaimYourPromotion}</Typography>
      )}
      <img src={images[icon]} className={styles.buttonIcon} alt='Icon' />
      <Typography className={styles.buttonText}>{text}</Typography>
    </Button>
  );
}

HvIconButton.defaultProps = {
  id: null,
  className: null,
  text: '',
  onClick: _.noop,
};

HvIconButton.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default HvIconButton;
