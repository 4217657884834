import _ from 'lodash';
import classNames from 'classnames';

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IOption } from 'types';

const useStyles = makeStyles({
  select: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSelect-select': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  label: {
    marginRight: 10,
  },
  dropdown: {
    flex: '1 1',
    borderRadius: 8,
    border: '1px solid #CDD1D6',
    padding: '3px 3px 3px 15px',
    cursor: 'pointer',

    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },

    '& .MuiSelect-icon': {
      right: 8,
    },

    '& input': {
      boxSizing: 'border-box',
    },

    '&::before': {
      display: 'none',
    },
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  icon: {
    marginRight: 10,
  },
});

interface Props {
  classes?: { [key: string]: string };
  className?: string;
  options: IOption[];
  value: string;
  labelText: string;
  onChange?: (s: string) => void;
  onOpen?: () => void;
  disabled?: boolean;
  showIcon?: boolean;
}

function HvSelect(props: Props) {
  const styles = useStyles();

  const [selected, setSelected] = useState('');

  const {
    classes,
    className,
    labelText,
    options,
    value,
    onChange,
    onOpen,
    disabled,
    showIcon,
    ...rest
  } = props;
  const { rootClass, labelClass, dropdownClass } = classes;

  useEffect(() => {
    if (selected !== value) {
      setSelected(value);
    }
  }, [value]);

  const onValueChange = (event: any) => {
    const _value = event.target.value;
    setSelected(_value);
    onChange(_value);
  };

  return (
    <Box className={classNames(className, rootClass, styles.select)}>
      {labelText ? (
        <label className={classNames(labelClass, styles.label)}>{labelText}</label>
      ) : null}
      <Select
        className={classNames(dropdownClass, styles.dropdown)}
        value={selected}
        onChange={onValueChange}
        onOpen={onOpen}
        disabled={!!disabled}
        disableUnderline
        {...rest}
      >
        {options.map((m: any) => {
          return (
            <MenuItem key={m.value} value={m.value} className={styles.menuItem}>
              {showIcon ? (
                <img src={m.icon} width={20} className={styles.icon} alt={m.label} />
              ) : null}
              {m.label}
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
}

HvSelect.defaultProps = {
  classes: {},
  className: null,
  options: [],
  value: '',
  labelText: '',
  onChange: _.noop,
};

HvSelect.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.string,
  labelText: PropTypes.string,
  onChange: PropTypes.any,
};

export default HvSelect;
