import axios from 'axios';

import { Profile } from 'models';
import { getAPIURL } from 'config/constants';

export const getProfile = async (token: string, email: string): Promise<any> => {
  try {
    const config = { headers: { token } };
    const res = await axios.get(`${getAPIURL()}/profile?email=${email}`, config);
    const profile = new Profile(res.data.account);
    return profile;
  } catch (err) {
    console.log('[getProfile] error: ', err);
  }
  return null;
};

export const getBanners = async (token: string) => {
  try {
    const config = { headers: { token } };
    const res = await axios.get(`${getAPIURL()}/banner`, config);
    return res.data.result;
  } catch (err) {
    console.log('[getBanners] error: ', err);
  }
  return null;
};

export const getFranchise = async (token: string, bannerId: number) => {
  try {
    const config = { headers: { token } };
    const res = await axios.get(`${getAPIURL()}/franchises?bannerId=${bannerId}`, config);

    return res.data.result;
  } catch (err) {
    console.log('[getFranchise] error: ', err);
  }
  return null;
};

export const saveProfile = async (token: string, profile: FormData) => {
  try {
    const config = { headers: { token } };
    const res = await axios.post(`${getAPIURL()}/profile`, profile, config);
    return res.data.status;
  } catch (err) {
    console.log('[saveProfile] error: ', err);
  }
  return null;
};

export const toggleSyncTeamCalendar = async (token: string, syncTeamCalendar: boolean) => {
  try {
    const config = { headers: { token } };
    await axios.put(`${getAPIURL()}/toggleSyncTeamCalendar`, syncTeamCalendar, config);
    // update sync team calendar
  } catch (err) {
    console.log('[toggleSyncTeamCalendar] error: ', err);
  }
  return null;
};

export const initializeCentrisTransferRequest = async (token: string) => {
  try {
    const config = { headers: { token } };
    await axios.post(`${getAPIURL()}/sendCentrisTransferDocument`, {}, config);
    // update centris data status as UNOPENED
  } catch (err) {
    console.log('[initializeCentrisTransferRequest] error: ', err);
  }
  return null;
};
