import classNames from 'classnames';

import React from 'react';
import { useHistory } from 'react-router';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { BaseHeader } from 'pages/shared';
import { COLOR } from 'config/constants';
import { images } from 'assets/images';
import { HvButton } from 'components';

const useStyles = makeStyles({
  root: {
    flexDirection: 'column',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left bottom',
    backgroundSize: '50% auto',
    padding: '120px 0 50px 0',
    color: COLOR.ULTRA_DARK_BLUE,
    backgroundColor: COLOR.ULTRA_DARK_BLUE,
  },
  thanksContent: {
    width: '80%',
    alignItems: 'center',
    backgroundColor: COLOR.WHITE,
    marginTop: 30,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    borderRadius: 8,
  },
  icon: {
    width: 100,
    height: 100,
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginTop: 10,
    textAlign: 'center',
    marginBottom: 20,
    '@media (max-width: 600px)': {
      fontSize: '1.5rem',
    },
  },
  subtitle: {
    marginBottom: 30,
  },
  button: {
    width: 150,
  },
});

function ThankYou() {
  const intl = useIntl();
  const styles = useStyles();
  const history = useHistory();
  const fmtThankYouForSubscription = intl.formatMessage({ id: 'plan.thankYouForSubscription' });
  const fmtReceiveEmail = intl.formatMessage({ id: 'plan.receiveEmail' });

  return (
    <Box className={classNames('Page', styles.root)}>
      <BaseHeader showLanguageMenu />
      <Box className={styles.thanksContent}>
        <img src={images.thumbsup} className={styles.icon} alt='thumbs up' />
        <Typography className={styles.title}>{fmtThankYouForSubscription}</Typography>
        <Typography className={styles.subtitle}>{fmtReceiveEmail}</Typography>
        <HvButton
          className={styles.button}
          onClick={() => {
            return history.push('/login');
          }}
        >
          {intl.formatMessage({ id: 'login' })}
        </HvButton>
      </Box>
    </Box>
  );
}

export default ThankYou;
