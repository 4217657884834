class Video {
  name: string;

  display_name: string;

  display_name_fr: string;

  url: string;

  youtube_video_id: string;

  is_public: boolean;

  id: number;

  is_slideshow: boolean;

  constructor(data: object = {}) {
    Object.assign(this, data);
  }
}

export default Video;
