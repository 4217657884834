class PictureMetaData {
  id: number;

  listing_picture_id: number;

  metadata_key: string;

  metadata_value: [];

  constructor(data: object = {}) {
    Object.assign(this, data);
  }
}

export default PictureMetaData;
