import axios from 'axios';

import { User, Shooting } from 'models';
import { getAPIURL, USER_ROLE } from 'config/constants';

export const fetchBrokers = async (user: User, token: string) => {
  try {
    const config = { headers: { token } };
    const res = await axios.get(`${getAPIURL()}/getUsers?role=${USER_ROLE.BROKER}`, config);
    const brokers = res.data?.user?.map((m: object) => {
      return new User(m);
    });
    return brokers;
  } catch (err) {
    console.log('[fetchBroker] error: ', err);
  }
  return [];
};

export const fetchPostProdTechs = async (token: string): Promise<User[]> => {
  try {
    const config = { headers: { token } };
    const res = await axios.get(`${getAPIURL()}/getPostProdTechs`, config);
    const users = res.data.users.map((obj: object) => {
      return new User(obj);
    });
    return users;
  } catch (err) {
    console.log('[fetchPostProdTechs] error: ', err);
  }
  return [];
};

export const fetchPhotographers = async (token: string): Promise<User[]> => {
  try {
    const config = { headers: { token } };
    const res = await axios.get(`${getAPIURL()}/getPhotographers`, config);
    const users = res.data.users.map((obj: object) => {
      return new User(obj);
    });
    return users;
  } catch (err) {
    console.log('[fetchPhotographers] error: ', err);
  }
  return [];
};

export async function generateBillForFreelancer(
  token: string,
  photograpers: User[],
  dateRange: any,
) {
  try {
    const config = { headers: { token } };
    const params = {
      freelance_photographers: photograpers,
      start_date: dateRange.startDate,
      end_date: dateRange.endDate,
    };
    const response = await axios.post(`${getAPIURL()}/generateBillForFreelancer`, params, config);
    if (response === undefined) {
      return null;
    }
    return response;
  } catch (err) {
    console.log('[generateBillForFreelancer] error: ', err);
  }
}

export async function fetchFreelancerWithShootings(
  token: string,
  startDate: string,
  endDate: string,
) {
  const config = { headers: { token } };
  const params = {
    start_date: startDate,
    end_date: endDate,
  };
  const response = await axios.post(`${getAPIURL()}/fetchFreelancerWithShootings`, params, config);
  const photographers = response.data.photographers.map((obj: object) => {
    return new User(obj);
  });
  return photographers;
}

export const saveFeedback = async (feedback: string, satisfaction: number, token: string) => {
  try {
    const config = { headers: { token } };
    const params = {
      feedback,
      satisfaction,
    };
    const res = await axios.post(`${getAPIURL()}/saveFeedback`, params, config);
    return res;
  } catch (error) {
    console.log('[generateBillForFreelancer] error: ', error);
  }
  return null;
};
