import { SUBSCRIPTION, PICTURE_SERVICE_OFFER } from 'config/constants';

function getCentrisListingPrice(listing) {
  let display_price;
  if (listing.price && listing.price > 1) {
    display_price = listing.price;
  }
  if (listing.price === 1 && listing.location_price && listing.rent_price_frequency) {
    display_price = listing.location_price;
  }
  if (listing.price > 1 && listing.location_price > 1 && listing.rent_price_frequency) {
    display_price = Math.min(listing.price, listing.location_price);
  }
  if (listing.price_plus_taxes) {
    display_price = listing.price_plus_taxes;
  }
  if (listing.price_including_taxes) {
    display_price = listing.price_including_taxes;
  }
  return display_price;
}

function getPictureServiceOfferIdsCopy(pictureServiceOfferIdsCopy, subscription_id: number) {
  switch (subscription_id) {
    case SUBSCRIPTION.plateforme:
      pictureServiceOfferIdsCopy.traditional.id = PICTURE_SERVICE_OFFER.plateformeTraditionalPic;
      pictureServiceOfferIdsCopy.drone.id = PICTURE_SERVICE_OFFER.plateformeDronePic;
      pictureServiceOfferIdsCopy.blueHour.id = PICTURE_SERVICE_OFFER.plateformeBlueHourPic;
      break;
    case SUBSCRIPTION.application:
      pictureServiceOfferIdsCopy.traditional.id = PICTURE_SERVICE_OFFER.applicationTraditionalPic;
      pictureServiceOfferIdsCopy.drone.id = PICTURE_SERVICE_OFFER.applicationDronePic;
      pictureServiceOfferIdsCopy.blueHour.id = PICTURE_SERVICE_OFFER.applicationBlueHourPic;
      break;
    case SUBSCRIPTION.carte:
      pictureServiceOfferIdsCopy.traditional.id = PICTURE_SERVICE_OFFER.carteTraditionalPic;
      pictureServiceOfferIdsCopy.drone.id = PICTURE_SERVICE_OFFER.carteDronePic;
      pictureServiceOfferIdsCopy.blueHour.id = PICTURE_SERVICE_OFFER.carteBlueHourPic;
      break;
    default:
      pictureServiceOfferIdsCopy.traditional.id = PICTURE_SERVICE_OFFER.carteTraditionalPic;
      pictureServiceOfferIdsCopy.drone.id = PICTURE_SERVICE_OFFER.carteDronePic;
      pictureServiceOfferIdsCopy.blueHour.id = PICTURE_SERVICE_OFFER.carteBlueHourPic;
      break;
  }
  return pictureServiceOfferIdsCopy;
}
export { getCentrisListingPrice, getPictureServiceOfferIdsCopy };
