import _ from 'lodash';
import classNames from 'classnames';

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  themeButton: {
    borderRadius: 8,
    background: '#00BDF2',
    color: '#fff',
    fontFamily: 'Ford Antenna',
    textTransform: 'none',
    transition: '.5s',
    boxSizing: 'border-box',
    padding: '6px 20px',

    '&:hover': {
      background: '#034ea2',
    },

    '& img': {
      marginRight: '5px',
    },
  },
});

interface Props {
  className?: string;
  style?: any;
  title?: string;
  disabled?: boolean;
  children?: any;
  onClick?: any;
}

function HvButton(props: Props) {
  const styles = useStyles();

  const { className, children, ...rest } = props;

  return (
    <Button className={classNames('HvButton', className, styles.themeButton)} {...rest}>
      {children}
    </Button>
  );
}

HvButton.defaultProps = {
  className: null,
  title: null,
  disabled: false,
  children: null,
  onClick: _.noop,
};

HvButton.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.any,
};

export default HvButton;
