import { useSelector, useDispatch } from 'react-redux';
import { getListingForReviewAsync, getReviewerTokenAsync } from 'state/actions';
import { LOADING, IDLES } from 'state/constants';
import { useState, useEffect } from 'react';

export const useListingForReview = (listing_id: number, token: string) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(LOADING.NONE);

  const { listing, reviewerToken } = useSelector((state: any) => {
    return state.listingReview;
  });

  const loadListing = async () => {
    const isIdle = IDLES.includes(loading);
    if (token && isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(getListingForReviewAsync(listing_id, token));
      await dispatch(getReviewerTokenAsync(token));
      setLoading(LOADING.FINISHED);
    }
  };

  const loadReviewToken = async () => {
    const isIdle = IDLES.includes(loading);
    if (token && isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(getReviewerTokenAsync(token));
      setLoading(LOADING.FINISHED);
    }
  };

  useEffect(() => {
    loadListing();
    loadReviewToken();
  }, [token, listing_id]);

  return { loading, listing, reviewerToken };
};
