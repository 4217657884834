import React, { useState, useEffect } from 'react';
import { makeStyles, Menu, MenuItem, Box, Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useLanguage } from 'hooks';

interface compactMenuProps {
  menuItems: any[];
}

const useStyles = makeStyles({
  menuRoot: {
    display: 'flex',
  },
});

function compactMenu({ menuItems }: compactMenuProps) {
  const classes = useStyles();
  const language = useLanguage();
  const [groupedItems, setGroupedItems] = useState<any>(null);
  useEffect(() => {
    if (menuItems.length > 0) {
      const grouped = {};
      menuItems.forEach((item) => {
        if (!grouped[item.type]) {
          grouped[item.type] = [item];
          grouped[item.type].open = null;
        } else {
          grouped[item.type].push(item);
        }
      });
      setGroupedItems(grouped);
    }
  }, [language]);

  function openMenu(event: React.MouseEvent<HTMLButtonElement>, menuKey: string) {
    const groupedItemsCopy = { ...groupedItems };
    groupedItemsCopy[menuKey].open = event.currentTarget;
    setGroupedItems(groupedItemsCopy);
  }

  function closeMenu(menuKey: string) {
    const groupedItemsCopy = { ...groupedItems };
    groupedItemsCopy[menuKey].open = null;
    setGroupedItems(groupedItemsCopy);
  }

  function subMenus() {
    return Object.keys(groupedItems).map((key, index: number) => {
      return (
        <Box key={index}>
          <Button
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              openMenu(event, key);
            }}
          >
            <Typography style={{ fontSize: 12 }}>{key}</Typography>
          </Button>
          <Menu
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            anchorEl={groupedItems[key].open}
            keepMounted
            open={Boolean(groupedItems[key].open)}
            onClose={() => {
              closeMenu(key);
            }}
          >
            {groupedItems[key].map((item: any, index: number) => {
              return (
                <MenuItem key={index}>
                  <Link to={item.link}>
                    <Typography>{item.text}</Typography>
                  </Link>
                </MenuItem>
              );
            })}
          </Menu>
        </Box>
      );
    });
  }

  return groupedItems && <Box className={classes.menuRoot}>{subMenus()}</Box>;
}

export default compactMenu;
