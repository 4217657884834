import React, { useEffect } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';
import { Paper, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Subscription from 'models/Subscription';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { PLANS, COLOR, ENG, SUBSCRIPTION, ZOHO_REFERRAL_CODE_API_NAME } from 'config/constants';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { HvButton } from 'components';
import { useLanguage } from 'hooks';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    width: '30%',
    minWidth: 350,
    height: 'auto',
    margin: '0 20px',
    boxSizing: 'border-box',
    '@media (max-width: 960px)': {
      margin: '0 5px',
    },
    '@media (max-width: 769px)': {
      minHeight: 0,
      marginTop: '50px',
      marginLeft: '10px',
      marginRight: '10px',
    },
  },
  freeContainer: {
    '@media (max-width: 769px)': {
      marginTop: '0px',
    },
  },
  activeContainer: {
    zIndex: 1,
    backgroundColor: '#1074BC0A',
    borderColor: 'black',
    borderStyle: 'dotted',
    borderWidth: 'thin',
  },
  content: {
    width: '100%',
    height: 'auto',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
  contentBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 500,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginTop: 10,
  },
  price: {
    fontWeight: 'bold',
    fontSize: 25,
    marginBottom: 0,
    fontFamily: 'FordAntenna-Bold',
  },
  interval_unit: {
    fontSize: 15,
    marginBottom: 0,
  },
  subscribeButton: {
    width: '90%',
    justifyContent: 'center',
    marginTop: 20,
  },
  items: {
    marginBottom: 10,
    marginTop: 20,
  },
  description: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'left',
    alignItems: 'center',
  },
  icon: {
    marginRight: 10,
    color: COLOR.LIGHT_BLUE,
  },
  bestValue: {
    position: 'absolute',
    width: 100,
    height: 30,
    top: -10,
    right: '36%',
    alignItems: 'center',
    zIndex: 2,
    borderRadius: 5,
    backgroundColor: COLOR.LIGHT_BLUE,
  },
  bestValueText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: COLOR.WHITE,
  },
  boldText: {
    fontWeight: 'bold',
    fontSize: 14,
    fontFamily: 'FordAntenna-Bold',
  },
  packageTitle: {
    color: COLOR.LIGHT_BLUE,
    fontFamily: 'FordAntenna-Bold',
    fontSize: 18,
  },
  descriptionText: {
    fontSize: 14,
  },
  discountContent: {
    marginTop: 5,
    width: 'auto',
    alignSelf: 'center',
    backgroundColor: COLOR.LIGHT_RED_CONTENT,
    borderRadius: 5,
    padding: 5,
  },
  discount: {
    marginLeft: 10,
    color: COLOR.RED_TEXT,
  },
});

interface Props {
  className: string;
  plan: Subscription;
  interval_unit: number;
  selectedPlan: number;
  setSelectedIframeLink: (link: string) => void;
  setShowCheckout: (showCheckout: boolean) => void;
  referral_code?: string;
}

function PlanItem({
  className,
  plan,
  interval_unit,
  selectedPlan,
  setSelectedIframeLink,
  setShowCheckout,
  referral_code,
}: Props) {
  const intl = useIntl();
  const styles = useStyles();
  const language = useLanguage();
  const isEnglish = language === ENG;

  const fmtFree = intl.formatMessage({ id: 'plan.free' });
  const fmtMonth = intl.formatMessage({ id: 'plan.month' });
  const fmtQuarter = intl.formatMessage({ id: 'plan.quarter' });
  const fmtYear = intl.formatMessage({ id: 'plan.year' });
  const fmtSubscribe = intl.formatMessage({ id: 'plan.subscribe' });
  const fmtBestValue = intl.formatMessage({ id: 'plan.bestValue' });
  const fmtSave = intl.formatMessage({ id: 'plan.save' });

  const is_platform = plan?.id === SUBSCRIPTION.plateforme;

  const getPlanKey = () => {
    let key = 'basic';
    if (plan?.id === SUBSCRIPTION.carte) {
      key = 'basic';
    } else if (plan?.id === SUBSCRIPTION.application) {
      key = 'application';
    } else if (plan?.id === SUBSCRIPTION.plateforme) {
      key = 'platform';
    }
    return key;
  };
  const getPlanLink = () => {
    let link = '';
    if (interval_unit === PLANS.TERM.MONTHLY) {
      link = plan?.monthly?.url;
    } else if (interval_unit === PLANS.TERM.QUARTERLY) {
      link = plan?.quarterly?.url;
    } else if (interval_unit === PLANS.TERM.YEARLY) {
      link = plan?.yearly?.url;
    }
    if (referral_code) {
      link += `?${ZOHO_REFERRAL_CODE_API_NAME}=${referral_code}`;
    }
    return link;
  };
  const getPlanPrice = () => {
    let display_price = null;
    if (interval_unit === PLANS.TERM.MONTHLY) {
      display_price = plan?.monthly?.price;
    } else if (interval_unit === PLANS.TERM.QUARTERLY) {
      display_price = plan?.quarterly?.price;
    } else if (interval_unit === PLANS.TERM.YEARLY) {
      display_price = plan?.yearly?.price;
    }
    return `$ ${display_price}`;
  };
  const getIntervalUnit = () => {
    let interval = '';
    if (interval_unit === PLANS.TERM.MONTHLY) {
      interval = fmtMonth;
    } else if (interval_unit === PLANS.TERM.QUARTERLY) {
      interval = fmtQuarter;
    } else if (interval_unit === PLANS.TERM.YEARLY) {
      interval = fmtYear;
    }
    return plan?.monthly?.price ? ` / ${interval}` : '';
  };
  return (
    <Paper id='planItem' elevation={3} className={classNames(className, styles.container)}>
      <Box className={styles.content}>
        {/* {is_platform ? (
          <Box className={styles.bestValue}>
            <Typography variant='caption' className={styles.bestValueText}>
              {fmtBestValue}
            </Typography>
          </Box>
        ) : null} */}
        <Box className={styles.contentBody}>
          <Box className={styles.items}>
            {plan?.items?.map((item: any) => {
              return (
                <Box key={item.id} className={styles.description}>
                  <CheckCircleIcon className={styles.icon} />
                  <Typography
                    className={
                      item.subscription_item.is_bold ? styles.boldText : styles.descriptionText
                    }
                  >
                    {isEnglish
                      ? item.subscription_item.description
                      : item.subscription_item.description_fr}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          <Box>
            <Typography className={styles.packageTitle}>
              {intl.formatMessage({ id: `plan.${getPlanKey()}` })}
            </Typography>
            <Box className={styles.row}>
              <Typography className={styles.price}>{getPlanPrice()}</Typography>
              <Typography className={styles.interval_unit}>{getIntervalUnit()}</Typography>
            </Box>
            <HvButton
              className={styles.subscribeButton}
              onClick={() => {
                setSelectedIframeLink(getPlanLink());
                setShowCheckout(true);
              }}
            >
              {fmtSubscribe}
            </HvButton>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}

PlanItem.defaultProps = {
  className: '',
  plan: {},
  selectedPlan: 0,
};

PlanItem.propTypes = {
  className: PropTypes.string,
  plan: PropTypes.object,
  selectedPlan: PropTypes.number,
};

export default PlanItem;
