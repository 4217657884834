import classNames from 'classnames';

import React from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  error404: {
    flex: '1 1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
  icon: {
    color: 'white',
    fontSize: '10rem',
  },
});

function Error404() {
  const intl = useIntl();
  const styles = useStyles();

  const fmtErrorMsg = intl.formatMessage({ id: 'notFound' });

  return (
    <div className={styles.error404}>
      <i className={classNames('material-icons', styles.icon)}>cloud_off</i>
      <h2>{fmtErrorMsg}</h2>
    </div>
  );
}

export default Error404;
