import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { getListingForReview, getReviewerToken } from 'apis';

const initialState = {
  listing: null,
  reviewerToken: null,
};

export const listingReviewSlice = createSlice({
  name: 'listingReview',
  initialState,
  reducers: {
    initialize: (state) => {
      state.listing = null;
      state.reviewerToken = null;
    },
    setListing: (state, action) => {
      state.listing = action.payload;
    },
    setReviewerToken: (state, action) => {
      state.reviewerToken = action.payload;
    },
  },
});

export const { initialize, setListing, setReviewerToken } = listingReviewSlice.actions;

export const getListingForReviewAsync = (listing_id: number, token: string) => {
  return async (dispatch: Dispatch) => {
    const listing = await getListingForReview(listing_id, token);
    if (listing) {
      dispatch(setListing(listing));
    }
  };
};

export const getReviewerTokenAsync = (token: string) => {
  return async (dispatch: Dispatch) => {
    const reviewerToken = await getReviewerToken(token);
    if (reviewerToken) {
      dispatch(setReviewerToken(reviewerToken));
    }
  };
};

export default listingReviewSlice.reducer;
