import { useState, useEffect } from 'react';
import { COLOR } from 'config/constants';
import { images } from 'assets/images';

export const useBlueBody = () => {
  useEffect(() => {
    document.body.style.backgroundColor = COLOR.ULTRA_DARK_BLUE;

    return () => {
      document.body.style.backgroundColor = COLOR.WHITE;
    };
  });
};

export const useLogoImage = () => {
  const [base64Logo, setBase64Logo] = useState('');

  const toDataURL = (src: any, callback: Function) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      const canvas: any = document.createElement('CANVAS');
      const ctx = canvas.getContext('2d');
      canvas.height = img.naturalHeight;
      canvas.width = img.naturalWidth;
      ctx.drawImage(img, 0, 0);
      callback(canvas.toDataURL('image/png'));
    };
    img.src = src;
  };

  useEffect(() => {
    toDataURL(images.logo_horizontal, (dataUrl: string) => {
      setBase64Logo(dataUrl);
    });
  }, []);

  return base64Logo;
};
