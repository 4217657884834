import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  token: null,
  language: null,
  modals: [],
  sidebars: [],
  alert: null,
  broker_domain_name: null,
  subscription_plans: [],
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    initialize: (state) => {
      state.user = null;
      state.token = null;
      state.modals = [];
      state.sidebars = [];
      state.broker_domain_name = null;
      state.subscription_plans = [];
    },
    setUser: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    showModal: (state, action) => {
      state.modals.push(action.payload);
    },
    hideModal: (state, action) => {
      state.modals = state.modals.filter((m) => {
        return m.type !== action.payload;
      });
    },
    showSideBar: (state, action) => {
      state.sidebars.push(action.payload);
    },
    hideSideBar: (state, action) => {
      state.sidebars = state.sidebars.filter((m) => {
        return m.type !== action.payload;
      });
    },
    showAlert: (state, action) => {
      state.alert = action.payload;
    },
    hideAlert: (state) => {
      state.alert = null;
    },
    setBrokerDomain: (state, action) => {
      state.broker_domain_name = action.payload;
    },
    setSubscriptionPlans: (state, action) => {
      state.subscription_plans = action.payload;
    },
  },
});

// Actions
export const {
  initialize,
  setUser,
  setLanguage,
  showModal,
  hideModal,
  showSideBar,
  hideSideBar,
  showAlert,
  hideAlert,
  setBrokerDomain,
  setSubscriptionPlans,
} = appSlice.actions;

export default appSlice.reducer;
