import { createSlice, Dispatch } from '@reduxjs/toolkit';
import moment from 'moment';
import {
  addAvailabilityForTechs,
  fetchUsersCalendar,
  fetchUserCalendar,
  removeAvailabilityForTechs,
  createUserUnavailability,
  removeUnavailability,
} from 'apis';

const initialState = {
  modalType: '',
  currentView: 'month',
  currentDate: moment().toDate(),
  currentMonth: moment().month(),
  events: [],
  schedule: null,
  selectedPhotographers: [],
  addedAvailabilityStatus: null,
  removedAvailabilityStatus: null,
  availabilityToBeDeleted: null,
  unavailabilityToBeDeleted: null,
  shootsToDisplay: [],
  addedUnavailabilityStatus: null,
  removedUnavailabilityStatus: null,
};

export const scheduleCalendarSlice = createSlice({
  name: 'scheduleCalendar',
  initialState,
  reducers: {
    initialize: (state) => {
      state.modalType = '';
      state.currentView = 'month';
      state.currentDate = moment().toDate();
      state.currentMonth = moment().month();
      state.events = [];
      state.schedule = null;
      state.selectedPhotographers = [];
      state.addedAvailabilityStatus = null;
      state.removedAvailabilityStatus = null;
      state.availabilityToBeDeleted = null;
      state.unavailabilityToBeDeleted = null;
      state.shootsToDisplay = [];
      state.addedUnavailabilityStatus = null;
      state.removedUnavailabilityStatus = null;
    },
    setModalType: (state, action) => {
      state.modalType = action.payload;
    },
    setCurrentView: (state, action) => {
      state.currentView = action.payload;
    },
    setCurrentDate: (state, action) => {
      state.currentDate = action.payload;
    },
    setCurrentMonth: (state, action) => {
      state.currentMonth = action.payload;
    },
    setEvents: (state, action) => {
      state.events = action.payload;
    },
    setSchedule: (state, action) => {
      state.schedule = action.payload;
    },
    setSelectedPhotographers: (state, action) => {
      state.selectedPhotographers = action.payload;
    },
    setAddedAvailabilityStatus: (state, action) => {
      state.addedAvailabilityStatus = action.payload;
    },
    setAvailabilityToBeDeleted: (state, action) => {
      state.availabilityToBeDeleted = action.payload;
    },
    setUnavailabilityToBeDeleted: (state, action) => {
      state.unavailabilityToBeDeleted = action.payload;
    },
    setShootsToDisplay: (state, action) => {
      state.shootsToDisplay = action.payload;
    },
    addedAvailabilitiesToSchedule: (state, action) => {
      state.schedule = {
        ...state.schedule,
        availabilities: [...state.schedule.availabilities, ...action.payload],
      };
    },
    setRemovedAvailabilityStatus: (state, action) => {
      state.removedAvailabilityStatus = action.payload;
    },
    removeAvailabilityFromSchedule: (state, action) => {
      state.schedule = {
        ...state.schedule,
        availabilities: state.schedule.availabilities.filter((availability: any) => {
          return availability.id !== action.payload.availability_id;
        }),
      };
    },
    setAddedUnavailabilityStatus: (state, action) => {
      state.addedUnavailabilityStatus = action.payload;
    },
    setRemovedUnavailabilityStatus: (state, action) => {
      state.removedUnavailabilityStatus = action.payload;
    },
    removeUnavailabilityFromSchedule: (state, action) => {
      state.schedule.unAvailabilities = state.schedule.unAvailabilities.filter(
        (unavailability: any) => {
          return unavailability.id !== action.payload;
        },
      );
    },
    addUnavailabilityToSchedule: (state, action) => {
      state.schedule = {
        ...state.schedule,
        unAvailabilities: [...state.schedule.unAvailabilities, action.payload],
      };
    },
    removeNewUnavailabilityFromEvents: (state, action) => {
      state.events = state.events.filter((event: any) => {
        return (
          event.start !== action.payload.start &&
          event.end !== action.payload.end &&
          event.type !== action.payload.type
        );
      });
    },
  },
});

// Actions
export const {
  initialize,
  setModalType,
  setCurrentView,
  setCurrentDate,
  setCurrentMonth,
  setEvents,
  setSchedule,
  setSelectedPhotographers,
  setAddedAvailabilityStatus,
  setAvailabilityToBeDeleted,
  setUnavailabilityToBeDeleted,
  setShootsToDisplay,
  addedAvailabilitiesToSchedule,
  removeAvailabilityFromSchedule,
  setRemovedAvailabilityStatus,
  setAddedUnavailabilityStatus,
  setRemovedUnavailabilityStatus,
  removeUnavailabilityFromSchedule,
  addUnavailabilityToSchedule,
  removeNewUnavailabilityFromEvents,
} = scheduleCalendarSlice.actions;

export const fetchUsersCalendarAsync = (token: string, startDate: string, endDate: string) => {
  return async (dispatch: Dispatch) => {
    const data = await fetchUsersCalendar(token, startDate, endDate);
    if (data.status === 200) {
      dispatch(setSchedule(data));
    }
  };
};

export const fetchUserScheduleForTimeperiodAsync = (
  token: string,
  userId: number,
  startDate: string,
  endDate: string,
) => {
  return async (dispatch: Dispatch) => {
    const data = await fetchUserCalendar(token, userId, startDate, endDate);
    if (data && data.status === 200) {
      dispatch(setSchedule(data));
    }
  };
};

export const addAvailabilityForTechsAsync = (
  token: string,
  techs: any,
  selectedTechsObj: any,
  dates: any,
) => {
  return async (dispatch: Dispatch) => {
    const res = await addAvailabilityForTechs(token, techs, dates);
    if (res && res.status === 200) {
      dispatch(setAddedAvailabilityStatus(200));
      const { availabilities } = res;
      const newAvailabilities = availabilities.map((availability: any) => {
        return {
          ...availability,
          user: {
            id: availability.user_id,
            firstname: selectedTechsObj.find((tech: any) => {
              return tech.id === availability.user_id;
            }).firstname,
            lastname: selectedTechsObj.find((tech: any) => {
              return tech.id === availability.user_id;
            }).lastname,
          },
        };
      });
      dispatch(addedAvailabilitiesToSchedule(newAvailabilities));
    } else {
      dispatch(setAddedAvailabilityStatus(500));
    }
    dispatch(setAddedAvailabilityStatus(null));
  };
};

export const removeAvailabilityForTechsAsync = (
  token: string,
  tech_id: number,
  startTime: string,
  availability_id: number,
) => {
  return async (dispatch: Dispatch) => {
    const res = await removeAvailabilityForTechs(token, tech_id, startTime);
    if (res && res === 200) {
      dispatch(setRemovedAvailabilityStatus(200));
      dispatch(removeAvailabilityFromSchedule({ tech_id, startTime, availability_id }));
    }
  };
};

export const addUnavailabilityForTechsAsync = (
  token: string,
  tech: any,
  start: string,
  end: string,
  unavailability_id: number,
  reason: string,
) => {
  return async (dispatch: Dispatch) => {
    const res = await createUserUnavailability(
      token,
      tech.id,
      start,
      end,
      unavailability_id,
      reason,
    );
    if (res && res.status === 200) {
      dispatch(setAddedUnavailabilityStatus(200));
      const { result } = res;
      const newUnavailability = {
        ...result,
        user: tech,
      };
      dispatch(addUnavailabilityToSchedule(newUnavailability));
    } else {
      dispatch(setAddedUnavailabilityStatus(500));
      dispatch(removeNewUnavailabilityFromEvents({ start, end, type: 'new-unavailability' }));
    }
    dispatch(setAddedUnavailabilityStatus(null));
  };
};

export const removeUnavailabilityForTechsAsync = (token: string, unavailability_id: number) => {
  return async (dispatch: Dispatch) => {
    const res = await removeUnavailability(token, unavailability_id);
    if (res && res === 200) {
      dispatch(removeUnavailabilityFromSchedule(unavailability_id));
      dispatch(setRemovedUnavailabilityStatus(200));
    }
  };
};
export default scheduleCalendarSlice.reducer;
