import { STATE } from 'config/constants';
import { images } from 'assets/images';

export const getTranslatedTitle = (isEn: boolean, item: any) => {
  if (isEn) {
    return item.display_name;
  }
  return item.display_name_fr;
};
export const getTranslatedShootingPackageTitle = (isEn: boolean, item: any) => {
  if (isEn) {
    return item?.display_name_en;
  }
  return item?.display_name_fr || '';
};
export const getStatusTitle = (state: number, intl: any) => {
  switch (state) {
    case STATE.NOT_STARTED.KEY:
      return intl.formatMessage({ id: `postProd.${STATE.NOT_STARTED.NAME}` });
    case STATE.ORDERED.KEY:
      return intl.formatMessage({ id: `postProd.${STATE.ORDERED.NAME}` });
    case STATE.ONGOING.KEY:
      return intl.formatMessage({ id: `postProd.${STATE.ONGOING.NAME}` });
    case STATE.COMPLETED.KEY:
      return intl.formatMessage({ id: `postProd.${STATE.COMPLETED.NAME}` });
    case STATE.DELIVERED.KEY:
      return intl.formatMessage({ id: `postProd.${STATE.DELIVERED.NAME}` });
    case STATE.CANCELLED.KEY:
      return intl.formatMessage({ id: `postProd.${STATE.CANCELLED.NAME}` });
    default:
      return intl.formatMessage({ id: `postProd.${STATE.NOT_STARTED.NAME}` });
  }
};
export const getStatusIcon = (state: number) => {
  switch (state) {
    case STATE.NOT_STARTED.KEY:
      return images.not_started;
    case STATE.ORDERED.KEY:
      return images.ongoing;
    case STATE.ONGOING.KEY:
      return images.ongoing;
    case STATE.COMPLETED.KEY:
      return images.completed;
    case STATE.DELIVERED.KEY:
      return images.delivered;
    case STATE.CANCELLED.KEY:
      return images.cancelled;
    default:
      return images.not_started;
  }
};
