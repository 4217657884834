import { Listing, Shooting } from 'models';
import { getViewerURL, SERVICE_OFFERS_PUBLIC_PAGE } from 'config/constants';

class BrokerFavoriteListing {
  id: number;

  listing_id: number;

  broker_id: number;

  order_index: number;

  listing: Listing;

  shootings: Shooting[];

  url_params: string;

  constructor(data: object = {}) {
    Object.assign(this, data);

    if (this.listing) {
      this.listing = new Listing(this.listing);
    }
  }

  get address() {
    let address = this.listing?.address || '';
    if (this.listing && this.listing.app) {
      address = address.concat(` #${this.listing.app}`);
    }
    if (address) {
      address += ', ';
    }
    const cityWithPostalCode = this.listing?.cityWithPostalCode || '';
    const full_address = `${address} ${cityWithPostalCode}`;
    return full_address;
  }

  get addressAndCityOnly() {
    return `${this.listing?.cityAddress || ''}`;
  }

  get pictures() {
    return this.listing.pictures;
  }

  get thumbnail() {
    return this.listing.picture_url;
  }

  get service_offers() {
    const serviceOffers = [];
    const added_service_offers = new Set();

    const isServiceOfferAlreadyAdded = (serviceOffer: any) => {
      return added_service_offers.has(serviceOffer.category);
    };

    this.shootings.forEach((shooting) => {
      shooting.service_offers.forEach((service_offer) => {
        if (!isServiceOfferAlreadyAdded(service_offer.serviceOffer)) {
          serviceOffers.push(service_offer.serviceOffer);
          added_service_offers.add(service_offer.serviceOffer.category);
        }
      });
    });

    if (serviceOffers.length <= 3) {
      if (this.url_params !== null) {
        serviceOffers.push(
          { id: SERVICE_OFFERS_PUBLIC_PAGE.shooting_package[0] },
          { id: SERVICE_OFFERS_PUBLIC_PAGE.virtual_tour[0] },
        );
      } else {
        serviceOffers.push({ id: SERVICE_OFFERS_PUBLIC_PAGE.shooting_package[0] });
      }
    } else if (serviceOffers.length === 4) {
      serviceOffers.push({ id: SERVICE_OFFERS_PUBLIC_PAGE.shooting_package[0] });
    }

    return serviceOffers;
  }

  get distribution_token() {
    return this.listing.distribution_token;
  }

  get virtualVisitURL() {
    return `${getViewerURL()}/visit/${this.url_params}`;
  }
}

export default BrokerFavoriteListing;
