import React from 'react';
import { useHistory } from 'react-router';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { useDispatch } from 'react-redux';
import { logout } from 'apis';
import { useAuth, useInitState } from 'hooks';
import { HvMenu } from 'components';
import { showModal } from 'state/actions';
import { MODAL } from 'config/constants';

const useStyles = makeStyles({
  profile: {
    minWidth: 'auto',
  },
  menuItem: {
    fontSize: '14px',
  },
});

interface IMenuItem {
  name: string;
  isShow: boolean;
  action: Function;
}

function ProfileMenu() {
  const intl = useIntl();
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { token } = useAuth();
  const { initState } = useInitState();

  const onLogout = async () => {
    try {
      await logout(token);

      initState();
      history.push('/login');
    } catch {
      console.log('error');
    }
  };

  const onLogin = async () => {
    initState();
    history.push('/login');
  };

  const goToProfile = () => {
    history.push('/profile');
  };

  const fmtLogout = intl.formatMessage({ id: 'logout' });
  const fmtProfile = intl.formatMessage({ id: 'profile' });
  const fmtLogin = intl.formatMessage({ id: 'login' });

  const isSamePath = (pathName: string) => {
    return history.location.pathname === pathName;
  };

  const menuItems: IMenuItem[] = [
    {
      name: fmtLogin,
      isShow: token === null,
      action: onLogin,
    },
    {
      name: fmtProfile,
      isShow: !isSamePath('/profile'),
      action: goToProfile,
    },
    {
      name: fmtLogout,
      isShow: token !== null,
      action: onLogout,
    },
  ];

  return (
    <HvMenu className={styles.profile} buttonContent={<PersonIcon />}>
      {menuItems.map((item: IMenuItem, index: number) => {
        if (item.isShow) {
          return (
            <MenuItem
              key={index.toString()}
              className={styles.menuItem}
              onClick={() => {
                return item.action();
              }}
            >
              {item.name}
            </MenuItem>
          );
        }
        return null;
      })}
    </HvMenu>
  );
}

export default ProfileMenu;
