import React, { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useIntl } from 'react-intl';
import { HvButton } from 'components';

const useStyles = makeStyles({
  container: {
    '&>.MuiDialog-paper': {
      padding: '20px',
    },
  },
});

interface Props {
  when: boolean;
  title: string;
  message: string;
}

let condition = false;

const HvRouterPrompt = ({ when, title, message }: Props) => {
  const intl = useIntl();
  const history = useHistory();
  const styles = useStyles();
  condition = when;
  const [openDlg, setOpenDlg] = useState(false);
  const [location, setLocation] = useState('');

  const show = (allowTransitionCallback) => {
    setOpenDlg(true);
    allowTransitionCallback(false);
  };

  const onCancel = () => {
    condition = false;
    history.push(location);
    setOpenDlg(false);
  };

  const onSave = () => {
    setOpenDlg(false);
  };

  const handleTransition = (location) => {
    setLocation(location.pathname);
    if (condition) {
      return Symbol.keyFor(HvRouterPrompt.__trigger);
    }
    return true;
  };

  useEffect(() => {
    window[HvRouterPrompt.__trigger] = show;
    return () => {
      delete window[HvRouterPrompt.__trigger];
    };
  }, []);

  return (
    <>
      <Prompt when={condition} message={handleTransition} />
      <Dialog open={openDlg} onClose={onSave} className={styles.container}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onSave} color='primary'>
            {intl.formatMessage({ id: 'shooting.create.buttons.cancel' })}
          </Button>
          <HvButton onClick={onCancel}>
            {intl.formatMessage({ id: 'shooting.create.confirm.unsavedDataGoBack' })}
          </HvButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

HvRouterPrompt.__trigger = Symbol.for(`__PreventTransitionPrompt_${_.uniqueId()}`);

export default HvRouterPrompt;
