import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { IMenuItem, IMenu } from 'types';
import { HvMenu } from 'components';

const useStyles = makeStyles({
  menuItem: {
    textTransform: 'capitalize',
  },
});

interface Props {
  className: string;
  menuItems: IMenu[];
}

function ComplexMenu({ className, menuItems }: Props) {
  const styles = useStyles();

  return (
    <Box className={className}>
      {menuItems.map((subMenu: IMenu) => {
        return (
          <HvMenu key={subMenu.text} className={styles.menuItem} buttonContent={subMenu.text}>
            {subMenu.links.map((item: IMenuItem) => {
              return (
                <Link key={item.link} to={item.link}>
                  {item.text}
                </Link>
              );
            })}
          </HvMenu>
        );
      })}
    </Box>
  );
}

ComplexMenu.defaultProps = {
  className: null,
};

ComplexMenu.propTypes = {
  className: PropTypes.string,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          link: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
};

export default ComplexMenu;
