import axios from 'axios';

import { Listing, VirtualServiceOrder, FloorplanOrder, Picture } from 'models';
import { getAPIURL } from 'config/constants';
import { tokenToString } from 'typescript';

export const fetchListings = async (
  token: string,
  page: number = 1,
  searchInput: string = '',
): Promise<any> => {
  try {
    const params = [
      `page=${page - 1}`,
      'withShootingData=true',
      'includeNonVRListings=true',
      'includeListingsWithIncompleteShootings=true',
    ];
    if (searchInput) {
      params.push(`searchInput=${searchInput}`);
    }
    const res = await axios.get(`${getAPIURL()}/allListingsForBrokers?${params.join('&')}`, {
      headers: { token },
    });
    const listings = res.data.listing.map((obj: object) => {
      return new Listing(obj);
    });
    return { listings, pageInfo: res.data.pageInfo };
  } catch (err) {
    console.log('[fetchListings] error: ', err);
  }
  return {};
};

export const fetchListing = async (distToken: string, public_usage: boolean = false) => {
  try {
    const res = await axios.get(
      `${getAPIURL()}/getListingWithDeliveriesFromToken?distribution_token=${distToken}&public_usage=${public_usage}`,
    );
    const listing = new Listing(res.data.listing);
    listing.distribution_token = distToken;
    return listing;
  } catch (err) {
    console.log('[fetchlisting] error: ', err);
  }
  return null;
};

export const fetchListingStats = async (token: string, listing_id: number) => {
  try {
    const config = { headers: { token } };
    const params = `listing_id=${listing_id}`;
    const res = await axios.get(`${getAPIURL()}/getListingStats?${params}`, config);
    return res.data;
  } catch (err) {
    console.log('[fetchListingStats] error: ', err);
  }
  return null;
};

export const fetchVirtualTourStats = async (
  token: string,
  listing_id: number,
  from: string,
  to: string,
) => {
  try {
    const config = { headers: { token } };
    const params = `listing_id=${listing_id}&from=${from}&to=${to}`;
    const res = await axios.get(
      `${getAPIURL()}/stats/allListingVirtualVisitStats?${params}`,
      config,
    );
    const { daily_stats, stats } = res.data;
    return { daily_stats, stats, recurring_visits: stats.visitorsVisites };
  } catch (err) {
    console.log('[fetchVirtualTourStats] error: ', err);
  }
  return null;
};
export const postPhotoComment = async (
  token: string,
  picture_id: number,
  comment: string,
  locationX: number,
  locationY: number,
  width: number,
  height: number,
  shape: string,
) => {
  try {
    const config = { headers: { token } };
    const params = { picture_id, description: comment, locationX, locationY, width, height, shape };
    const res = await axios.post(`${getAPIURL()}/ticket`, params, config);

    return res.data;
  } catch (err) {
    console.log('[postTicket] error: ', err);
  }
  return null;
};
export const deletePhotoComment = async (token: string, ticket_id: number) => {
  try {
    const config = { headers: { token } };
    const params = { ticket_id };
    const res = await axios.post(`${getAPIURL()}/deleteTicket`, params, config);
    return res.data;
  } catch (err) {
    console.log('[deleteTicket] error: ', err);
  }
  return null;
};
export const postGeneralComment = async (token: string, comment: string, listing_id: number) => {
  try {
    const config = { headers: { token } };
    const params = { feedback: comment, listing_id };
    const response = await axios.post(`${getAPIURL()}/setGeneralFeedback`, params, config);
    return response;
  } catch (err) {
    console.log('[setGeneralFeedback] error: ', err);
  }
  return null;
};
export const addVirtualServiceOrder = async (
  token: string,
  virtualServiceOrder: VirtualServiceOrder,
  broker_id: string,
  actor: string,
  action: string,
) => {
  try {
    const config = { headers: { token } };
    const params = { virtualServiceOrder, actor, action };
    const res = await axios.post(
      `${getAPIURL()}/addVirtualServiceOrder?broker_id=${broker_id}&listing_id=${
        virtualServiceOrder.listing_id
      }`,
      params,
      config,
    );
    return res;
  } catch (err) {
    console.log('[addVirtualServiceOrder] error: ', err);
  }
  return null;
};
export const getVirtualServiceOrders = async (
  token: string,
  listing_id: number,
  broker_id: string,
) => {
  try {
    const config = { headers: { token } };
    const res = await axios.get(
      `${getAPIURL()}/getVirtualServiceOrders?listing_id=${listing_id}&broker_id=${broker_id}`,
      config,
    );
    return res.data;
  } catch (err) {
    console.log('[getVirtualServiceOrders] error: ', err);
  }
  return null;
};
export const addFloorplanOrder = async (
  token: string,
  floorplanOrder: FloorplanOrder,
  broker_id: string,
  actor: string,
  action: string,
) => {
  try {
    const config = { headers: { token } };
    const params = { floorplanOrder, actor, action };
    const res = await axios.post(
      `${getAPIURL()}/addFloorplanOrder?broker_id=${broker_id}&listing_id=${
        floorplanOrder.listing_id
      }`,
      params,
      config,
    );
    return res;
  } catch (err) {
    console.log('[addFloorplanOrder] error: ', err);
  }
  return null;
};
export const getFloorplanOrders = async (token: string, listing_id: number, broker_id: string) => {
  try {
    const config = { headers: { token } };
    const res = await axios.get(
      `${getAPIURL()}/getFloorplanOrders?listing_id=${listing_id}&broker_id=${broker_id}`,
      config,
    );
    return res.data;
  } catch (err) {
    console.log('[getFloorplanOrders] error: ', err);
  }
  return null;
};
export const setMLS = async (token: string, listing_id: number, mls: string) => {
  try {
    const config = { headers: { token } };
    const params = { listing_id, mls };
    const res = await axios.post(`${getAPIURL()}/setMLS`, params, config);
    return res;
  } catch (err) {
    console.log('[setMLS] error: ', err);
  }
  return null;
};

export const generateInvoiceForExtraService = async (
  token: string,
  listing: Listing,
  selectedPictures: Array<any>,
  service: string,
) => {
  try {
    const config = { headers: { token } };
    const params = { listing, pictures: selectedPictures, service };
    const res = await axios.post(`${getAPIURL()}/generateInvoiceForExtraService`, params, config);
    return res;
  } catch (err) {
    console.log('[generateInvoiceForExtraService ] error: ', err);
  }
  return null;
};

export const brokerPurchasedPictures = async (
  token: string,
  invoice_id: Listing,
  pictureIds: Array<number>,
  uneditedPictures?: boolean,
) => {
  try {
    const config = { headers: { token } };
    const params = { zoho_invoice_id: invoice_id, picture_ids: pictureIds, uneditedPictures };
    const res = await axios.post(`${getAPIURL()}/brokerPurchasedPictures`, params, config);
    return res;
  } catch (err) {
    console.log('[brokerPurchasedPictures ] error: ', err);
  }
  return null;
};

export const brokerSwapPictures = async (
  token: string,
  picturesToExchangeWith: Array<number>,
  picturesToExchange: Array<number>,
) => {
  try {
    const config = { headers: { token } };
    const params = {
      pictures_to_exchange_with_ids: picturesToExchangeWith,
      pictures_to_exchange_ids: picturesToExchange,
    };
    const res = await axios.post(`${getAPIURL()}/pictureExchange`, params, config);
    return res;
  } catch (err) {
    console.log('[pictureExchange] error: ', err);
  }
  return null;
};

export async function getListingRawPictures(
  shooting_id: number,
  listing_id: number,
  token: string,
) {
  try {
    const config = { headers: { token } };
    const res = await axios.get(
      `${getAPIURL()}/getListingRawPictures?shooting_id=${shooting_id}&listing_id=${listing_id}`,
      config,
    );
    const { pictures } = res.data;
    if (pictures && pictures.length > 0) {
      const rawPictures = pictures.map((picture: Picture) => {
        return new Picture(picture);
      });
      return rawPictures;
    }
  } catch (err) {
    console.log('[getListingRawPictures] error: ', err);
  }
  return null;
}

export async function getListingForReview(listing_id: number, token: string) {
  try {
    const config = { headers: { token } };
    const response = await axios.get(
      `${getAPIURL()}/getListingForReview?listing_id=${listing_id}`,
      config,
    );
    const { listing } = response.data;
    if (listing) {
      return new Listing(listing);
    }
  } catch (err) {
    console.log('[getListingForReview] error: ', err);
  }
  return null;
}

export async function fetchDescriptionGenerator(
  token: string,
  broker_id: number,
  listing_id: number,
  prompt: any,
  textLength: string,
  language: string,
) {
  try {
    const config = { headers: { token } };
    const params = { prompt, broker_id, listing_id, textLength, language };
    const response = await axios.post(`${getAPIURL()}/generateListingDescription`, params, config);
    return response;
  } catch (err) {
    console.log('[generateListingDescription] error: ', err);
  }
  return null;
}

export async function saveEditedDescription(
  token: string,
  gpt_usage_id: number,
  edited_description: string,
) {
  try {
    const config = { headers: { token } };
    const params = { gpt_usage_id, edited_description };
    const response = await axios.post(`${getAPIURL()}/saveEditedDescription`, params, config);
    return response;
  } catch (err) {
    console.log('[saveEditedDescription] error: ', err);
  }
  return null;
}
export async function saveListingTags(token: string, listing_id: number, prompt: any) {
  try {
    const config = { headers: { token } };
    const params = { listing_id, prompt };
    const response = await axios.post(`${getAPIURL()}/saveListingGPTTags`, params, config);
    return response.data;
  } catch (err) {
    console.log('[saveListingGPTTags] error: ', err);
  }
  return null;
}
export async function saveGeneratedDescriptionFeedback(
  token: string,
  gpt_usage_id: number,
  feedback: number,
) {
  try {
    const config = { headers: { token } };
    const params = { gpt_usage_id, feedback };
    const response = await axios.post(
      `${getAPIURL()}/saveGeneratedDescriptionFeedback`,
      params,
      config,
    );
    return response;
  } catch (err) {
    console.log('[saveGeneratedDescriptionFeedback] error: ', err);
  }
  return null;
}

export async function fetchPreviousDescriptions(
  token: string,
  broker_id: number,
  listing_id: number,
) {
  try {
    const config = { headers: { token } };
    const response = await axios.get(
      `${getAPIURL()}/getPreviousDescriptions?broker_id=${broker_id}&listing_id=${listing_id}`,
      config,
    );
    const { data } = response;
    if (data) {
      return data;
    }
  } catch (err) {
    console.log('[getPreviousDescriptions] error: ', err);
  }
  return null;
}

export async function fetchGPTPromptTags(
  token: string,
  listing_id: number,
  lat: number,
  lng: number,
  language: string,
) {
  try {
    const config = { headers: { token } };
    const response = await axios.get(
      `${getAPIURL()}/getListingGPTPromptTags?listing_id=${listing_id}&lat=${lat}&lng=${lng}&language=${language}`,
      config,
    );
    return response.data;
  } catch (err) {
    console.log('[getListingGPTPromptTags] error: ', err);
  }
  return null;
}

export async function fetchMediaNotificationLogs(
  token: string,
  listing_id: number,
  broker_id: number,
) {
  try {
    const config = { headers: { token } };
    const response = await axios.get(
      `${getAPIURL()}/getMediaNotificationLogs?listing_id=${listing_id}&broker_id=${broker_id}`,
      config,
    );
    return response.data;
  } catch (err) {
    console.log('[getMediaNotificationLogs] error: ', err);
  }
  return null;
}

export async function sendMediaEmailToClient(
  token: string,
  client_firstname: string,
  client_lastname: string,
  email: string,
  client_lang: string,
  listing_id: number,
  broker_id: number,
  public_delivery_url: string,
) {
  try {
    const config = { headers: { token } };
    const params = {
      client_firstname,
      client_lastname,
      email,
      client_lang,
      listing_id,
      broker_id,
      public_delivery_url,
    };
    const response = await axios.post(`${getAPIURL()}/sendPublicMediaEmail`, params, config);
    return response;
  } catch (err) {
    console.log('[sendMediaEmailToClient] error: ', err);
  }
  return null;
}

export async function sendMediaSMSToClient(
  token: string,
  client_firstname: string,
  client_lastname: string,
  phone_number: string,
  client_lang: string,
  listing_id: number,
  broker_id: number,
  public_delivery_url: string,
) {
  try {
    const config = { headers: { token } };
    const params = {
      client_firstname,
      client_lastname,
      phone_number,
      client_lang,
      listing_id,
      broker_id,
      public_delivery_url,
    };
    const response = await axios.post(`${getAPIURL()}/sendPublicMediaSMS`, params, config);
    return response;
  } catch (err) {
    console.log('[sendMediaSMSToClient] error: ', err);
  }
  return null;
}

export const getListingVideoTemplates = async (token: string) => {
  try {
    const config = { headers: { token } };
    const res = await axios.get(`${getAPIURL()}/getListingVideoTemplates`, config);
    return res.data;
  } catch (err) {
    console.log('[getListingVideoTemplates] error: ', err);
  }
  return null;
};

export const orderListingVideo = async (
  token: string,
  listing_id: number,
  video_template_id: number,
  pictures: Picture[],
) => {
  try {
    const config = { headers: { token } };
    const params = { listing_id, video_template_id, pictures };
    const response = await axios.post(`${getAPIURL()}/orderListingVideo`, params, config);
    return response;
  } catch (err) {
    console.log('[orderListingVideo] error: ', err);
  }
  return null;
};

export async function createAiStagingOrder(
  listing_id: number,
  orderData: any,
  service_offer_id: number,
  token: string,
) {
  try {
    const config = { headers: { token } };
    const params = { listing_id, orderData, service_offer_id };
    const response = await axios.post(`${getAPIURL()}/aiStagingOrder`, params, config);
    return response;
  } catch (err) {
    console.log('[createAiStagingOrder] error: ', err);
  }
  return null;
}

export async function createAiStagingOrderIteration(
  listing_id: number,
  render_id: string,
  token: string,
) {
  try {
    const config = { headers: { token } };
    const params = { listing_id, render_id };
    const response = await axios.post(`${getAPIURL()}/aiStagingOrder`, params, config);
    return response;
  } catch (err) {
    console.log('[createAiStagingOrderIteration] error: ', err.response.data);
    return err.response.data;
  }
}
