import classNames from 'classnames';

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { Button, Drawer } from '@material-ui/core';
import { useAuth, useMenuItems } from 'hooks';
import { COLOR } from 'config/constants';
import { BaseHeader, SimpleMenu } from 'pages/shared';
import CompactMenu from './CompactMenu';
import 'styles/animations.scss';

const useStyles = makeStyles({
  menuBox: {
    height: '100%',
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',

    '&.mobile': {
      flexDirection: 'column',
      justifyContent: 'flex-start',

      '& .MuiAccordion-root.Mui-expanded': {
        margin: 0,
      },

      '&.SimpleMenu a': {
        color: 'white',
        lineHeight: '48px',
        textDecoration: 'none',
        padding: '0 16px',
        boxSizing: 'border-box',
      },
    },
  },
  menuButton: {
    display: 'none',

    '@media (max-width: 767px)': {
      display: 'block',
    },

    '& .MuiButton-root': {
      minWidth: 'auto',
    },
  },
  paper: {
    bottom: 0,
    display: 'none',
    paddingTop: 30,
    width: 350,
    color: 'white',
    background: COLOR.ULTRA_DARK_BLUE,
    '@media (max-width: 767px)': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
});

function Header() {
  const styles = useStyles();

  const { user } = useAuth();
  const menuItems = useMenuItems(user);

  const [showMenu, setShowMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const brokerMenu = menuItems.length ? (
    <SimpleMenu className={styles.menuBox} menuItems={menuItems} />
  ) : null;

  const compactMenu = <CompactMenu menuItems={menuItems} />;

  const mobileMenu = menuItems.length ? (
    <div className={styles.menuButton}>
      <Button
        onClick={() => {
          return setShowMenu(true);
        }}
      >
        <MenuIcon style={{ color: 'black' }} />
      </Button>
      <Drawer
        classes={{ paper: styles.paper }}
        anchor='right'
        open={showMenu}
        onClose={() => {
          return setShowMenu(false);
        }}
      >
        <SimpleMenu
          isMobile
          className={classNames(styles.menuBox, 'mobile')}
          menuItems={menuItems}
        />
      </Drawer>
    </div>
  ) : null;

  if (isMobile) {
    return <BaseHeader rightAfter={mobileMenu} showLanguageMenu />;
  }

  if (user && user.isBroker) {
    return <BaseHeader center={brokerMenu} showLanguageMenu />;
  }

  return <BaseHeader center={compactMenu} showLanguageMenu />;
}

export default Header;
