import _ from 'lodash';
import classNames from 'classnames';

import React from 'react';
import PropTypes from 'prop-types';
import { Paper, InputBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { HvButton } from '.';

const useStyles = makeStyles({
  emailInput: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    borderRadius: '8px',
  },
  input: {
    flex: 1,
    padding: '2px 4px',
    margin: 'auto 10px',
  },
});

interface Props {
  className: string;
  value: string;
  placeholder: string;
  onChange: any;
  onClick: any;
}

function HvEmailInput(props: Props) {
  const styles = useStyles();

  const { className, placeholder, value, onChange, onClick } = props;

  return (
    <Paper className={classNames('HvEmailInput', className, styles.emailInput)}>
      <InputBase
        className={styles.input}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />

      <HvButton onClick={onClick}>
        <ArrowForwardIcon />
      </HvButton>
    </Paper>
  );
}

HvEmailInput.defaultProps = {
  className: null,
  value: '',
  placeholder: 'Enter your email *',
  onChange: _.noop,
  onClick: _.noop,
};

HvEmailInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

export default HvEmailInput;
