import testimonial_block from './testimonial_block.png';
import testimonial_list from './testimonial_list.png';
import testimonial_simple from './testimonial_simple.png';
import about_simple from './about_simple.png';
import about_block from './about_block.png';
import about_list from './about_list.png';

export default {
  testimonial_block,
  testimonial_list,
  testimonial_simple,
  about_simple,
  about_block,
  about_list,
};
