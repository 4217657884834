import _ from 'lodash';
import React, { useState } from 'react';
import classNames from 'classnames';
import { useBlueBody } from 'hooks';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box } from '@material-ui/core';
import { ThankYou } from 'pages/shared';
import { COLOR } from 'config/constants';
import { images } from 'assets/images';

const useStyles = makeStyles({
  root: {
    flexDirection: 'column',
    backgroundImage: `url(${images.maskGroup1})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left bottom',
    backgroundSize: '50% auto',
    padding: '50px 0 50px 0',
    color: '#FFF',
  },
  dataContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: 800,
    borderRadius: 8,
    padding: 20,
    backgroundColor: COLOR.WHITE,
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
});

interface Props {
  className: string;
}

function Response({ className }: Props) {
  const styles = useStyles();
  useBlueBody();
  return (
    <Box className={classNames('Page', styles.root)}>
      <Container className={styles.dataContainer}>
        <Box className={styles.logoContainer}>
          <img style={{ width: 200 }} src={images.logo_horizontal} alt='logo' />
        </Box>
        <ThankYou />
      </Container>
    </Box>
  );
}

Response.defaultProps = {
  className: null,
};

Response.propTypes = {
  className: PropTypes.string,
};

export default Response;
