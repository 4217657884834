import _ from 'lodash';
import classNames from 'classnames';

import React from 'react';
import PropTypes from 'prop-types';
import { Paper, InputBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { HvButton } from '.';

const useStyles = makeStyles({
  copyInput: {
    display: 'flex',
    alignItems: 'center',
    padding: '2px 6px',
    borderRadius: '8px',
  },
  input: {
    flex: 1,
    height: '100%',
    margin: 'auto 10px',
  },
  button: {
    height: '42px',
  },
});

interface Props {
  className: string;
  value: string;
  onClick: any;
}

function HvCopyInput(props: Props) {
  const styles = useStyles();
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'copy' });

  const { className, value, onClick } = props;

  return (
    <Paper className={classNames('HvCopyInput', className, styles.copyInput)}>
      <InputBase className={styles.input} type='text' defaultValue={value} readOnly />
      <HvButton className={styles.button} onClick={onClick}>
        {title}
      </HvButton>
    </Paper>
  );
}

HvCopyInput.defaultProps = {
  className: null,
  value: '',
  onClick: _.noop,
};

HvCopyInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onClick: PropTypes.func,
};

export default HvCopyInput;
