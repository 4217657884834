import React, { useState, useEffect } from 'react';
import PropsTypes from 'prop-types';
import classNames from 'classnames';
import { HvPleaseWait } from 'components';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import $ from 'jquery';

const useStyles = makeStyles({
  iframeContainer: {
    position: 'relative',
    width: '100%',
    minWidth: '601px',
    height: '1800px',
    '@media (max-width: 1024px)': {
      minWidth: '557px',
    },
    '@media (max-width: 768px)': {
      minWidth: '100%',
      height: '1896px',
    },
    '@media (max-width: 601px)': {
      width: '400px',
    },
  },
  iframe: {
    border: 'none',
    width: '100%',
    height: '100%',
  },
});

interface Props {
  className: string;
  title: string;
  src: string;
  uniqueKey: string;
}

function HviFrame({ className, title, src, uniqueKey }: Props) {
  const styles = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(false);
  }, [uniqueKey, src]);

  const onLoad = (e: any) => {
    setIsLoaded(true);
  };
  return (
    <div className={classNames(className, styles.iframeContainer)}>
      <iframe
        className={styles.iframe}
        title={title}
        src={src}
        key={uniqueKey}
        onLoad={onLoad}
        sandbox='allow-scripts allow-same-origin allow-popups allow-top-navigation'
      />
      {!isLoaded && <HvPleaseWait />}
    </div>
  );
}

HviFrame.defaultProps = {
  className: '',
  title: 'iFrame',
  src: '',
  uniqueKey: '1',
};

HviFrame.propTypes = {
  className: PropsTypes.string,
  title: PropsTypes.string,
  src: PropsTypes.string,
  uniqueKey: PropsTypes.string,
};

export default HviFrame;
