import classNames from 'classnames';

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  tabPanel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&.hidden': {
      display: 'none',
    },
  },
});

interface Props {
  className?: string;
  children: any;
  index: number;
  value: any;
}

function HvTabPanel(props: Props) {
  const styles = useStyles();

  const { className, value, index, children } = props;

  const isHidden = value !== index;
  const classes = classNames(className, styles.tabPanel, { hidden: isHidden });

  return <Box className={classes}>{children}</Box>;
}

HvTabPanel.defaultProps = {
  className: '',
};

HvTabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default HvTabPanel;
