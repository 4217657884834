class Profile {
  id: number;

  firstname?: string;

  lastname?: string;

  email?: string;

  picture_url?: string;

  logo_url?: string;

  phone_number?: string;

  office_phone_number?: string;

  title_num?: number;

  custom_title?: string;

  banner_id?: number;

  franchise_id?: number;

  broker_code?: string;

  oaciq_code?: string;

  colors?: Array<string>;

  language?: string;

  unsaved_data?: boolean;

  sync_team_calendar: boolean;

  centris_data_status?: number;

  subscription_id?: number;

  static CENTRIS_DATA_STATUS = {
    UNSENT: {
      KEY: 'UNSENT',
      VALUE: 0,
    },
    VIEWED: {
      KEY: 'VIEWED',
      VALUE: 1,
    },
    SIGNED: {
      KEY: 'SIGNED',
      VALUE: 2,
    },
    DECLINED: {
      KEY: 'DECLINED',
      VALUE: 3,
    },
    COMPLETED: {
      KEY: 'COMPLETED',
      VALUE: 4,
    },
    RECALLED: {
      KEY: 'RECALLED',
      VALUE: 5,
    },
    UNOPENED: {
      KEY: 'UNOPENED',
      VALUE: 6,
    },
    FORWARDED: {
      KEY: 'FORWARDED',
      VALUE: 7,
    },
    UNKNOWN: {
      KEY: 'UNKNOWN',
      VALUE: 8,
    },
  };

  constructor(data: object = {}) {
    Object.assign(this, data);
  }

  get getReadableCentrisTransferStatus(): string {
    switch (this.centris_data_status) {
      case Profile.CENTRIS_DATA_STATUS.UNSENT.VALUE:
        return Profile.CENTRIS_DATA_STATUS.UNSENT.KEY;
      case Profile.CENTRIS_DATA_STATUS.VIEWED.VALUE:
        return Profile.CENTRIS_DATA_STATUS.VIEWED.KEY;
      case Profile.CENTRIS_DATA_STATUS.SIGNED.VALUE:
        return Profile.CENTRIS_DATA_STATUS.SIGNED.KEY;
      case Profile.CENTRIS_DATA_STATUS.DECLINED.VALUE:
        return Profile.CENTRIS_DATA_STATUS.DECLINED.KEY;
      case Profile.CENTRIS_DATA_STATUS.COMPLETED.VALUE:
        return Profile.CENTRIS_DATA_STATUS.COMPLETED.KEY;
      case Profile.CENTRIS_DATA_STATUS.RECALLED.VALUE:
        return Profile.CENTRIS_DATA_STATUS.RECALLED.KEY;
      case Profile.CENTRIS_DATA_STATUS.UNOPENED.VALUE:
        return Profile.CENTRIS_DATA_STATUS.UNOPENED.KEY;
      case Profile.CENTRIS_DATA_STATUS.FORWARDED.VALUE:
        return Profile.CENTRIS_DATA_STATUS.FORWARDED.KEY;
      default:
        return Profile.CENTRIS_DATA_STATUS.UNSENT.KEY;
    }
  }

  get centrisTransferActionNeeded(): boolean {
    switch (this.centris_data_status) {
      case Profile.CENTRIS_DATA_STATUS.UNSENT.VALUE:
      case Profile.CENTRIS_DATA_STATUS.DECLINED.VALUE:
      case Profile.CENTRIS_DATA_STATUS.RECALLED.VALUE:
      case Profile.CENTRIS_DATA_STATUS.FORWARDED.VALUE:
        return true;
      case Profile.CENTRIS_DATA_STATUS.UNOPENED.VALUE:
      case Profile.CENTRIS_DATA_STATUS.VIEWED.VALUE:
      case Profile.CENTRIS_DATA_STATUS.SIGNED.VALUE:
      case Profile.CENTRIS_DATA_STATUS.COMPLETED.VALUE:
        return false;
      default:
        return true;
    }
  }

  get completedCentrisDataTransfer(): boolean {
    return (
      this.centris_data_status === Profile.CENTRIS_DATA_STATUS.SIGNED.VALUE ||
      this.centris_data_status === Profile.CENTRIS_DATA_STATUS.COMPLETED.VALUE
    );
  }
}

export default Profile;
