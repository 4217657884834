import axios from 'axios';
import { getAPIURL } from '../config/constants';

export const fetchUsersCalendar = async (
  token: string,
  startDate: string,
  endDate: string,
): Promise<any> => {
  try {
    const config = { headers: { token } };
    const res = await axios.get(
      `${getAPIURL()}/getAllScheduleForTimeperiod?startDate=${startDate}&endDate=${endDate}`,
      config,
    );
    const { data } = res;
    return data;
  } catch (err) {
    console.log('[getAllScheduleForTimeperiod] error: ', err);
  }
  return [];
};

export const fetchUserCalendar = async (
  token: string,
  userId: number,
  startDate: string,
  endDate: string,
): Promise<any> => {
  try {
    const config = { headers: { token } };
    const res = await axios.get(
      `${getAPIURL()}/getUserScheduleForTimeperiod?userId=${userId}&startDate=${startDate}&endDate=${endDate}`,
      config,
    );
    const { data } = res;
    return data;
  } catch (err) {
    console.log('[getUserScheduleForTimeperiod] error: ', err);
  }
  return [];
};

export const addAvailabilityForTechs = async (
  token: string,
  techs: any,
  dates: any,
): Promise<any> => {
  try {
    const config = { headers: { token } };
    const params = { userIds: techs, dates };
    const res = await axios.post(`${getAPIURL()}/addAvailabilityForTechs`, params, config);
    return res.data;
  } catch (err) {
    console.log('[addAvailabilityForTechs] error: ', err);
  }
  return null;
};

export const removeAvailabilityForTechs = async (
  token: string,
  tech_id: number,
  date: string,
): Promise<any> => {
  try {
    const config = { headers: { token } };
    const params = { user_id: tech_id, time: date };
    const res = await axios.post(`${getAPIURL()}/removeAvailability`, params, config);
    return res.data.status;
  } catch (err) {
    console.log('[removeAvailabilityForTechs] error: ', err);
  }
  return null;
};

export const createUserUnavailability = async (
  token: string,
  techId: number,
  start: string,
  end: string,
  unavailability_id: number,
  summary: string,
): Promise<any> => {
  try {
    const config = { headers: { token } };
    const params = {
      user_id: techId,
      start_time: start,
      end_time: end,
      unavailability_id,
      summary,
    };
    const res = await axios.post(`${getAPIURL()}/createUserUnavailability`, params, config);
    return res.data;
  } catch (err) {
    console.log('[createUserUnavailability] error: ', err);
  }
  return null;
};

export const removeUnavailability = async (token, unavailability_id: number): Promise<any> => {
  try {
    const config = { headers: { token } };
    const params = { unavailability_id };
    const res = await axios.post(`${getAPIURL()}/removeUnavailability`, params, config);
    return res.data.status;
  } catch (err) {
    console.log('[removeUnavailability] error: ', err);
  }
  return null;
};
