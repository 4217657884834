import axios from 'axios';

import { reactLocalStorage } from 'reactjs-localstorage';
import { User } from 'models';
import { getAPIURL, KEYS, ENGLISH_LOCALES, ENG, FR } from 'config/constants';

export const login = async (email: string, password: string) => {
  try {
    const data = { email, password };
    const res = await axios.post(`${getAPIURL()}/login`, data);

    const { token } = res.data;
    const user = new User(res.data.user);
    const language = ENGLISH_LOCALES.includes(user.language?.toLowerCase()) ? ENG : FR;

    reactLocalStorage.set(KEYS.TOKEN, token);
    reactLocalStorage.setObject(KEYS.USER, user);
    reactLocalStorage.set(KEYS.LANGUAGE, language);

    return { user, token };
  } catch (err) {
    console.log('[login] error: ', err);
  }
  return null;
};

export const logout = async (token: string) => {
  try {
    await axios.post(
      `${getAPIURL()}/logout`,
      {},
      {
        headers: { token },
      },
    );

    reactLocalStorage.remove(KEYS.TOKEN);
    reactLocalStorage.remove(KEYS.USER);
    reactLocalStorage.remove(KEYS.LANGUAGE);
  } catch (err) {
    console.log('[login] error: ', err);
  }
  return null;
};

export const fetchSubscriptionPlans = async () => {
  try {
    const res = await axios.get(`${getAPIURL()}/subscriptions`);
    return res;
  } catch (error) {
    console.log('[get subscriptions] error: ', error);
  }
  return null;
};

export const fetchUpdatedUser = async (token: string) => {
  try {
    const res = await axios.get(`${getAPIURL()}/getUserInfoFromToken`, {
      headers: { token },
    });
    const user = new User(res.data.user);
    reactLocalStorage.setObject(KEYS.USER, user);
    return user;
  } catch (error) {
    console.log('[get user info from token] error: ', error);
  }
  return null;
};
