import { useSelector, useDispatch } from 'react-redux';
import { fetchFeedbacksAsync } from 'state/actions';
import { LOADING, IDLES } from 'state/constants';
import { useState, useEffect } from 'react';

export const useFeedbacks = (token: string) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(LOADING.NONE);

  const { feedbacks } = useSelector((state: any) => {
    return state.feedback;
  });

  const loadFeedbacks = async () => {
    const isIdle = IDLES.includes(loading);
    if (isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchFeedbacksAsync(token));
      setLoading(LOADING.FINISHED);
    }
  };

  useEffect(() => {
    loadFeedbacks();
  }, [feedbacks]);
  return { loadingFeedbacks: loading, feedbacksData: feedbacks };
};
