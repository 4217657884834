import React, { useEffect, useState } from 'react';
import { Box, makeStyles, Button, LinearProgress, Typography } from '@material-ui/core';
import { uploadFileWithProgress, getUploadLinkForSingleFile } from 'utils';
import { COLOR } from 'config/constants';
import { useIntl } from 'react-intl';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BlockIcon from '@material-ui/icons/Block';

const useStyles = makeStyles({
  uploadBtn: {
    color: 'white',
    marginTop: 10,
    '& p': {
      fontSize: 12,
    },
    backgroundColor: COLOR.BLUE,
    '&:hover': {
      background: COLOR.LIGHT_BLUE,
    },
  },
});

interface Props {
  file: File;
  token: string;
}

function SingleFileUpload({ file, token }: Props) {
  const classes = useStyles();
  const intl = useIntl();
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadDone, setUploadDone] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const fmtUploadSucceed = intl.formatMessage({ id: 'upload.succeed' });
  const fmtUploadFailed = intl.formatMessage({ id: 'upload.failed' });
  const fmtUpload = intl.formatMessage({ id: 'postProdInjector.uploadBtn' });
  const fmtProgress = intl.formatMessage({ id: 'progress' });

  function handleUploadProgress(filename: string, progress: number) {
    setProgress(progress);
  }

  const handleUpload = async () => {
    try {
      const uploadLink = await getUploadLinkForSingleFile(token, file);
      await uploadFileWithProgress(file, uploadLink, handleUploadProgress);
    } catch (error) {
      console.log(error);
      setIsUploading(false);
      setUploadDone(true);
      setUploadError(true);
    }
  };
  useEffect(() => {
    if (progress === 100) {
      setUploadDone(true);
      setIsUploading(false);
    }
  }, [progress]);

  function uploadDoneMessage() {
    if (uploadError) {
      return (
        <>
          <BlockIcon style={{ marginRight: 5, color: COLOR.RED }} />
          <Typography>{fmtUploadFailed}</Typography>
        </>
      );
    }
    return (
      <>
        <CheckCircleIcon style={{ marginRight: 5, color: COLOR.GREEN }} />
        <Typography>{fmtUploadSucceed}</Typography>
      </>
    );
  }

  return (
    <Box>
      {!isUploading && !uploadDone ? (
        <Button
          className={classes.uploadBtn}
          disabled={!file || uploadDone}
          onClick={() => {
            handleUpload();
            setIsUploading(true);
          }}
        >
          {fmtUpload}
        </Button>
      ) : (
        <Box style={{ marginTop: 10 }} width={250}>
          <Typography>{fmtProgress}</Typography>
          <LinearProgress variant='determinate' value={progress} />
        </Box>
      )}
      {uploadDone && <Box style={{ display: 'flex', marginTop: 10 }}>{uploadDoneMessage()}</Box>}
    </Box>
  );
}

export default SingleFileUpload;
