import React from 'react';
import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { HEADER_BASE_URL } from 'config/constants';
import { images } from 'assets/images';

const useStyles = makeStyles({
  logo: {
    height: 28,
    display: 'inline',

    '@media (max-width: 499px)': {
      height: 20,
    },
  },
});

function Logo() {
  const styles = useStyles();

  return (
    <Link href={HEADER_BASE_URL}>
      <img src={images.logoTitle} className={styles.logo} alt='Logo' />
    </Link>
  );
}

export default Logo;
