import classNames from 'classnames';

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  downloadingFile: {
    maxWidth: 300,
    borderRadius: '4px',
    overflow: 'hidden',
  },
  paragraph: {
    lineHeight: '25px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

interface Props {
  className: string;
  title: string;
  progress: string;
}

function DownloadingFile({ className, title, progress }: Props) {
  const styles = useStyles();

  return (
    <Card className={classNames('downloadingFile', className, styles.downloadingFile)}>
      <CardContent>
        <Typography className={styles.paragraph} title={title}>
          <b>{title}</b>
        </Typography>
        <Typography className={styles.paragraph}>{progress}</Typography>
      </CardContent>
    </Card>
  );
}

DownloadingFile.defaultProps = {
  className: null,
  title: '',
  progress: '',
};

DownloadingFile.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  progress: PropTypes.string,
};

export default DownloadingFile;
