import { ENG } from 'config/constants';

class ShootingPackage {
  id: number;

  display_name_en: string;

  display_name_fr: string;

  excluded_items_en: string;

  excluded_items_fr: string;

  gf_clause: boolean;

  hdr_photo_count: number;

  included_service_offers: number[] = [];

  is_visible_to_brokers: boolean;

  items: string;

  items_fr: string;

  key_name: string;

  order_index: number;

  price: string;

  recommendation_max: number;

  recommendation_min: number;

  subscription_id: number;

  zoho_item_id: string;

  freelance_post_commission: number;

  freelancer_prod_commission: number;

  is_drone_photo: boolean;

  drone_photo_count: number;

  package_items: any;

  related_shooting_package: any;

  constructor(data: object = {}) {
    Object.assign(this, data);
  }

  getDisplayName(language: string): String {
    const { display_name_en, display_name_fr } = this;
    if (language === ENG) {
      return display_name_en;
    }
    return display_name_fr;
  }
}

export default ShootingPackage;
