class ZohoUser {
  id: number;

  hausvalet_id: number;

  crm_id: number;

  desk_id: number;

  books_id: number;

  crm_account_id: number;

  books_vendor_id: number;

  constructor(data: object = {}) {
    Object.assign(this, data);
  }
}

export default ZohoUser;
