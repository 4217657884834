import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import appReducer from './slices/app';
import usersReducer from './slices/users';
import listingsReducer from './slices/listings';
import shootingsReducer from './slices/shootings';
import profileReducer from './slices/profile';
import faqReducer from './slices/faq';
import feedbackReducer from './slices/feedback';
import personalPortalReducer from './slices/publicPersonalPortal';
import bullJobsReducer from './slices/bullJobs';
import listingReviewReducer from './slices/listingReview';
import aiServicesReducer from './slices/aiServices';
import scheduleCalendarReducer from './slices/scheduleCalender';

const store = configureStore({
  reducer: {
    app: appReducer,
    users: usersReducer,
    listings: listingsReducer,
    shootings: shootingsReducer,
    profile: profileReducer,
    faq: faqReducer,
    feedback: feedbackReducer,
    personalPortal: personalPortalReducer,
    bullJobs: bullJobsReducer,
    listingReview: listingReviewReducer,
    aiServices: aiServicesReducer,
    scheduleCalendar: scheduleCalendarReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export default store;
