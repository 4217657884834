import React, { useState, useEffect } from 'react';
import { Picture } from 'models';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

interface Props {
  pictures: Picture[];
  setImageCarouselOpen: (open: boolean) => void;
  allowDownload?: boolean;
  startingIndex?: number;
  brokerOrPublic?: boolean;
  photoShootingId?: number;
}

function HvImageCarousel(props: Props) {
  const {
    setImageCarouselOpen,
    pictures,
    allowDownload,
    startingIndex,
    brokerOrPublic,
    photoShootingId,
  } = props;
  const [photoIndex, setPhotoIndex] = useState(0);

  function handelRightClick(event: any) {
    event.preventDefault();
  }

  function fetchIndex(pictureIndex: number) {
    const trueArrayIndex = pictures.findIndex((picture) => {
      if (photoShootingId !== 0 && photoShootingId) {
        return picture.picture_index === pictureIndex && picture.shooting_id === photoShootingId;
      }
      return picture.picture_index === pictureIndex;
    });
    return trueArrayIndex;
  }

  function fetchPicture(picturePosition: string, photo_index: number) {
    let picture: Picture = null;
    switch (picturePosition) {
      case 'next':
        picture = pictures[(photo_index + 1) % pictures.length];
        break;
      case 'prev':
        picture = pictures[(photo_index + pictures.length - 1) % pictures.length];
        break;
      default:
        picture = pictures[photo_index];
    }
    return picture.lightboxURL;
  }

  useEffect(() => {
    if (startingIndex >= 0) {
      const index = fetchIndex(startingIndex);
      setPhotoIndex(index);
    }
    if (!allowDownload) {
      document.addEventListener('contextmenu', handelRightClick);
      return () => {
        document.removeEventListener('contextmenu', handelRightClick);
      };
    }
  }, [pictures]);

  return (
    pictures.length !== 0 && (
      <Lightbox
        mainSrc={fetchPicture('current', photoIndex)}
        nextSrc={fetchPicture('next', photoIndex)}
        prevSrc={fetchPicture('prev', photoIndex)}
        wrapperClassName={
          brokerOrPublic && pictures[0].isUndedited ? 'image-carousel-uneditedPictures' : ''
        }
        onCloseRequest={() => {
          setImageCarouselOpen(false);
        }}
        onMoveNextRequest={() => {
          return setPhotoIndex((photoIndex + 1) % pictures.length);
        }}
        onMovePrevRequest={() => {
          return setPhotoIndex((photoIndex + pictures.length - 1) % pictures.length);
        }}
      />
    )
  );
}

export default HvImageCarousel;
