import React from 'react';
import ReactDOM from 'react-dom';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import store from 'state';
import { LanguageProvider } from 'contexts';
import App from './App';

const theme = createTheme({
  typography: {
    fontFamily: 'Ford Antenna',
  },
  palette: {
    primary: {
      main: '#034EA2',
    },
    secondary: {
      main: '#00bdf2',
    },
    error: {
      main: '#f44336',
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <LanguageProvider>
        <App />
      </LanguageProvider>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root'),
);
