import _ from 'lodash';
import classNames from 'classnames';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Input, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { InputKeyUpHandler, InputChangeHandler } from 'types';

const useStyles = makeStyles({
  passwordInput: {
    height: '40px',
    fontSize: '14px',
    borderRadius: '8px',
    border: '1px solid #CDD1D6',
    marginBottom: '15px',
    padding: '0 10px',
    overflow: 'hidden',

    '&::placeholder, &::-webkit-input-placeholder, &::-ms-input-placeholder, &:-ms-input-placeholder, &:-moz-placeholder':
      {
        color: '#818D99',
      },
  },
});

interface Props {
  className: string;
  value: string;
  placeholder: string;
  onEnter: InputKeyUpHandler;
  onChange: InputChangeHandler;
}

function HvPasswordInput(props: Props) {
  const styles = useStyles();

  const { className, onEnter, ...rest } = props;

  const [showPassword, setShowPassword] = useState(false);

  const onToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onEnter(e);
    }
  };

  return (
    <Input
      className={classNames('HvPasswordInput', className, styles.passwordInput)}
      type={showPassword ? 'text' : 'password'}
      required
      disableUnderline
      onKeyUp={onKeyUp}
      {...rest}
      endAdornment={
        <InputAdornment position='end'>
          <IconButton onClick={onToggleShowPassword}>
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      }
    />
  );
}

HvPasswordInput.defaultProps = {
  className: null,
  value: '',
  placeholder: 'Password',
  onEnter: _.noop,
  onChange: _.noop,
};

HvPasswordInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onEnter: PropTypes.func,
  onChange: PropTypes.func,
};

export default HvPasswordInput;
