import classNames from 'classnames';

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Card, CardContent, LinearProgress, Typography } from '@material-ui/core';
import { convertSizeToHuman } from 'utils';
import { COLOR } from 'config/constants';

const useStyles = makeStyles({
  UploadingFile: {
    width: '100%',
    borderRadius: '4px',
    overflow: 'hidden',
    marginBottom: 10,
  },
  doubleColumnsRow: {
    marginTop: 5,
    marginBottom: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  paragraph: {
    lineHeight: '25px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  error: {
    color: COLOR.DELETE_RED,
  },
});

interface Props {
  file: File;
  className: string;
  progress: number;
  error: string;
}

function UploadingFile({ file, className, progress, error }: Props) {
  const styles = useStyles();

  return (
    <Card className={classNames('UploadingFile', className, styles.UploadingFile)}>
      <CardContent>
        <Box className={styles.doubleColumnsRow}>
          <Typography className={styles.paragraph} title={file.name}>
            <b>{file.name}</b>
          </Typography>
          <Typography className={styles.paragraph}>{convertSizeToHuman(file.size)}</Typography>
        </Box>
        <Box>
          {progress ? (
            <LinearProgress style={{ marginTop: 5 }} variant='determinate' value={progress} />
          ) : null}
          {error ? (
            <Typography className={styles.error} title={error}>
              {error}
            </Typography>
          ) : null}
        </Box>
      </CardContent>
    </Card>
  );
}

UploadingFile.defaultProps = {
  file: null,
  className: null,
  progress: 0,
  error: '',
};

UploadingFile.propTypes = {
  file: PropTypes.object,
  className: PropTypes.string,
  progress: PropTypes.number,
  error: PropTypes.string,
};

export default UploadingFile;
