import { useIntl } from 'react-intl';

export const useQuestionCategories = () => {
  const intl = useIntl();
  const categories = [
    {
      key: 'subscription',
      title: intl.formatMessage({ id: 'helpCenter.subscription' }),
      subtitle: intl.formatMessage({ id: 'helpCenter.subscriptionSubtitle' }),
      link: 'subscription',
      keywords: [
        'subscription',
        'abonnement',
        'paiement',
        'payment',
        'facture',
        'invoice',
        'billing',
      ],
    },
    {
      key: 'facebook_icon',
      title: intl.formatMessage({ id: 'helpCenter.FBPixel' }),
      subtitle: intl.formatMessage({ id: 'helpCenter.FBPixelSubtitle' }),
      link: 'facebook_pixel',
      keywords: ['facebook', 'pixel', 'facebook pixel'],
    },
    {
      key: 'centris_logo',
      title: intl.formatMessage({ id: 'helpCenter.centris' }),
      subtitle: intl.formatMessage({ id: 'helpCenter.centrisSubtitle' }),
      link: 'centris',
      keywords: ['centris', 'mls', 'real estate', 'immobilier'],
    },
    {
      key: 'centris',
      title: intl.formatMessage({ id: 'helpCenter.centrisDataTransfer' }),
      subtitle: intl.formatMessage({ id: 'helpCenter.centrisDataTransferSubtitle' }),
      link: 'centris_data',
      keywords: ['centris', 'transfer', 'data', 'real estate', 'immobilier'],
    },
    {
      key: 'virtualStaging',
      title: intl.formatMessage({ id: 'helpCenter.virtualServices' }),
      subtitle: intl.formatMessage({ id: 'helpCenter.virtualServicesSubtitle' }),
      link: 'virtual_services',
      keywords: [
        'virtual',
        'staging',
        'virtuelle',
        'rennovation',
        'staging virtuelle',
        'renovation virtuelle',
        'virtual blue hour',
        'heure bleu virtuelle',
      ],
    },
    {
      key: 'chatgpt',
      title: intl.formatMessage({ id: 'helpCenter.listingDescriptionGeneration' }),
      subtitle: intl.formatMessage({ id: 'helpCenter.listingDescriptionGenerationSubtitle' }),
      link: 'listing_description_generation',
      keywords: ['description generation', 'addendum', 'chatgpt'],
    },
    {
      key: 'slideshow',
      title: intl.formatMessage({ id: 'helpCenter.videoSlideshow' }),
      subtitle: intl.formatMessage({ id: 'helpCenter.videoSlideshowSubtitle' }),
      link: 'slideshow',
      keywords: ['vidéo', 'video slideshow', 'slideshow', 'video', 'diaporama', 'vidéo diaporama'],
    },
    {
      key: 'aiServices',
      title: intl.formatMessage({ id: 'helpCenter.aiVirtualStaging' }),
      subtitle: intl.formatMessage({ id: 'helpCenter.aiVirtualStagingSubtitle' }),
      link: 'ai_virtual_staging',
      keywords: [
        'ai',
        'virtual staging',
        'staging',
        'virtuelle',
        'rennovation',
        'staging virtuelle',
        'renovation virtuelle',
        'ai virtual staging',
      ],
    },
  ];

  const questions = [
    {
      key: 'subscription',
      title: intl.formatMessage({ id: 'helpCenter.questions.subscription' }),
    },
    {
      key: 'facebook_pixel',
      title: intl.formatMessage({ id: 'helpCenter.questions.facebookPixel' }),
      pdf_en: 'https://cdn.hauslifenetwork.com/tutorial/FacebookPixelMessenger_en.pdf',
      pdf_fr: 'https://cdn.hauslifenetwork.com/tutorial/FacebookPixelMessenger.pdf',
    },
    {
      key: 'facebook_messenger',
      title: intl.formatMessage({ id: 'helpCenter.questions.facebookMessenger' }),
      pdf_en: 'https://cdn.hauslifenetwork.com/tutorial/FacebookPixelMessenger_en.pdf',
      pdf_fr: 'https://cdn.hauslifenetwork.com/tutorial/FacebookPixelMessenger.pdf',
    },
    {
      key: 'centris',
      title: intl.formatMessage({ id: 'helpCenter.questions.centris' }),
      pdf_en: 'https://cdn.hauslifenetwork.com/tutorial/SaisieALaSourceCentris_en.pdf ',
      pdf_fr: 'https://cdn.hauslifenetwork.com/tutorial/SaisieALaSourceCentris.pdf',
    },
    {
      key: 'centris_data',
      title: intl.formatMessage({ id: 'helpCenter.questions.centrisDataTransfer' }),
      pdf_en: 'https://cdn.hauslifenetwork.com/tutorial/TransfertDeDataCentris.pdf',
      pdf_fr: 'https://cdn.hauslifenetwork.com/tutorial/TransfertDeDataCentris.pdf',
    },
    {
      key: 'virtual_services',
      title: intl.formatMessage({ id: 'helpCenter.questions.virtualServices' }),
    },
    {
      key: 'chatgpt',
      title: intl.formatMessage({ id: 'helpCenter.questions.chatgpt' }),
    },
    {
      key: 'slideshow',
      title: intl.formatMessage({ id: 'helpCenter.questions.videoSlideshow' }),
    },
    {
      key: 'aiServices',
      title: intl.formatMessage({ id: 'helpCenter.questions.aiVirtualStaging' }),
    },
  ];
  return { categories, questions };
};
