import { images } from 'assets/images';
import { GOOGLE_MAP_LOCATION_CONSTANTS } from 'config/constants';
import { Listing } from 'models';

export const getServiceDescriptionIcon = (icon_name) => {
  switch (icon_name) {
    case 'hdr':
      return images.shooting;
    case 'twilight':
      return images.blue_hour;
    case 'drone':
      return images.drone;
    case 'staging':
      return images.staging;
    case 'seasonal':
      return images.seasonal;
    case 'video':
      return images.video;
    case 'upload':
      return images.upload_picture;
    case 'production':
      return images.video_shoot;
    case 'floorplan':
      return images.floorplan;
    default:
      return null;
  }
};

export const copyShootingsAddresses = (data: any) => {
  let shooting_addresses = '';
  for (let i = 0; i < data.shootings.length; i++) {
    const shooting = data.shootings[i];
    const listing = new Listing(shooting.listing);
    shooting_addresses += `${listing.cityAddressWithPostalCode} ${shooting.tech.firstname} ${shooting.tech.lastname}  ${shooting.listing.broker.firstname} ${shooting.listing.broker.lastname}\n`;
  }
  navigator.clipboard.writeText(shooting_addresses);
};

export const formatGoogleMapUrlForPhotographer = (data: any, inject_at_office = false) => {
  let shootings_string = '';
  for (let i = 0; i < data.shootings.length; i++) {
    shootings_string += `/${data.shootings[i].listing.lat},${data.shootings[i].listing.lng}`;
  }

  const office_string = `/${GOOGLE_MAP_LOCATION_CONSTANTS.office}`;

  const depot_string = `/${data.data.tech.depot.lat},${data.data.tech.depot.lng}`;

  if (inject_at_office) {
    return `${GOOGLE_MAP_LOCATION_CONSTANTS.prefix}${depot_string}${shootings_string}${office_string}${depot_string}`;
  }

  return `${GOOGLE_MAP_LOCATION_CONSTANTS.prefix}${depot_string}${shootings_string}${depot_string}`;
};

export const getSortedShootingPackages = (shootingPackages: any) => {
  return shootingPackages.sort((a, b) => {
    const nameA = a.display_name_en.toUpperCase();
    const nameB = b.display_name_en.toUpperCase();

    // Extract the starting number
    const regex = /^\d+/;
    const numA = nameA.match(regex);
    const numB = nameB.match(regex);

    // If both have starting numbers, compare them as numbers
    if (numA && numB) {
      const numberA = parseInt(numA[0], 10);
      const numberB = parseInt(numB[0], 10);
      return numberA - numberB;
    }

    // If one has a starting number and the other doesn't, the one with the number comes first
    if (numA) return -1;
    if (numB) return 1;

    // Otherwise, compare as strings
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
};
