import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { fetchFeedbacks } from 'apis';

const initialState = {
  feedbacks: [],
};

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    initialize: (state) => {
      state.feedbacks = [];
    },
    setFeedbacks: (state, action) => {
      state.feedbacks = action.payload;
    },
  },
});

// Actions
export const { initialize, setFeedbacks } = feedbackSlice.actions;

export const fetchFeedbacksAsync = (token: string) => {
  return async (dispatch: Dispatch) => {
    const { feedbacks } = await fetchFeedbacks(token);
    if (feedbacks) {
      dispatch(setFeedbacks(feedbacks));
    }
  };
};

export default feedbackSlice.reducer;
