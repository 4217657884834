import _ from 'lodash';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Input, Box } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
import { useIntl } from 'react-intl';
import { login } from 'apis';
import { setUser, hideModal } from 'state/actions';
import { KEYS, MODAL } from 'config/constants';
import { HvModal, HvButton, HvPasswordInput } from 'components';
import { images } from 'assets/images';

const useStyles = makeStyles({
  LoginModal: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    minWidth: 400,
    '@media (max-width: 767px)': {
      minWidth: 250,
    },
  },
  titleBar: {
    fontWeight: 600,
  },
  input: {
    height: '40px',
    fontSize: '14px',
    borderRadius: '8px',
    border: '1px solid #CDD1D6',
    marginBottom: 15,
    padding: '0 10px',
    overflow: 'hidden',

    '&::placeholder, &::-webkit-input-placeholder, &::-ms-input-placeholder, &:-ms-input-placeholder, &:-moz-placeholder':
      {
        color: '#818D99',
      },
  },
  title: {
    textAlign: 'center',
  },
  alert: {
    marginTop: 30,

    '&.error': {
      color: 'red',
    },
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: 'grey',
  },
  contentBox: {
    flex: '1 1',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    boxSizing: 'border-box',
    overflowY: 'hidden',
  },
  buttonsBox: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  dialogContent: {
    width: '100%',
  },
  description: {
    fontSize: 14,
    marginBottom: 16,
  },
  button: {
    '&:disabled': {
      backgroundColor: 'transparent',
    },
  },
  link: {
    color: '#818D99',
    fontSize: '13px',
    lineHeight: '15px',
    fontFamily: 'Ford Antenna',
  },
  logo: {
    height: '32px',
    marginBottom: '30px',
  },
});

// interface Props {
//   className?: string;
//   title?: string;
//   children: any;
//   buttons?: any;
//   onClose: () => void;
// }

// function HvLoginModal({ className, title, children, buttons, onClose }: Props) {
//   const styles = useStyles();
//   const intl = useIntl();
//   const fmtEmail = intl.formatMessage({ id: 'email' });
//   return (
//     <Dialog
//       PaperProps={{ style: { paddingLeft: 20, paddingRight: 20, paddingBottom: 20 } }}
//       className={styles.modal}
//       onClose={onClose}
//       open={false}
//     >
//       <DialogTitle className={styles.title}>Log in</DialogTitle>
//       <Input classes={{ root: styles.input }} required disableUnderline placeholder={fmtEmail} />
//       <HvPasswordInput />
//       <HvButton>Log in</HvButton>
//     </Dialog>
//   );
// }

// HvLoginModal.defaultProps = {
//   className: '',
//   title: '',
//   children: null,
//   buttons: null,
//   onClose: _.noop,
// };

// HvLoginModal.propTypes = {
//   className: PropTypes.string,
//   title: PropTypes.string,
//   children: PropTypes.any,
//   buttons: PropTypes.any,
//   onClose: PropTypes.func,
// };
function HvLoginModal() {
  const styles = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [state, setState] = useState({ status: '', message: '' });
  const fmtEmail = intl.formatMessage({ id: 'email' });
  const fmtPassword = intl.formatMessage({ id: 'password' });
  const fmtForgotPassword = intl.formatMessage({ id: 'forgotPassword' });
  const fmtInvalidCredentials = intl.formatMessage({ id: 'invalidCredentials' });
  const fmtSignin = intl.formatMessage({ id: 'login' });
  const onEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const onPasswordChange = (e: any) => {
    setPassword(e.target.value);
  };
  const onLogin = async () => {
    try {
      const { user, token } = await login(email, password);
      dispatch(setUser({ user, token }));
      dispatch(hideModal(MODAL.LOGIN));
    } catch {
      setState({
        status: 'error',
        message: fmtInvalidCredentials,
      });
    }
  };
  const onClose = () => {
    dispatch(hideModal(MODAL.LOGIN));
  };
  return (
    <HvModal
      title={intl.formatMessage({ id: 'login' })}
      //   buttons={buttons}
      onClose={onClose}
      largerWidth
    >
      <Box className={styles.LoginModal}>
        <img className={styles.logo} src={images.logoTitle} alt='Logo' />
        <Box display='flex' flexDirection='column'>
          <Input
            classes={{ root: styles.input }}
            value={email}
            placeholder={fmtEmail}
            required
            disableUnderline
            onChange={onEmailChange}
          />

          <HvPasswordInput
            value={password}
            onEnter={onLogin}
            onChange={onPasswordChange}
            placeholder={fmtPassword}
          />
        </Box>
        <Box className={styles.buttonsBox}>
          <Link
            to='/request-reset-password'
            onClick={() => {
              dispatch(hideModal(MODAL.LOGIN));
            }}
            className={styles.link}
          >
            {fmtForgotPassword}
          </Link>
          <HvButton className={styles.button} onClick={onLogin}>
            {fmtSignin}
          </HvButton>
        </Box>
        {state.status && (
          <Alert
            className={classNames(styles.alert, state.status)}
            severity={state.status as Color}
          >
            {state.message}
          </Alert>
        )}
      </Box>
    </HvModal>
  );
}

// HvLoginModal.defaultProps = {
//   className: '',
//   title: '',
//   children: null,
//   buttons: null,
//   onClose: _.noop,
// };

// HvLoginModal.propTypes = {
//   className: PropTypes.string,
//   title: PropTypes.string,
//   children: PropTypes.any,
//   buttons: PropTypes.any,
//   onClose: PropTypes.func,
// };
export default HvLoginModal;
