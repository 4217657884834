import React from 'react';
import { HvButton } from 'components';
import { makeStyles, CircularProgress, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  loadingScreen: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, .3)',
    zIndex: 9000,

    '&.transparent': {
      backgroundColor: 'transparent',
    },
  },
  loadingBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    width: 'fit-content',
    padding: 30,
    borderRadius: 5,
  },
  title: {
    '@media (max-width: 767px)': {
      fontSize: 14,
    },
  },
  subtitle: {
    '@media (max-width: 767px)': {
      fontSize: 12,
    },
  },
  button: {
    marginTop: 20,
  },
});

interface HvCustomLoadingProps {
  loadingTitle: string;
  loadingText?: string;
  buttonText?: string;
  onClickButton?: () => void;
}

function HvCustomLoading({
  loadingTitle,
  loadingText,
  buttonText,
  onClickButton,
}: HvCustomLoadingProps) {
  const classes = useStyles();

  return (
    <div className={classes.loadingScreen}>
      <div className={classes.loadingBox}>
        <CircularProgress style={{ marginBottom: 20 }} />
        <Typography
          variant='h5'
          className={classes.title}
          style={{ marginBottom: 10, fontWeight: 700 }}
        >
          {loadingTitle}
        </Typography>
        <Typography variant='subtitle1' className={classes.subtitle}>
          {loadingText}
        </Typography>
        <HvButton className={classes.button} onClick={onClickButton}>
          {buttonText}
        </HvButton>
      </div>
    </div>
  );
}

export default HvCustomLoading;
