import { SUBSCRIPTION } from 'config/constants';
import Depot from './Depot';
import UserTax from './UserTax';
import ZohoUser from './ZohoUser';
import Shooting from './Shooting';

class User {
  id: number;

  firstname: string;

  lastname: string;

  email: string;

  role: number;

  notifications_enabled: boolean;

  first_login: boolean;

  hdr_style: object;

  phone_number: string;

  notification_email: string[] = [];

  notification_phone_number: string[] = [];

  broker_id: string;

  backup_broker_id: string;

  suspended: boolean;

  opted_in_centris_data: boolean;

  gf_clause: boolean;

  language: string;

  token: string;

  depot: Depot;

  userTax: UserTax;

  zohoContact: ZohoUser;

  team_id: number;

  shootings: Shooting[] = [];

  subscription_id: number;

  static ROLES = {
    SUPER_AMDIN: 1,
    ADMIN: 2,
    BROKER: 3,
    TECH_CAPTURE: 4,
    TECH_POSTPROD: 5,
    CLIENT: 6,
    TECH_INJECTOR: 7,
    TECH_POSTPROD_LEAD: 8,
    RELEASER: 9,
    SOCIAL_MEDIAS: 10,
    STATS_QUERY: 11,
    SALES: 12,
  };

  static ADMIN_ROLES = [User.ROLES.SUPER_AMDIN, User.ROLES.ADMIN];

  static TECHN_ROLES = [User.ROLES.TECH_CAPTURE, User.ROLES.TECH_INJECTOR];

  static POSTPROD_ROLES = [User.ROLES.TECH_POSTPROD, User.ROLES.TECH_POSTPROD_LEAD];

  static POSTPROD_TECH_ROLES = [User.ROLES.TECH_CAPTURE, User.ROLES.TECH_POSTPROD];

  static POSTPROD_TEAM_IDS = [1019, 2287, 2228, 2696, 1340, 1026, 1003, 2834];

  static BROKER_ROLES = [User.ROLES.BROKER, User.ROLES.SALES];

  constructor(data: object = {}) {
    Object.assign(this, data);

    if (this.depot) {
      this.depot = new Depot(this.depot);
    }

    if (this.zohoContact) {
      this.zohoContact = new ZohoUser(this.zohoContact);
    }
  }

  get fullName() {
    return `${this.firstname} ${this.lastname}`;
  }

  get isAdmin(): boolean {
    return User.ADMIN_ROLES.includes(this.role);
  }

  get isTechn(): boolean {
    return User.TECHN_ROLES.includes(this.role);
  }

  get isPostProd(): boolean {
    return User.POSTPROD_ROLES.includes(this.role);
  }

  get isBroker(): boolean {
    return User.BROKER_ROLES.includes(this.role);
  }

  get isPremium(): boolean {
    return (
      this.subscription_id === SUBSCRIPTION.application ||
      this.subscription_id === SUBSCRIPTION.plateforme
    );
  }

  homepage() {
    switch (this.role) {
      case User.ROLES.BROKER:
        return 'media-directory';
      case User.ROLES.TECH_CAPTURE:
        return 'remote-injector';
      case User.ROLES.TECH_POSTPROD:
        return 'post-prod-dashboard';
      case User.ROLES.TECH_POSTPROD_LEAD:
        return 'post-prod-dashboard';
      default:
        return 'media-directory';
    }
  }
}

export default User;
