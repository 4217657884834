import { IServiceOfferTypeValueMap } from 'types';
import ServiceOfferDetails from './ServiceOfferDetails';

class ServiceOffer {
  id?: number;

  broker_note?: string;

  serviceOffer?: ServiceOfferDetails = new ServiceOfferDetails();

  service_offer_id?: number;

  shooting_id?: number;

  is_child?: boolean;

  type_value_map?: IServiceOfferTypeValueMap;

  url?: string;

  youtube_video_id?: string;

  is_public?: boolean;

  listing_video_id?: number;

  created_at?: string;

  static TYPE = {
    BOOLEAN: 1,
    NUMBER: 2,
  };

  constructor(data: object = {}) {
    Object.assign(this, data);

    if (this.serviceOffer) {
      this.serviceOffer = new ServiceOfferDetails(this.serviceOffer);
    }
  }
}

export default ServiceOffer;
