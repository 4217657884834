class FaqCategory {
  id: number;

  title_fr: String;

  title_en: String;

  constructor(data: object = {}) {
    Object.assign(this, data);
  }

  getTitle(language: String): String {
    if (language === 'fr') {
      return `${this.title_fr}`;
    }
    return `${this.title_en}`;
  }

  get categoryId() {
    return this.id;
  }
}

export default FaqCategory;
