import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { Picture } from 'models';
import { roomTypes } from 'pages/Delivery/modals/aiServiceSteps/aiStagingOrder/stagingOptionsConfig';

const initialState = {
  listing_pictures: [],
  selectedPicture: new Picture(),
  orderParams: null,
  canProceed: false,
  noMoreSteps: false,
  listing_id: null,
  result: null,
  currentStep: 0,
  hideButtons: false,
  selectedService: null,
  is_AI_promotion_applied: false,
  nextAiStagingOrderIsFree: false,
  showDisclaimer: false,
  creatingOrder: false,
  selectedRoomType: roomTypes[0].key,
  selectedRoomStyle: null,
};

export const aiServicesSlice = createSlice({
  name: 'aiServices',
  initialState,
  reducers: {
    initialize: (state) => {
      state.listing_pictures = [];
      state.selectedPicture = null;
      state.orderParams = null;
      state.canProceed = false;
      state.noMoreSteps = false;
      state.listing_id = null;
      state.result = null;
      state.currentStep = 0;
      state.hideButtons = false;
      state.selectedService = null;
      state.showDisclaimer = false;
      state.creatingOrder = false;
      state.selectedRoomType = roomTypes[0].key;
      state.selectedRoomStyle = null;
    },
    startNewDesign: (state) => {
      state.selectedPicture = null;
      state.orderParams = null;
      state.canProceed = false;
      state.noMoreSteps = false;
      state.result = null;
      state.currentStep = 0;
      state.hideButtons = false;
      state.is_AI_promotion_applied = true;
      state.nextAiStagingOrderIsFree = false;
      state.showDisclaimer = false;
      state.creatingOrder = false;
      state.selectedRoomType = roomTypes[0].key;
      state.selectedRoomStyle = null;
    },
    setListingPictures: (state, action) => {
      state.listing_pictures = action.payload;
    },
    setSelectedPicture: (state, action) => {
      state.selectedPicture = new Picture(action.payload);
    },
    setOrderParams: (state, action) => {
      state.orderParams = action.payload;
    },
    setNoMoreSteps: (state, action) => {
      state.noMoreSteps = action.payload;
    },
    setCanProceed: (state, action) => {
      state.canProceed = action.payload;
    },
    setListingId: (state, action) => {
      state.listing_id = action.payload;
    },
    setResult: (state, action) => {
      state.result = action.payload;
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    toggleHideButtons: (state) => {
      state.hideButtons = !state.hideButtons;
    },
    setSelectedService: (state, action) => {
      state.selectedService = action.payload;
    },
    setAIPromotionApplied: (state, action) => {
      state.is_AI_promotion_applied = action.payload;
    },
    setNextAiStagingOrderIsFree: (state, action) => {
      state.nextAiStagingOrderIsFree = action.payload;
    },
    setShowDisclaimer: (state, action) => {
      state.showDisclaimer = action.payload;
    },
    setCreatingOrder: (state, action) => {
      state.creatingOrder = action.payload;
    },
    setSelectedRoomType: (state, action) => {
      state.selectedRoomType = action.payload;
    },
    setSelectedRoomStyle: (state, action) => {
      state.selectedRoomStyle = action.payload;
    },
  },
});

// Actions
export const {
  initialize,
  setListingPictures,
  setSelectedPicture,
  setOrderParams,
  setCanProceed,
  setNoMoreSteps,
  setListingId,
  setResult,
  setCurrentStep,
  toggleHideButtons,
  setSelectedService,
  setAIPromotionApplied,
  setNextAiStagingOrderIsFree,
  setShowDisclaimer,
  setCreatingOrder,
  setSelectedRoomType,
  setSelectedRoomStyle,
  startNewDesign,
} = aiServicesSlice.actions;

export default aiServicesSlice.reducer;
