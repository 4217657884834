import React from 'react';
import PropTypes from 'prop-types';
import { Document, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import { Listing, Room } from 'models';

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    color: '#555',
    fontSize: '12px',
    backgroundColor: '#FFF',
    padding: '40px 20px',
  },
  logoBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
  },
  logo: {
    width: '204px',
    height: '40px',
  },
  title: {
    color: '#000',
    fontSize: '20px',
    textAlign: 'center',
  },
  address: {
    color: '#000',
    fontSize: '20px',
    textAlign: 'center',
    margin: '10px',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableCell: {
    width: '25%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    padding: '6px 3px',
  },
  tableHeadText: {
    color: '#000',
    fontWeight: 600,
    letterSpacing: '2px',
  },
});

interface Props {
  listing: Listing;
  headers: string[];
  language: string;
  logo: string;
}

function PdfDocument(props: Props) {
  const { listing, headers = [], language, logo } = props;

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.logoBox}>
          <Image src={logo} style={styles.logo} />
        </View>
        <View style={{ marginBottom: '20px' }}>
          <Text style={styles.address}>{listing?.cityAddress}</Text>
        </View>

        <View style={styles.tableRow}>
          {headers.map((item: string) => {
            return (
              <View key={item} style={styles.tableCell}>
                <Text style={styles.tableHeadText}>{item}</Text>
              </View>
            );
          })}
        </View>

        {listing?.insideRooms.map((room: Room) => {
          return (
            <View key={room.id} style={styles.tableRow}>
              <View style={styles.tableCell}>
                <Text>{room.getRoomName(language)}</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>{room.getFloor(language)}</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>{room.hasDefaultMeasures() ? 'NA' : room.dimensions}</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>{room.getFlooring(language)}</Text>
              </View>
            </View>
          );
        })}
      </Page>
    </Document>
  );
}

PdfDocument.propTypes = {
  listing: PropTypes.object.isRequired,
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  language: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
};

export default PdfDocument;
