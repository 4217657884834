import _ from 'lodash';

import React from 'react';
import { useDispatch } from 'react-redux';
import { reactLocalStorage } from 'reactjs-localstorage';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import messages from 'config/i18n';
import { useLanguage } from 'hooks';
import { KEYS, ENG } from 'config/constants';
import { setLanguage } from 'state/actions';

const LanguageContext = React.createContext({
  language: ENG,
  updateLanguage: _.noop,
});

function LanguageProvider({ children }) {
  const language = useLanguage();
  const dispatch = useDispatch();

  const updateLanguage = (lang: string) => {
    reactLocalStorage.set(KEYS.LANGUAGE, lang);
    dispatch(setLanguage(lang));
  };

  const contextData = {
    language,
    updateLanguage,
  };

  return (
    <LanguageContext.Provider value={contextData}>
      <IntlProvider locale={language} defaultLocale={ENG} messages={messages[language]}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
}

LanguageProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export { LanguageContext, LanguageProvider };
