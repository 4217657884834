import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from 'hooks';

const AuthRoute = ({ component, ...rest }: any) => {
  const { isLoggedIn } = useAuth();

  const routeComponent = (props: any) => {
    return isLoggedIn ? React.createElement(component, props) : <Redirect to='/login' />;
  };

  return <Route {...rest} render={routeComponent} />;
};

export default AuthRoute;
