import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { images } from 'assets/images';
import { Box, Card, CardMedia, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  emptyList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FFF',
    padding: '10px',
    borderRadius: '4px',
    boxShadow: 'none',
  },
  paragraph: {
    fontSize: 16,
    textAlign: 'center',
  },
  emptyListingImage: {
    height: 'auto',
    alignSelf: 'center',
    width: '30%',
    '@media (max-width: 800px)': {
      width: '100%',
    },
  },
  imageContent: {
    justifyContent: 'center',
    display: 'flex',
  },
});

interface Props {
  text: string;
  image?: string;
}

function EmptyList({ text, image }: Props) {
  const styles = useStyles();

  return (
    <Card className={styles.emptyList}>
      <CardContent>
        {image ? (
          <Box className={styles.imageContent}>
            <img className={styles.emptyListingImage} src={image} alt='empty listing' />
          </Box>
        ) : null}
        <Typography className={styles.paragraph}>{text}</Typography>
      </CardContent>
    </Card>
  );
}

EmptyList.defaultProps = {
  text: '',
};

EmptyList.propTypes = {
  text: PropTypes.string,
};

export default memo(EmptyList);
