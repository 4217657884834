import axios from 'axios';

import { FaqCategory, FaqQuestion } from '../models';
import { getAPIURL } from '../config/constants';

export const fetchFaqCategories = async (): Promise<any> => {
  try {
    const res = await axios.get(`${getAPIURL()}/getAllFaqCategories`, {});
    const categories = res.data.categories.map((obj: object) => {
      return new FaqCategory(obj);
    });
    return { categories };
  } catch (err) {
    console.log('[fetchFaqCategories] error: ', err);
  }
  return {};
};

export const fetchFaqQuestions = async (): Promise<any> => {
  try {
    const res = await axios.get(`${getAPIURL()}/getAllFaq`, {});
    const questions = res.data.questions.map((obj: object) => {
      return new FaqQuestion(obj);
    });
    return { questions };
  } catch (err) {
    console.log('[FaqQuestion] error: ', err);
  }
  return {};
};
