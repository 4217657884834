import React from 'react';
import { useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
  alert: {
    position: 'fixed',
    left: '50%',
    bottom: 50,
    transform: 'translate(-50%, 0)',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',
    zIndex: 4000,
  },
  message: {
    fontSize: 16,
    fontWeight: 500,
    whiteSpace: 'pre-line',
  },
});

function HvAlert() {
  const styles = useStyles();

  const { alert } = useSelector((state: any) => {
    return state.app;
  });

  if (!alert) return null;

  return (
    <Alert className={styles.alert} severity={alert.status} variant='filled'>
      <Typography className={styles.message}>{alert.message}</Typography>
    </Alert>
  );
}

export default HvAlert;
